import { useEffect, useRef, useState } from 'react';
import {
  Table,
  Pagination,
  TableProps,
  GetProp,
  TablePaginationConfig,
  ConfigProvider,
} from 'antd';
import cloneDeep from 'lodash.clonedeep';
import { useGetProductionOrderQueueV2Query } from '@api/productionOrderSlice';
import { convertUnixToSingaporeTime } from '@utils/utils';
import { DeleteProductionOrderBankQueueButton } from '../../reports/Buttons';
import DirectionsQueueHeaderGroup from './DirectionsQueueHeaderGroup'; // handleSelectSingleMoniker, // handleSelectSingleGeneratePO,
import { CommonButton } from '@components/common/CommonButton';
import ProductionOrderBankModal from '../modals/ProductionOrderBankModal';
import { GenerateProductionOrderBankQueueButton } from '@pages/portfolios/Buttons';
import AccessControl from '@components/common/AccessControl';
import { Transaction } from '@interface/constant';
import { getSessionUserFromAccessToken } from '@utils/utils';

interface sortBy {
  sortId: string;
  sortOrder: 'asc' | 'desc';
}

interface TableParams {
  pagination: TablePaginationConfig;
  sort: sortBy;
  filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

function getTotalFromReportTransactions(financialInstitute: any) {
  let total = 0;
  if (financialInstitute) {
    let transactions = financialInstitute.updatedTransactions.filter(
      (e: any) => e.reportNumber === financialInstitute.reportNumber,
    )[0]?.transactions;
    if (transactions?.length > 0) {
      transactions.forEach((transaction: Transaction) => {
        total += transaction?.amountLossInSGD || 0;
      });
    }
  }

  return total;
}

export default function TableComponent() {
  const [currentUser, setCurrentUser] = useState<any>();
  const [searchTerm, setSearchTerm] = useState('');
  const [tableParams, setTableParams] = useState<TableParams>({
    sort: {
      sortId: 'createdAt',
      sortOrder: 'asc',
    },
    pagination: {
      current: 1,
      pageSize: 50,
    },
  });
  const [selectedRows, setSelectedRows] = useState<Array<any>>([]);
  const [selectedScamEnablerIds, setSelectedScamEnablerIds] = useState<Array<string>>([]);
  const [selectedPOQueueIds, setSelectedPOQueueIds] = useState<Array<string>>([]);
  const [bankModalSelectedId, setBankModalSelectedId] = useState('');
  const [bankModalOpen, setBankModalOpen] = useState(false);

  const column = [
    {
      title: 'Bank Account No',
      dataIndex: 'accountNumber',
      key: 'accountNumber',
      width: '20%',
      sorter: true,
      render: (text: string, record: any, index: number) => (
        <div className="overflow-hidden">
          <CommonButton
            isDisabled={false}
            variant="underline-text"
            title={text}
            className="text-left"
            onClick={() => {
              setBankModalOpen(true);
              setBankModalSelectedId(record.poQueueId);
            }}
          />
        </div>
      ),
    },
    {
      title: 'Payment Channel',
      dataIndex: 'platform',
      sorter: true,
      key: 'platform',
      width: '15%',
    },
    {
      title: 'PO Type',
      dataIndex: 'type',
      key: 'type',
      width: '15%',
      render: (text: string, record: any, index: number) => (
        <>
          {text === 'freeze' && <p className="text-pink">To Freeze</p>}
          {text === 'earmark' && (
            <p className="text-green">To Earmark ({getTotalFromReportTransactions(record)})</p>
          )}
        </>
      ),
    },
    {
      title: 'Report Number',
      dataIndex: 'reportNumber',
      sorter: true,
      width: '15%',
      key: 'reportNumber',
    },
    {
      title: 'Date Sent to Queue',
      dataIndex: 'createdAt',
      sorter: true,
      width: '15%',
      key: 'createdAt',
      render: (text: string, record: any, index: number) =>
        convertUnixToSingaporeTime(record.createdAt),
    },
    {
      title: 'DO Assigned',
      dataIndex: 'dutyOfficer',
      sorter: true,
      width: '15%',
      key: 'dutyOfficer',
      render: (text: string, record: any, index: number) => record.dutyOfficer.userName,
    },
    {
      key: 'delete',
      width: '4%',
      render: (text: string, record: any, index: number) => (
        <DeleteProductionOrderBankQueueButton
          selectedRows={[record]}
          view="bankView"
          buttonType="icon"
          callback={refetch}
          isDisabled={false}
        />
      ),
    },
    {
      key: 'generate',
      width: '4%',
      render: (text: string, record: any, index: number) => (
        <GenerateProductionOrderBankQueueButton
          financialInstituteData={[record]}
          callback={refetch}
          buttonType="icon"
          isDisabled={!isUserDOAssigned(record)}
        />
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      const selectedScamEnablerIds = selectedRows.map((previewPOData: any) => {
        return previewPOData.scamEnablerUserId;
      });
      const selectedPOQueueIds = selectedRows.map((previewPOData: any) => {
        return previewPOData.poQueueId;
      });

      setSelectedScamEnablerIds(selectedScamEnablerIds);
      setSelectedPOQueueIds(selectedPOQueueIds);

      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const { refetch, data, isLoading, isFetching } = useGetProductionOrderQueueV2Query({
    params: {
      view: 'bankView',
      search: searchTerm,
      sort_by: tableParams.sort.sortId,
      order_by: tableParams.sort.sortOrder,
      page: tableParams.pagination.current,
      size: tableParams.pagination.pageSize,
      filters: tableParams.filters,
    },
  });

  useEffect(() => {
    const getUserData = async () => {
      const sessionUser = await getSessionUserFromAccessToken();
      setCurrentUser(sessionUser);
    };

    getUserData();
  }, []);

  useEffect(() => {
    refetch();
  }, []);

  const isUserDOAssigned = (row: any) => {
    return row.dutyOfficer.userId === currentUser?.userId;
  };

  const handlePageChange = (page: number, pageSize: number) => {
    setTableParams({
      ...tableParams,

      pagination: {
        current: page,
        pageSize: pageSize,
      },
    });
  };

  const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter: any) => {
    setTableParams({
      ...tableParams,
      sort: {
        sortId: sorter.field ? sorter.field : 'createdAt',
        sortOrder: sorter.order === 'ascend' ? 'asc' : 'desc',
      },
    });
  };
  const tableRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    tableRef.current?.scrollTo(0, 0);
  }, [data]);

  return (
    <>
      <AccessControl
        type="component"
        allowedPermissions={['directionsQueue.productionordersbanks.view']}
      >
        <div className="rounded-lg p-2 md:pt-0 overflow-y-auto">
          <DirectionsQueueHeaderGroup
            selectedRows={selectedRows}
            selectedScamEnablerIds={selectedScamEnablerIds}
            selectedPOQueueIds={selectedPOQueueIds}
            refetch={refetch}
            setTableParams={setTableParams}
            tableParams={tableParams}
            setSearchTerm={setSearchTerm}
            view="bankView"
          />
          <div className="max-h-[calc(100vh-320px)] overflow-auto" ref={tableRef}>
            <ConfigProvider
              theme={{
                components: {
                  Table: {
                    /* here is your component tokens */
                    headerBg: '#D0DEE0',
                  },
                },
              }}
            >
              <Table
                rowSelection={{
                  ...rowSelection,
                }}
                dataSource={cloneDeep(data?.poQueues)}
                columns={column}
                rowKey="poQueueId"
                pagination={false}
                onChange={handleTableChange}
                loading={isLoading || isFetching}
                sticky
              />
            </ConfigProvider>
          </div>

          <Pagination
            className="pt-2 flex justify-end"
            defaultCurrent={1}
            current={tableParams.pagination.current}
            total={data?.totalNumberOfRecords}
            onChange={handlePageChange}
            showSizeChanger={true}
            defaultPageSize={50}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
          />
        </div>
        <ProductionOrderBankModal
          isOpen={bankModalOpen}
          onClose={() => {
            setBankModalOpen(false);
            // refetch();
          }}
          poQueueId={bankModalSelectedId}
        />
      </AccessControl>
    </>
  );
}
