import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import routeTitlesJson from "../analytics/routeTitles.json";

const routeTitles: Record<string, string> = routeTitlesJson as Record<string, string>;

const getPageTitle = (pathname: string): string => {
  if (routeTitles[pathname]) {
    return routeTitles[pathname];
  }

  if (pathname.startsWith("/admin/reports/")) return "Report Details Page";
  if (pathname.startsWith("/admin/portfolios/monikers/")) return "Moniker Details Page";
  if (pathname.startsWith("/admin/portfolios/bank/")) return "Bank Details Page";
  if (pathname.startsWith("/admin/portfolios/paynow/")) return "PayNow Details Page";

  return "Unknown Page"; 
};

const PageView = () => {
  const location = useLocation();
  useEffect(() => {

    const pageTitle = getPageTitle(location.pathname);

    ReactGA.gtag('event', 'page_view', {
      page_path: location.pathname + location.search,
      page_title: pageTitle,
    });

    console.log(`GA Pageview sent: ${location.pathname} - ${pageTitle}`);
  }, [location.pathname]);

  return null;
};

export default PageView;