import { Modal } from '@mui/material';
import ModalLayout from '@components/layouts/ModalLayout';
import { CommonButton } from '@components/common/CommonButton';
import { Table } from 'antd';
import { Transaction } from '@interface/constant';

import { useState } from 'react';
import { delay } from '@utils/utils';

const BankProductionOrderConfirmModal = ({
  financialInstituteData,
  reportNumber,
  type,
  modalOpen,
  isLoading,
  onModalClose,
  handleSubmit,
}: {
  financialInstituteData: any;
  reportNumber?: string;
  type?: string;
  modalOpen: boolean;
  isLoading: boolean;
  onModalClose: () => void;
  handleSubmit: () => void;
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [isCopied, setIsCopied] = useState(false);

  const columns = [
    {
      title: 'Report Number',
      dataIndex: 'reportNumber',
      key: 'reportNumber',
      width: '25%',
      render: (text: string, record: any, index: number) => {
        return reportNumber || record.reportNumber;
      },
    },
    {
      title: 'PO Type',
      dataIndex: 'type',
      key: 'type',
      width: '25%',
      render: (text: string, record: any, index: number) => {
        let poType = type || record.type;
        return poType === 'freeze' ? 'to f' : `to em ${getTotalFromReportTransactions(record)}`;
      },
    },
    {
      title: 'Bank/FinTech',
      dataIndex: 'platform',
      key: 'platform',
      width: '25%',
    },
    {
      title: 'Account No./Txn Reference',
      dataIndex: 'accountNumber',
      key: 'accountNumber',
      width: '25%',
    },
  ];

  const getTotalFromReportTransactions = (financialInstitute: any) => {
    let selectedReportNumber = reportNumber || financialInstitute.reportNumber;
    let total = 0;
    let transactions = financialInstitute.updatedTransactions.filter(
      (e: any) => e.reportNumber === selectedReportNumber,
    )[0].transactions;
    if (transactions?.length > 0) {
      transactions.forEach((transaction: Transaction) => {
        total += transaction?.amountLossInSGD || 0;
      });
    }

    return total;
  };

  const copyTableInfo = async () => {
    setIsCopied(true);
    setIsDisabled(false);

    let text = '';
    let index = 0;
    for (let financialInstitute of financialInstituteData) {
      let poType = type || financialInstitute.type;
      text += `${financialInstitute.reportNumber || reportNumber}	${poType === 'freeze' ? 'to f' : `to em ${getTotalFromReportTransactions(financialInstitute)}`}	${financialInstitute.platform}	${financialInstitute.accountNumber}`;
      if (index < financialInstituteData.length - 1) {
        text += '\n';
      }
      index++;
    }

    await navigator.clipboard.writeText(text);

    await delay(3000);
    setIsCopied(false);
  };

  return (
    <>
      <Modal open={modalOpen} onClose={onModalClose}>
        <ModalLayout
          title={`Generate Production Order`}
          handleCloseModal={onModalClose}
          alignment="center"
          size="medium"
        >
          <div className="flex flex-column justify-between mb-4">
            <p className="pb-2 w-[70%]">
              Please review the following information for the selected account(s) before proceeding
              to generate the PO.
            </p>
            {!isCopied && (
              <CommonButton title="Copy Table Info" variant="secondary" onClick={copyTableInfo} />
            )}
            {isCopied && <span className="text-sm text-blue-500 font-bold">Copied!</span>}
          </div>
          <Table
            dataSource={financialInstituteData}
            columns={columns}
            rowKey="reportNumber"
            pagination={false}
          />
          <div className="flex gap-4 mr-0 ml-auto mt-10">
            <CommonButton
              title="Cancel"
              variant="text"
              onClick={onModalClose}
              isDisabled={isLoading}
            />
            <CommonButton
              title="Generate"
              variant="primary"
              onClick={handleSubmit}
              isDisabled={isDisabled || isLoading}
              isLoading={isLoading}
            />
          </div>
        </ModalLayout>
      </Modal>
    </>
  );
};

export default BankProductionOrderConfirmModal;
