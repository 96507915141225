import { FormItemInterface } from '@interface/dynamicFormInterface';
import { getField, processData } from './DynamicForm';
import { useFieldArray } from 'react-hook-form';
import { CommonButton, IconButton } from './CommonButton';
import cloneDeep from 'lodash.clonedeep';
import { useEffect } from 'react';

const DynamicTable = ({
  tableId,
  formFields,
  data,
  children,
  formMethods,
  setBadgeText,
  callback,
  isEditable,
  isLoading,
  parentExtras,
  formClass,
  defaultRow,
  showCounter,
  isSimpleView,
}: {
  tableId: string;
  formFields: Array<FormItemInterface>;
  formMethods?: any;
  data?: any;
  children?: any;
  setBadgeText?: any;
  callback?: any;
  isEditable?: boolean;
  isLoading?: boolean;
  parentExtras?: any;
  formClass?: any;
  defaultRow?: any;
  showCounter?: boolean;
  isSimpleView?: boolean;
}) => {
  const processedForm = processData(formFields, data);
  const processField = (field: FormItemInterface, index: number, data: any) => {
    let newField = cloneDeep(field);
    newField.id = `${tableId}[${index}][${field.id}]`;
    if (newField?.extras?.idCurrency) {
      newField.extras.idCurrency = `${tableId}[${index}][${field.extras.idCurrency}]`;
    }
    if (!isEditable && field.id && data && data[tableId] && data[tableId][index]) {
      newField.value = data[tableId][index][field.id];
    }
    if (field?.extras?.condition?.field?.includes('${tableId}')) {
      newField.extras.condition.field = newField.extras.condition.field.replace(
        '${tableId}',
        `${tableId}[${index}]`,
      );
    }

    return newField;
  };

  const { fields, append, remove } = useFieldArray({
    control: formMethods.control,
    name: tableId,
  });

  useEffect(() => {
    if (fields.length === 0 && isEditable) {
      append(defaultRow);
    }
  }, [fields, isEditable]);

  return (
    <>
      {processedForm && (
        <div className="flex flex-col  ">
          <div className="rounded-lg overflow-x-auto ">
            <div className="inline-block min-w-full">
              <table className="w-[115%] text-sm text-left rtl:text-right table-fixed">
                <thead className={`${isSimpleView ? '' : 'bg-grey-300'} font-bold`}>
                  <tr>
                    {processedForm && processedForm.length > 0 && (
                      <>
                        {showCounter && (
                          <td className="w-10 text-grey-400" key="counter">
                            No.
                          </td>
                        )}
                        {processedForm.map((field: FormItemInterface, index: number) => (
                          <td
                            className={`p-2 ${
                              field?.extras?.customClass
                                ? `${field.extras.customClass}`
                                : 'w-52 2xl:w-60' // Default width if not specified
                            }`}
                            key={`${field.id}-${index}`}
                          >
                            <div className="flex flex-col">
                              <div className="flex flex-row">
                                <p className="font-bold text-grey-400">{field.label}</p>
                                {!field.optional && isEditable && (
                                  <p className="text-red text-xl font-bold leading-none mt-0 ml-1">
                                    *
                                  </p>
                                )}
                              </div>
                              {field?.helperText && (
                                <p className=" text-grey-400 opacity-60 text-xs">
                                  {field.helperText}
                                </p>
                              )}
                              {/* {field.optional && !isSimpleView && (
                              <p className="font-bold text-grey-400 opacity-60 italic">
                                {'- Optional'}
                              </p>
                            )} */}
                            </div>
                          </td>
                        ))}
                        {isEditable && <div className="w-5"></div>}
                      </>
                    )}
                  </tr>
                </thead>

                <tbody>
                  {fields &&
                    processedForm &&
                    fields.map((arrayField: any, index) => (
                      <tr
                        key={arrayField.id}
                        className={`${!isSimpleView ? `bg-${index % 2 ? 'white' : 'grey-100'}` : ''}`}
                      >
                        {showCounter && (
                          <td className="px-2 py-1" key={'counter'}>
                            {index + 1}
                          </td>
                        )}

                        {processedForm.map((field) => (
                          <td className="px-2 py-4 align-top" key={`${field.id}-${index}`}>
                            {!isLoading &&
                              formMethods &&
                              getField(
                                processField(field, index, data),
                                arrayField[field.id],
                                callback,
                                isEditable,
                                formMethods,
                                setBadgeText,
                                true,
                                { ...parentExtras, _id: data?._id },
                              )}
                          </td>
                        ))}

                        {isEditable && (
                          <td className="flex items-center ml-4">
                            <IconButton
                              icon="delete"
                              isDisabled={false}
                              onClick={() => {
                                remove(index);
                              }}
                              className="mt-4"
                            />
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          {isEditable && (
            <CommonButton
              title="Add another row"
              isDisabled={false}
              onClick={(e: any) => {
                e.preventDefault();
                append(defaultRow);
              }}
              variant="text-blue"
              className="mt-4"
            />
          )}
        </div>
      )}
    </>
  );
};

export default DynamicTable;
