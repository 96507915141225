import { useState } from 'react';
import { redirect, useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import DynamicForm from '@components/common/DynamicForm';
import LoginHeader from '@components/common/LoginHeader';
import { loginFormGeneralItems } from './LoginFormConfig';
import { useLoginMutation } from '@api/sessionSlice';
import { getSessionUserFromAccessToken } from '@utils/utils';
import { DEFAULT_LOGGED_IN_PAGE } from '@constants/index';
import { CommonButton } from '@components/common/CommonButton';
import { get } from 'http';

export default function LoginComponent() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [login] = useLoginMutation();

  const formMethods = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onSubmit',
  });

  const onSubmit = async (event: any) => {
    setIsSubmitting(true);
    const formValues: any = formMethods.getValues();
    let params = {
      email: formValues.email,
      password: formValues.password,
    };
    const response: any = await login(params);
    setIsSubmitting(false);

    let route = searchParams.get('route') || '';

    if (route) {
      route = `?route=${route}`;
    }
    return navigate(`/login-change-password${route}`);
  };

  return (
    <div className="md:flex flex-col items-center justify-center min-h-screen bg-white md:bg-gradient-to-b md:from-blue-300 md:to-blue-400">
      <div className="flex flex-col w-full h-full md:w-1/2 xl:w-5/12 gap-2 bg-white py-16 px-10 md:px-16 rounded-lg">
        <LoginHeader />

        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <div className="flex flex-col">
            <h1 className="mb-4 ml-1 text-center">Login</h1>
            <DynamicForm formFields={loginFormGeneralItems} formMethods={formMethods}></DynamicForm>
          </div>
          <div className="flex flex-row flex-col pt-4 text-left">
            <CommonButton
              className="ml-0 w-full flex justify-center"
              variant="primary"
              type="submit"
              title={isSubmitting ? 'Logging in...' : 'Login'}
              isLoading={isSubmitting}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export async function loader({ request }: any) {
  console.log('login loader');

  const accessTokenPayload = await getSessionUserFromAccessToken('SatisPlusAccessToken');

  let url = new URL(request.url);

  let params = '';

  if (url.pathname !== '/' && !url.pathname.startsWith('/login')) {
    params = `?route=${url.pathname}`;
  }

  if (url.searchParams.get('route')) {
    params = `?route=${url.searchParams.get('route')}`;
  }

  if (url.pathname === '/' || url.pathname === '/login') {
    if (
      accessTokenPayload &&
      !accessTokenPayload.changePasswordRequired &&
      !accessTokenPayload.setupTotpRequired &&
      accessTokenPayload.totpVerified
    ) {
      console.log('Valid session');
      console.log('/login');

      return validLogin(accessTokenPayload, url);
    } else if (accessTokenPayload && accessTokenPayload.changePasswordRequired) {
      console.log('Change password required');

      return redirect(`/login-change-password${params}`);
    } else if (
      accessTokenPayload &&
      !accessTokenPayload.changePasswordRequired &&
      accessTokenPayload.setupTotpRequired
    ) {
      return redirect(`/login-setup-totp${params}`);
    } else if (
      accessTokenPayload &&
      !accessTokenPayload.changePasswordRequired &&
      accessTokenPayload.setupTotpRequired
    ) {
      return redirect(`/login-totp${params}`);
    }
  } else if (url.pathname === '/login-change-password') {
    if (
      accessTokenPayload &&
      !accessTokenPayload.changePasswordRequired &&
      !accessTokenPayload.setupTotpRequired &&
      accessTokenPayload.totpVerified
    ) {
      console.log('Valid session');
      return validLogin(accessTokenPayload, url);
    } else if (!accessTokenPayload) {
      return redirect(`/login${params}`);
    } else if (!accessTokenPayload.changePasswordRequired && accessTokenPayload.setupTotpRequired) {
      return redirect(`/login-setup-totp${params}`);
    } else if (
      !accessTokenPayload.changePasswordRequired &&
      !accessTokenPayload.setupTotpRequired
    ) {
      return redirect(`/login-totp${params}`);
    }
  } else if (url.pathname === '/login-setup-totp') {
    if (
      accessTokenPayload &&
      !accessTokenPayload.changePasswordRequired &&
      !accessTokenPayload.setupTotpRequired &&
      accessTokenPayload.totpVerified
    ) {
      console.log('Valid session');
      return validLogin(accessTokenPayload, url);
    } else if (!accessTokenPayload) {
      return redirect(`/login${params}`);
    } else if (
      !accessTokenPayload.changePasswordRequired &&
      !accessTokenPayload.setupTotpRequired
    ) {
      return redirect(`/login-totp${params}`);
    }
  } else if (url.pathname === '/login-totp') {
    if (
      accessTokenPayload &&
      !accessTokenPayload.changePasswordRequired &&
      !accessTokenPayload.setupTotpRequired &&
      accessTokenPayload.totpVerified
    ) {
      console.log('Valid session');

      return validLogin(accessTokenPayload, url);
    } else if (accessTokenPayload && accessTokenPayload.changePasswordRequired) {
      console.log('Change password required');
      return redirect(`/login-change-password${params}`);
    } else if (!accessTokenPayload) {
      return redirect(`/login${params}`);
    }
  } else {
    if (
      !accessTokenPayload ||
      !accessTokenPayload.totpVerified ||
      accessTokenPayload.changePasswordRequired
    ) {
      console.log('No valid session, redirecting to login page');

      return redirect(`/login${params}`);
    }
  }
  return '';
}

export function validLogin(accessTokenPayload: any, url: any) {
  localStorage.clear();

  let defaultLoggedinPage = getDefaultLoggedinPage(accessTokenPayload, url);
  return redirect(defaultLoggedinPage);
}

export function getDefaultLoggedinPage(accessTokenPayload: any, url: any) {
  let permissionsWithPage = [
    'portfolios.scamenablers.view',
    'portfolios.banks.view',
    'portfolios.paynows.view',
    'portfolios.proactivescamenablers.view',
  ];
  let permissions = accessTokenPayload?.permissions.filter((e: string) =>
    permissionsWithPage.includes(e),
  );
  console.log('permissions', permissions);
  let permission = 'default';
  if (permissions.length > 1 && accessTokenPayload?.teams?.length > 0) {
    let team = accessTokenPayload?.teams[0];
    permission = team;
  } else {
    permission = permissions[0];
  }
  console.log('permission', permission);

  let defaultLoginPage =
    url.searchParams.get('route') ||
    DEFAULT_LOGGED_IN_PAGE[permission] ||
    DEFAULT_LOGGED_IN_PAGE['default'];

  return defaultLoginPage;
}
