import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ReactComponent as Warning } from '@assets/icons/warning.svg';
import { Controller } from 'react-hook-form';
import { getNestedError } from '@utils/utils';
import { useState } from 'react';
import { clsx } from 'clsx';
import { ReactComponent as CircleIcon } from '@assets/icons/circle.svg';
import { ReactComponent as ValidatedFailedIcon } from '@assets/icons/validated_failed.svg';
import { ReactComponent as ValidatedSuccessIcon } from '@assets/icons/validated_success.svg';

const Password = ({
  id,
  control,
  placeholder,
  prefix,
  formState,
  displayPasswordRequirements,
  rules,
}: {
  id: string;
  control: any;
  placeholder?: string;
  prefix?: string;
  formState?: any;
  displayPasswordRequirements?: boolean;
  rules?: any;
}) => {
  const errors = formState?.errors;

  const [showPassword, setShowPassword] = useState(false);
  const [atLeastMinimumLength, setAtLeastMinimumLength] = useState<boolean | null>(null);
  const [atLeastOneLowercaseLetter, setAtLeastOneLowercaseLetter] = useState<boolean | null>(null);
  const [atLeastOneUppercaseLetter, setAtLeastOneUppercaseLetter] = useState<boolean | null>(null);
  const [atLeastOneNumber, setAtLeastOneNumber] = useState<boolean | null>(null);
  const [atLeastOneSpecialChar, setAtLeastOneSpecialChar] = useState<boolean | null>(null);
  const [isPasswordStrengthError, setIsPasswordStrengthError] = useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <div>
      <Controller
        control={control}
        name={id}
        rules={rules}
        render={({ field: { onChange, onBlur, value, name, ref }, fieldState, formState }) => (
          <>
            <TextField
              type={showPassword ? 'text' : 'password'}
              onBlur={onBlur}
              size="small"
              onChange={(e) => {
                const newValue = e.target.value;
                onChange(prefix ? `${prefix}${newValue}` : newValue);

                if (displayPasswordRequirements) {
                  const isAtLeastMinimumLength = new RegExp(/(?=.{8,})/).test(newValue);
                  const isAtLeastOneLowercaseLetter = new RegExp(/(?=.*?[a-z])/).test(newValue);
                  const isAtLeastOneUppercaseLetter = new RegExp(/(?=.*?[A-Z])/).test(newValue);
                  const isAtLeastOneNumber = new RegExp(/(?=.*?[0-9])/).test(newValue);
                  const isAtLeastOneSpecialChar = new RegExp(
                    /(?=.*?[`~!@#$%^&*_()\-+={}[\]:;"',.?/<>])/,
                  ).test(newValue);

                  setAtLeastMinimumLength(isAtLeastMinimumLength);
                  setAtLeastOneLowercaseLetter(isAtLeastOneLowercaseLetter);
                  setAtLeastOneUppercaseLetter(isAtLeastOneUppercaseLetter);
                  setAtLeastOneNumber(isAtLeastOneNumber);
                  setAtLeastOneSpecialChar(isAtLeastOneSpecialChar);

                  const passwordStrengthError = !(
                    isAtLeastMinimumLength &&
                    isAtLeastOneLowercaseLetter &&
                    isAtLeastOneUppercaseLetter &&
                    isAtLeastOneNumber &&
                    isAtLeastOneSpecialChar
                  );

                  setIsPasswordStrengthError(passwordStrengthError);
                }
              }}
              value={value ? value.replace(prefix, '') : ''}
              inputRef={ref}
              sx={{
                '& .MuiOutlinedInput-root': {
                  paddingLeft: 0,
                  '&:hover fieldset': {
                    borderColor:
                      errors && getNestedError(errors, id) ? 'var(--red)' : 'var(--grey-400)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor:
                      errors && getNestedError(errors, id) ? 'var(--red)' : 'var(--blue-400)',
                  },
                },
                '& fieldset': {
                  borderColor:
                    errors && getNestedError(errors, id) ? 'var(--red)' : 'var(--grey-400)',
                },
                '.MuiInputBase-input': {
                  fontSize: '14px',
                },
              }}
              placeholder={placeholder}
              fullWidth
              InputLabelProps={{ shrink: false }}
              InputProps={{
                startAdornment: prefix && (
                  <InputAdornment
                    sx={{
                      padding: '27px 14px',
                      backgroundColor: 'var(--grey-200)',
                      borderRight: '1px solid var(--grey-400)',
                      borderTopLeftRadius: (theme) => theme.shape.borderRadius + 'px',
                      borderBottomLeftRadius: (theme) => theme.shape.borderRadius + 'px',
                    }}
                    position="start"
                  >
                    <span className="text-sm">{prefix}</span>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {errors && getNestedError(errors, id) ? (
                      <Warning />
                    ) : (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
                sx: { borderRadius: '6px' },
              }}
            />
          </>
        )}
      />
      {!isPasswordStrengthError && errors && getNestedError(errors, id) && (
        <span style={{ color: 'var(--red)' }} role="alert">
          {getNestedError(errors, id)?.message}
        </span>
      )}
      {displayPasswordRequirements && (
        <div className="ml-5 mt-4 mb-2">
          <span>Password must contain:</span>
          <div className="ml-4 mt-2">
            <div className="flex">
              {atLeastMinimumLength === null ? (
                <CircleIcon />
              ) : atLeastMinimumLength ? (
                <ValidatedSuccessIcon />
              ) : (
                <ValidatedFailedIcon />
              )}
              <span
                className={clsx(
                  'ml-2',
                  atLeastMinimumLength == null
                    ? ''
                    : atLeastMinimumLength
                      ? 'text-emerald-700'
                      : 'text-rose-600',
                )}
              >
                min. 8 characters
              </span>
            </div>
            <div className="flex mt-1">
              {atLeastOneLowercaseLetter === null ? (
                <CircleIcon />
              ) : atLeastOneLowercaseLetter ? (
                <ValidatedSuccessIcon />
              ) : (
                <ValidatedFailedIcon />
              )}
              <span
                className={clsx(
                  'ml-2',
                  atLeastOneLowercaseLetter == null
                    ? ''
                    : atLeastOneLowercaseLetter
                      ? 'text-emerald-700'
                      : 'text-rose-600',
                )}
              >
                min. 1 lower case character
              </span>
            </div>
            <div className="flex mt-1">
              {atLeastOneUppercaseLetter === null ? (
                <CircleIcon />
              ) : atLeastOneUppercaseLetter ? (
                <ValidatedSuccessIcon />
              ) : (
                <ValidatedFailedIcon />
              )}
              <span
                className={clsx(
                  'ml-2',
                  atLeastOneUppercaseLetter == null
                    ? ''
                    : atLeastOneUppercaseLetter
                      ? 'text-emerald-700'
                      : 'text-rose-600',
                )}
              >
                min. 1 upper case character
              </span>
            </div>
            <div className="flex mt-1">
              {atLeastOneNumber === null ? (
                <CircleIcon />
              ) : atLeastOneNumber ? (
                <ValidatedSuccessIcon />
              ) : (
                <ValidatedFailedIcon />
              )}
              <span
                className={clsx(
                  'ml-2',
                  atLeastOneNumber == null
                    ? ''
                    : atLeastOneNumber
                      ? 'text-emerald-700'
                      : 'text-rose-600',
                )}
              >
                min. 1 number
              </span>
            </div>
            <div className="flex mt-1">
              {atLeastOneSpecialChar === null ? (
                <CircleIcon />
              ) : atLeastOneSpecialChar ? (
                <ValidatedSuccessIcon />
              ) : (
                <ValidatedFailedIcon />
              )}
              <span
                className={clsx(
                  'ml-2',
                  atLeastOneSpecialChar == null
                    ? ''
                    : atLeastOneSpecialChar
                      ? 'text-emerald-700'
                      : 'text-rose-600',
                )}
              >
                min. 1 special character
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Password;
