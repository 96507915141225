import { useEffect, useRef, useState } from 'react';
import AdminCreateReportMonikers from './AdminCreateReportMonikers';
import { countNonEmptyRows, getSessionDataPermissions } from '@utils/utils';
import { ReactComponent as Warning } from '@assets/icons/warning.svg';
import { validateScamEnablers } from './AdminCreateReportUtility';
import { useLocation } from 'react-router-dom';

const AdminCreateReportScamEnablers = ({
  formMethods,
  errors,
  setErrors,
}: {
  formMethods: any;
  errors: any;
  setErrors: any;
}) => {
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const reportType = queryParams.get('route');
  type Category = 'Banks' | 'Paynow' | 'Monikers' | 'Fintech';
  const userPermissions = getSessionDataPermissions();
  const categoryPermissions: any = {
    Banks: ['reports.banks.create'],
    Paynow: ['reports.paynows.create'],
    Fintech: ['reports.banks.create'],
    Monikers: ['reports.scamenablers.create'],
  };
  const allowedCategory: Category[] = [];

  for (let category in categoryPermissions) {
    if (
      categoryPermissions[category].some((categoryPermission: string) =>
        userPermissions.includes(categoryPermission),
      )
    ) {
      allowedCategory.push(category as Category);
    }
  }

  const [selectedCategory, setSelectedCategory] = useState<Category>(
    reportType === 'monikers' ? 'Monikers' : allowedCategory[0],
  );
  // const [errors, setErrors] = useState<any>([]);
  const clickTab = (selectedCategory: Category) => {
    setSelectedCategory(selectedCategory);
  };
  // const useFormValues = () => {
  //   const { getValues } = useFormContext();
  //   return {
  //     ...useWatch(), // subscribe to form value updates
  //     ...getValues(), // always merge with latest form values
  //   };
  // };
  const formValues = formMethods.getValues();

  useEffect(() => {
    let errors = validateScamEnablers(formValues, formMethods, true);
    setErrors(errors);
  }, [selectedCategory]);

  return (
    <div className="flex flex-row h-full  pb-4 ">
      {/* Side tab */}
      <div className="w-44 flex flex-col gap-2 ">
        <p className="font-bold text-lg">Scam Enablers</p>
        <div className=" bg-grey-300 w-44 p-4 flex flex-col flex-grow gap-6 items-start rounded-lg">
          {allowedCategory.includes('Banks') && (
            <button
              onClick={() => clickTab('Banks')}
              className="flex flex-row justify-between w-full items-center"
            >
              <span
                className={`font-bold flex items-center ${
                  selectedCategory === 'Banks' ? 'text-blue-400' : 'text-blue-500'
                }`}
              >
                Banks
              </span>
              {countNonEmptyRows(formMethods.getValues('scamEnablers.Banks')) > 0 && (
                <div className="flex flex-row items-center gap-1">
                  {errors?.bank?.length > 0 && <Warning />}
                  <p
                    className={`text-xs  h-[20px] w-[20px]  flex items-center justify-center  text-white rounded-full ${selectedCategory === 'Banks' ? 'bg-blue-400' : 'bg-blue-500'}`}
                  >
                    {countNonEmptyRows(formMethods.getValues('scamEnablers.Banks'))}
                  </p>
                </div>
              )}
            </button>
          )}
          {allowedCategory.includes('Paynow') && (
            <button
              onClick={() => clickTab('Paynow')}
              className="flex flex-row justify-between w-full items-center"
            >
              <p
                className={`font-bold  flex items-center ${
                  selectedCategory === 'Paynow' ? 'text-blue-400' : 'text-blue-500'
                }`}
              >
                PayNow
              </p>
              {countNonEmptyRows(formMethods.getValues('scamEnablers.Paynow')) > 0 && (
                <div className="flex flex-row items-center gap-1">
                  {errors?.paynow?.length > 0 && <Warning />}

                  <p
                    className={`text-xs  h-[20px] w-[20px]  flex items-center justify-center  text-white rounded-full ${selectedCategory === 'Paynow' ? 'bg-blue-400' : 'bg-blue-500'}`}
                  >
                    {countNonEmptyRows(formMethods.getValues('scamEnablers.Paynow'))}
                  </p>
                </div>
              )}
            </button>
          )}
          {allowedCategory.includes('Fintech') && (
            <button
              onClick={() => clickTab('Fintech')}
              className="flex flex-row justify-between w-full items-center"
            >
              <p
                className={`font-bold  flex items-center ${
                  selectedCategory === 'Fintech' ? 'text-blue-400' : 'text-blue-500'
                }`}
              >
                Fintech/Crypto
              </p>
              {countNonEmptyRows(formMethods.getValues('scamEnablers.Fintech')) > 0 && (
                <div className="flex flex-row items-center gap-1">
                  {errors?.fintech?.length > 0 && <Warning />}

                  <p
                    className={`text-xs  h-[20px] w-[20px]  flex items-center justify-center  text-white rounded-full ${selectedCategory === 'Fintech' ? 'bg-blue-400' : 'bg-blue-500'}`}
                  >
                    {countNonEmptyRows(formMethods.getValues('scamEnablers.Fintech'))}
                  </p>
                </div>
              )}
            </button>
          )}
          {allowedCategory.includes('Monikers') && (
            <button
              onClick={() => clickTab('Monikers')}
              className="flex flex-row justify-between w-full items-center"
            >
              <p
                className={`font-bold  flex items-center ${
                  selectedCategory === 'Monikers' ? 'text-blue-400' : 'text-blue-500'
                }`}
              >
                Monikers
              </p>
              {countNonEmptyRows(formMethods.getValues('scamEnablers.Monikers')) > 0 && (
                <div className="flex flex-row items-center gap-1">
                  {errors?.monikers?.length > 0 && <Warning />}
                  <p
                    className={`text-xs  h-[20px] w-[20px]  flex items-center justify-center  text-white rounded-full ${selectedCategory === 'Monikers' ? 'bg-blue-400' : 'bg-blue-500'}`}
                  >
                    {countNonEmptyRows(formMethods.getValues('scamEnablers.Monikers'))}
                  </p>
                </div>
              )}
            </button>
          )}
        </div>
      </div>

      {/* Main content (forms) */}
      <div className="w-full  h-full mx-4 ">
        {allowedCategory.includes('Banks') && selectedCategory === 'Banks' && (
          <AdminCreateReportMonikers
            formMethods={formMethods}
            selectedCategory={selectedCategory}
            sectionRefs={sectionRefs}
          />
        )}
        {allowedCategory.includes('Paynow') && selectedCategory === 'Paynow' && (
          <AdminCreateReportMonikers
            formMethods={formMethods}
            selectedCategory={selectedCategory}
            sectionRefs={sectionRefs}
          />
        )}
        {allowedCategory.includes('Fintech') && selectedCategory === 'Fintech' && (
          <AdminCreateReportMonikers
            formMethods={formMethods}
            selectedCategory={selectedCategory}
            sectionRefs={sectionRefs}
          />
        )}
        {allowedCategory.includes('Monikers') && selectedCategory === 'Monikers' && (
          <AdminCreateReportMonikers
            formMethods={formMethods}
            selectedCategory={selectedCategory}
            sectionRefs={sectionRefs}
          />
        )}
      </div>
    </div>
  );
};

export default AdminCreateReportScamEnablers;
