export interface Config {
    local: EnvironmentConfig;
    dev: EnvironmentConfig;
    stg: EnvironmentConfig;
    prd: EnvironmentConfig;
}

export interface EnvironmentConfig {
    BACKEND_URL: string;
    GOOGLE_ANALYTICS_TAG: string;
}

export const CONFIG: Config = {
    local: {
        BACKEND_URL: "http://localhost:3000/dev",
        GOOGLE_ANALYTICS_TAG: "G-ZC7VCZ5SXR"
    },
    dev: {
        BACKEND_URL: "https://backend.satisplus-dev.gasp.gov.sg",
        GOOGLE_ANALYTICS_TAG: "G-ZC7VCZ5SXR"
    },
    stg: {
        BACKEND_URL: "https://backend.satisplus-stg.gasp.gov.sg",
        GOOGLE_ANALYTICS_TAG: "G-RN6PYPDYSX"
    },
    prd: {
        BACKEND_URL: "https://backend.satisplus.gasp.gov.sg",
        GOOGLE_ANALYTICS_TAG: "G-SN1DDXPJHS"
    },
    
}