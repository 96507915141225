import { Modal, Stack } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { getConfig } from '../MonikersDetailedPage/AssessmentFindings/AssessmentFindingsConfig';
import { useForm } from 'react-hook-form';
import { CommonButton } from '@components/common/CommonButton';
import ModalLayout from '@components/layouts/ModalLayout';
import {
  useCreateProductionOrderV2Mutation,
  useDownloadProductionOrders,
  useSendProductionOrderQueue,
} from '@api/productionOrderSlice';
import Promise from 'bluebird';
import { ReactComponent as ExpandMoreIcon } from '@assets/icons/down_arrow.svg';
import { ReactComponent as ExpandLessIcon } from '@assets/icons/up_arrow.svg';
import { ErrorOutline } from '@mui/icons-material';
import { useBulkUpdateAssessmentFindingsV2Mutation } from '@api/scamEnablerSlice';
import { ReactComponent as Warning } from '@assets/icons/warning_yellow.svg';
import { CHECKLIST_VERSION } from '../../../../constants';
import AssessmentFindingsMonikerPresubmitChecklist1 from '../MonikersDetailedPage/AssessmentFindings/AssessmentFindingsMonikerPresubmitChecklist1';
import { getSessionUserFromAccessToken, convertValues } from '@utils/utils';
import { MonikerOutcomeStatus } from '@components/common/StatusComponent';
import AssignModal from '../modals/AssignModal';
import { useDispatch } from 'react-redux';
import { showAlert } from '@store/alertSlice';
import { Table } from 'antd';
import cloneDeep from 'lodash.clonedeep';

const selectedScamEnablersColumns = [
  {
    title: 'Scam Enabler User ID',
    dataIndex: 'scamEnablerUserId',
    key: 'scamEnablerUserId',
    width: '30%',
  },
  {
    title: 'Platform',
    dataIndex: 'platform',
    key: 'platform',
    width: '12%',
  },
  {
    title: 'DA1 Assigned',
    dataIndex: 'assignment',
    key: 'assignment',
    width: '23%',
    render: (assignment: any, record: any, index: number) =>
      assignment?.filter((e: any) => e.role === 'da1')[0].userName || '-',
  },
  {
    title: 'Outcome',
    dataIndex: 'outcome',
    key: 'outcome',
    width: '30%',
    render: (outcome: string, record: any, index: number) => (
      <MonikerOutcomeStatus outcome={outcome} />
    ),
  },
  {
    title: '',
    dataIndex: 'invalid',
    key: 'invalid',
    width: '5%',
    render: (invalid: boolean, record: any, index: number) => <>{invalid && <Warning />}</>,
  },
];

const UpdateAssessmentFindingsMonikerPresubmitChecklist1Modal = ({
  isOpen,
  handleClose,
  selectedScamEnablers,
  callback,
}: {
  isOpen: boolean;
  handleClose: () => void;
  selectedScamEnablers: any[];
  callback: (alertMessage?: any) => void;
}) => {
  const [bulkUpdateAssessmentFindings] = useBulkUpdateAssessmentFindingsV2Mutation();
  const [isAssessmentFindingsAPILoading, setIsAssessmentFindingsAPILoading] = useState(false);
  const sendProductionOrderQueue = useSendProductionOrderQueue();
  const [createProductionOrderV2] = useCreateProductionOrderV2Mutation();
  const downloadProductionOrders = useDownloadProductionOrders();
  const [isExpanded, setIsExpanded] = useState(false);
  const tableRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();

  const [currentUser, setCurrentUser] = useState<any>({});
  const [showOcha, setShowOcha] = useState(true);
  const formMethodsDa1 = useForm({
    mode: 'onChange',
    defaultValues: {
      channel: 'OCHA',
    },
  });

  const [isSendPOQueueLoading, setIsSendPOQueueLoading] = useState(false);
  const [assignOpen, setAssignOpen] = useState(false);

  const [validScamEnablers, setValidScamEnablers] = useState<any[]>([]);
  const [invalidScamEnablers, setInvalidScamEnablers] = useState<any[]>([]);
  const [validScamEnablerIds, setValidScamEnablerIds] = useState<any[]>([]);

  const allSamePlatform = validScamEnablers.every(
    (scamEnabler: any) => scamEnabler.platform === validScamEnablers[0].platform,
  );

  useEffect(() => {
    const getSessionData = async () => {
      try {
        const response = await getSessionUserFromAccessToken();

        setCurrentUser(response);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    getSessionData();
  }, []);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isExpanded]);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    let tempValidScamEnablers: any = [];
    let tempValidScamEnablerIds: any = [];
    let tempInvalidScamEnablers: any = [];
    selectedScamEnablers.forEach((scamEnabler) => {
      let tempScamEnabler = { ...scamEnabler };
      if (tempScamEnabler.invalid) {
        tempInvalidScamEnablers.push(scamEnabler);
      } else {
        tempValidScamEnablers.push(scamEnabler);
        tempValidScamEnablerIds.push(scamEnabler._id);
      }
    });
    setValidScamEnablers(tempValidScamEnablers);
    setValidScamEnablerIds(tempValidScamEnablerIds);
    setInvalidScamEnablers(tempInvalidScamEnablers);
  }, [selectedScamEnablers]);

  const showPOButton = () => {
    let formResultsDa1 = convertValues(formMethodsDa1.getValues());
    let showButton: boolean = false;

    if (
      currentUser?.permissions?.includes('customs.sao') ||
      formResultsDa1?.channel === 'Community Guideline'
    ) {
      showButton = true;
    }

    return showButton;
  };

  const generateProductionOrder = async () => {
    let productionOrderRequest: any[] = [];

    await Promise.map(validScamEnablers, async (scamEnabler: any) => {
      productionOrderRequest.push({
        scamEnablerId: scamEnabler._id,
      });
    });
    let params = {
      productionOrderRequest: productionOrderRequest,
      channel: formMethodsDa1.getValues().channel,
      platform: validScamEnablers[0].platform,
    };
    let response: any = await createProductionOrderV2(params);

    if (response.error) {
      dispatch(
        showAlert({
          type: 'error',
          message: response.error,
        }),
      );
    } else if (response.data) {
      if (response.data.productionOrder.length === 1) {
        downloadProductionOrders(response.data.productionOrder[0].productionOrderId, 'v1', false);
      } else {
        let productionOrderIds = response.data.productionOrder.map(
          (row: any) => row.productionOrderId,
        );
        await downloadProductionOrders(productionOrderIds, 'v1', false);
      }

      callback({
        message: (
          <div>
            <p>Monikers bulk assessed and PO generated successfully.</p>
            <p>Please check your download folder.</p>
          </div>
        ),
        severity: 'success',
      });
    }
  };

  const saveAssessmentFindings = async () => {
    let formResults: any = formMethodsDa1.getValues();
    setIsAssessmentFindingsAPILoading(true);
    if (formResults.channel !== 'OCHA') {
      getConfig(CHECKLIST_VERSION)?.assessmentFindingsDa1PartItems.forEach((sections: any) => {
        sections.extras.checkboxData.forEach((element: any) => {
          delete formResults[element.id];
        });
      });
    }
    let params = {
      scamEnablerIds: validScamEnablerIds,
      monikerPreSubmitChecklist1: {
        version: CHECKLIST_VERSION,
        checklist: formResults,
      },
    };
    let response: any = await bulkUpdateAssessmentFindings(params);
    return response;
  };

  const onSaveAndAssignDA2 = async (event: any) => {
    event.preventDefault();
    setIsAssessmentFindingsAPILoading(true);

    let response: any = await saveAssessmentFindings();
    if (response && !response.error) {
      setAssignOpen(true);
    }
    setIsAssessmentFindingsAPILoading(false);
  };

  const onSubmitPOQueue = async (event: any) => {
    event.preventDefault();
    setIsSendPOQueueLoading(true);
    let assessmentFindingsResponse = await saveAssessmentFindings();
    if (assessmentFindingsResponse.error) {
      setIsSendPOQueueLoading(false);
      return;
    }

    let response = await sendProductionOrderQueue(validScamEnablerIds, 'monikerView');
    if (response.error) {
      setIsSendPOQueueLoading(false);
      return;
    }

    if (callback) {
      callback({
        message: 'Monikers bulk assessed and sent to directions queue successfully.',
        severity: 'success',
      });
    }
  };

  const onGeneratePO = async (event: any) => {
    event.preventDefault();
    setIsSendPOQueueLoading(true);
    let assessmentFindingsResponse = await saveAssessmentFindings();
    if (assessmentFindingsResponse.error) {
      setIsSendPOQueueLoading(false);
      return;
    }
    await generateProductionOrder();

    if (callback) {
      callback({
        message: 'Monikers bulk assessed and PO generated successfully.',
        severity: 'success',
      });
    }
  };

  const onAssignClose = () => {
    setAssignOpen(false);
  };

  const isAllowGeneratePO = () => {
    if (formMethodsDa1.getValues().channel === 'Community Guideline') {
      if (allSamePlatform) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalLayout
          title={'Bulk Assess'}
          handleCloseModal={handleClose}
          alignment="center"
          size="medium-tall"
          overflowClass="overflow-none"
        >
          <div className="-mt-8">
            <p className="text-grey-400 mb-3">
              The checklist below will apply to all the monikers you have just added. If any
              monikers require a different assessment, assess them individually on their details
              page.
            </p>
          </div>
          <Stack sx={{ width: '100%' }} spacing={1}>
            {invalidScamEnablers.length > 0 && (
              <div>
                <div className="rounded px-2 py-1 mb-3 font-semibold bg-yellow-200 border border-yellow flex flex-row text-black items-center gap-1">
                  <Warning className="w-16" />
                  <p className="pl-2 text-xs">
                    Only monikers under <b>Pending Review (not assigned / assigned to you)</b>, <b>Under Review (assigned to you)</b>, <b>Not Terminated</b> or{' '}
                    <b>No Action Required</b> status are eligible for
                    bulk assessment. There are monikers that do not meet the criteria -
                    bulk assess will not be applied to these monikers.
                    <button
                      className="pl-2 text-blue-500 hover:text-blue-400 underline"
                      onClick={toggleExpansion}
                    >
                      View list of monikers
                    </button>
                  </p>
                </div>
              </div>
            )}

            <div
              className={`overflow-auto ${
                invalidScamEnablers.length > 0 ? 'h-[56vh]' : 'h-[66vh]'
              }`}
            >
              <AssessmentFindingsMonikerPresubmitChecklist1
                formMethods={formMethodsDa1}
                isReassigned={false}
                showOcha={showOcha}
                setShowOcha={setShowOcha}
                currentRole={'da1'}
                callback={callback}
                isEditing={true}
                isLoading={false}
                source={'modal'}
              />
              {selectedScamEnablers.length > 0 && (
                <div className="flex flex-col" ref={tableRef}>
                  <div className="flex flex-row gap-4 items-center">
                    <h5 className="font-h4-bold-14">{`Selected monikers: ${selectedScamEnablers.length}`}</h5>
                    <button
                      className="py-2 px-4 text-white font-bold rounded-lg my-4 flex items-center justify-center"
                      onClick={toggleExpansion}
                    >
                      {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </button>
                  </div>
                  {isExpanded && (
                    <Table
                      rowKey="scamEnablerUserId"
                      dataSource={cloneDeep(selectedScamEnablers)}
                      columns={selectedScamEnablersColumns}
                      pagination={false}
                    />
                  )}
                </div>
              )}
            </div>

            <div className=" flex flex-col  sticky  bottom-0 pb-4 bg-white">
              <div className={`flex flex-row justify-end min-h-2`}>
                <div className={`flex flex-row ${isAllowGeneratePO() ? 'invisible' : ''}`}>
                  <ErrorOutline className="mr-2" />
                  <p className="text-grey-400 text-sm italic ">
                    Generation of PO is only allowed if the selected monikers have the same platform
                  </p>
                </div>
              </div>
              <div className="flex flex-row gap-5 justify-end pt-2">
                <CommonButton variant="text" title="Cancel" onClick={handleClose} type="button" />
                {!currentUser?.permissions?.includes('customs.sao') && showOcha && (
                  <CommonButton
                    isDisabled={!formMethodsDa1.formState.isValid}
                    variant="primary"
                    title={'Save & Assign DA2'}
                    isLoading={isAssessmentFindingsAPILoading}
                    onClick={(e: any) => {
                      onSaveAndAssignDA2(e);
                    }}
                  />
                )}
                {showPOButton() && (
                  <>
                    <CommonButton
                      variant="secondary"
                      title={`Save & Send to Queue`}
                      onClick={(e: any) => {
                        onSubmitPOQueue(e);
                      }}
                      isLoading={isSendPOQueueLoading}
                      isDisabled={!formMethodsDa1.formState.isValid}
                    />

                    <CommonButton
                      variant="primary"
                      title={`Save & Generate PO`}
                      onClick={(e: any) => {
                        onGeneratePO(e);
                      }}
                      isLoading={isSendPOQueueLoading}
                      isDisabled={!formMethodsDa1.formState.isValid || !isAllowGeneratePO()}
                    />
                  </>
                )}
              </div>
            </div>
          </Stack>
        </ModalLayout>
      </Modal>
      {assignOpen && (
        <AssignModal
          selectedScamEnablers={validScamEnablers}
          showSelectedScamEnablers={false}
          assignOpen={assignOpen}
          onAssignClose={onAssignClose}
          callback={callback}
          isDA2Assign={true}
        />
      )}
    </>
  );
};

export default UpdateAssessmentFindingsMonikerPresubmitChecklist1Modal;
