import { useEffect, useState } from 'react';
import { Badge } from '@mui/material';
import CommentsModal from '../../modals/CommentsModal';

import { CommonButton, IconButton } from '@components/common/CommonButton';
import {
  useGetScamEnablerCommentsQuery,
  useUpdateScamEnablerCommentsReadMutation,
} from '@api/scamEnablerSlice';
import { DownloadProductionOrderSingleButton } from '@pages/reports/Buttons';
import {
  convertMsToDDMMYYYYHHmm,
  convertMsToHoursMinutes,
  convertUnixToSingaporeTime,
} from '@utils/utils';
import CustomBadge from '@components/common/CustomBadge';
import AssessmentFindingsDetailsComponent from './AssessmentFindingsDetailsComponent';
import cloneDeep from 'lodash.clonedeep';
import { ReactComponent as Warning } from '@assets/icons/warning.svg';
import UpdateProductionOrderModal from '../../modals/UpdateProductionOrderModal';
import moment from 'moment';

const AssessmentFindingsComponent = ({
  data,
  callback,
  currentRole,
  currentUser,
  isLoading,
  scamEnablerId,
}: {
  data?: any;
  callback?: any;
  currentRole: string;
  currentUser: any;
  isLoading: boolean;
  scamEnablerId: string | undefined;
}) => {
  const [commentsModalOpen, setCommentsModalOpen] = useState(false);
  const [unreadComments, setUnreadComments] = useState([]);
  const [disableAddAssesmentFindings, setDisableAddAssesmentFindings] = useState(true);
  const [displayInProgressAssessmentFinding, setDisplayInProgressAssessmentFinding] =
    useState(true);

  const [updateCommentsRead] = useUpdateScamEnablerCommentsReadMutation();

  const [selectedAssessmentFindings, setSelectedAssessmentFindings] = useState(null);
  const [newAssessmentFindings, setNewAssessmentFindings] = useState(null);
  const [isReassign, setIsReassign] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedProductinoOrder, setSelectedProductionOrder] = useState<any>(null);

  const handleAssessmentFindingsDetailsComponent = (productionOrder: any) => {
    setSelectedAssessmentFindings(productionOrder);
  };

  const handleAssessmentFindingsReturn = () => {
    setSelectedAssessmentFindings(null);
    setNewAssessmentFindings(null);
  };

  const handleAddAssessmentFindings = async () => {
    let newData = cloneDeep(data);
    newData.scamEnabler.findings = {};
    setNewAssessmentFindings(newData);
  };

  const { data: commentsData, refetch: refetchComments } = useGetScamEnablerCommentsQuery({
    scamenabler_id: scamEnablerId,
  });

  useEffect(() => {
    let unread = commentsData?.filter((e: any) => !e.readAt && e.createdBy !== currentUser.userId);
    setUnreadComments(unread);
  }, [commentsData, currentUser]);
  const handleOpenCommentsModal = () => {
    setCommentsModalOpen(true);
  };

  useEffect((): any => {
    if (!data?.scamEnabler?.findings) {
      setDisplayInProgressAssessmentFinding(false);
      if (currentRole === 'da1') {
        setDisableAddAssesmentFindings(false);
      } else {
        setDisableAddAssesmentFindings(true);
      }
    } else {
      setDisableAddAssesmentFindings(true);
      setDisplayInProgressAssessmentFinding(true);
    }

    if (
      data?.scamEnabler?.outcome === 'Under Review' &&
      data?.scamEnabler?.findings?.monikerPreSubmitChecklist2
    ) {
      setIsReassign(true);
    } else {
      setIsReassign(false);
    }

    if (data?.scamEnabler?.productionOrders) {
      for (let i = 0; i < data?.scamEnabler?.productionOrders.length; i++) {
        let scamEnabler = data?.scamEnabler?.productionOrders[i].scamEnablers.find(
          (scamEnabler: any) => scamEnabler._id === data?.scamEnabler?._id,
        );
        if (
          scamEnabler?.findings?.monikerPreSubmitChecklist1?.createdAt ===
          data?.scamEnabler?.findings?.monikerPreSubmitChecklist1?.createdAt
        ) {
          setDisplayInProgressAssessmentFinding(false);
          setDisableAddAssesmentFindings(false);

          break;
        }
      }
    }
  }, [data?.scamEnabler?.findings, data?.scamEnabler?.productionOrders, currentRole, data?._id]);

  const handleCloseCommentsModal = async () => {
    setCommentsModalOpen(false);

    if (currentRole) {
      let unread = unreadComments.map((e: any) => e.scamEnablerCommentId);

      let params = {
        comments: unread,
      };

      await updateCommentsRead(params);
      refetchComments();
    }
  };

  if (selectedAssessmentFindings) {
    return (
      <AssessmentFindingsDetailsComponent
        data={data}
        callback={callback}
        currentRole={currentRole}
        currentUser={currentUser}
        isLoading={isLoading}
        scamEnablerId={scamEnablerId}
        productionOrder={selectedAssessmentFindings}
        onReturn={handleAssessmentFindingsReturn}
      />
    );
  }

  if (newAssessmentFindings) {
    return (
      <AssessmentFindingsDetailsComponent
        data={newAssessmentFindings}
        callback={callback}
        currentRole={currentRole}
        currentUser={currentUser}
        isLoading={isLoading}
        scamEnablerId={scamEnablerId}
        onReturn={handleAssessmentFindingsReturn}
      />
    );
  }

  let productionOrders: any = data?.scamEnabler?.productionOrders;
  let index = productionOrders?.length || 0;
  if (displayInProgressAssessmentFinding) {
    index++;
  }

  return (
    <div className="box-border flex-row bg-grey-100 rounded-lg p-6 h-full overflow-hidden">
      <div className="flex justify-between align-middle items-center">
        <h1 className="font-bold text-lg text-blue-500 ">Assessment Findings</h1>
        <div className="flex">
          <IconButton
            icon="add"
            type="button"
            onClick={() => handleAddAssessmentFindings()}
            isDisabled={disableAddAssesmentFindings}
            className="bg-blue-200 w-10 h-10 flex items-center justify-center rounded-lg mr-3"
          />
          <IconButton
            onClick={() => handleOpenCommentsModal()}
            icon="comment"
            type="button"
            isDisabled={false}
            className="bg-blue-200 w-10 h-10 flex items-center justify-center rounded-lg"
          />
          {unreadComments && unreadComments.length > 0 && currentRole && (
            <Badge
              className="mt-1 mr-1"
              badgeContent={unreadComments.length}
              max={999}
              sx={{
                '& .MuiBadge-badge': {
                  color: 'white',
                  backgroundColor: '#D96363',
                },
              }}
            />
          )}
        </div>
      </div>
      <div className="flex overflow-auto max-h-[75vh]">
        <div className="w-full">
          {displayInProgressAssessmentFinding && (
            <div
              className="bg-white rounded-lg flex items-center justify-between p-4 w-full overflow-hidden mt-4 cursor-pointer"
              onClick={() => handleAssessmentFindingsDetailsComponent(data)}
            >
              <div className="flex flex-col w-full justify-between gap-2">
                <div className="flex flex-row w-full items-center gap-3">
                  <p className="text-blue-500 font-bold text-sm">{`Assessment Findings ${index--}`}</p>
                  <p>
                    {data?.scamEnabler?.findings?.monikerPreSubmitChecklist1?.checklist
                      ?.channel && (
                      <CustomBadge
                        text={
                          data?.scamEnabler?.findings?.monikerPreSubmitChecklist1?.checklist
                            ?.channel
                        }
                      />
                    )}
                  </p>
                  {data?.scamEnabler?.outcome === 'PO Required' && (
                    <div className="flex justify align-middle text-orange font-bold text-sm ml-auto">
                      IN QUEUE
                    </div>
                  )}
                  {data?.scamEnabler?.outcome !== 'PO Required' && (
                    <div className="flex justify align-middle items-center text-red font-bold text-sm ml-auto">
                      PENDING {isReassign && <Warning className="size-6 ml-2" />}
                    </div>
                  )}
                </div>
                {data?.scamEnabler?.findings?.monikerPreSubmitChecklist1?.checklist
                  ?.substitutionOrderReferenceNo && (
                  <p className="text-grey-400 text-xs italic">Substitution Order</p>
                )}
              </div>
            </div>
          )}
          {productionOrders &&
            productionOrders.map((productionOrder: any) => {
              let showOcha = false;
              if (productionOrder?.directionReferenceNo) {
                showOcha = true;
              }
              let roundedNow = moment(productionOrder.createdAt);
              roundedNow = roundedNow.startOf('hour').add(1, 'hour');
              let productionOrderSentDate = roundedNow.valueOf();
              let returnTatObj = convertMsToHoursMinutes(
                productionOrder.closedAt - productionOrderSentDate,
              );
              let days = Math.floor(returnTatObj.hours / 24);
              let returnTat =
                days +
                ' days ' +
                (returnTatObj.hours % 24) +
                ' hours ' +
                returnTatObj.minutes +
                ' minutes';
              return (
                <div
                  key={productionOrder.productionOrderId}
                  className="bg-white rounded-lg flex items-start justify-between p-4 w-full overflow-hidden mt-4 cursor-pointer"
                  onClick={() => handleAssessmentFindingsDetailsComponent(productionOrder)}
                >
                  <div className="flex flex-col justify-between w-full gap-2">
                    <div className="flex">
                      <div className="flex flex-col gap-1">
                        <p className="text-blue-500 font-bold text-sm flex gap-3 items-center">
                          {`Assessment Findings ${index--}`}
                          {<CustomBadge text={showOcha ? `OCHA` : `Community Guideline`} />}
                        </p>
                        <p className="text-blue-500 font-bold text-sm">
                          PO#
                          {productionOrder.directionReferenceNo
                            ? productionOrder.directionReferenceNo
                            : productionOrder.productionOrderId}
                        </p>
                      </div>
                      <div
                        className="flex ml-auto justify-end items-start"
                        onClick={(e) => {
                          e.stopPropagation(); // This stops the event from propagating to the parent div
                        }}
                      >
                        <div className="text-pink font-bold text-sm mr-2">
                          {productionOrder?.status}
                        </div>
                        <DownloadProductionOrderSingleButton
                          productionOrderId={productionOrder.productionOrderId}
                        />
                      </div>
                    </div>

                    {productionOrder?.scamEnablers[0]?.findings?.monikerPreSubmitChecklist1
                      ?.checklist?.substitutionOrderReferenceNo && (
                      <p className="text-grey-400 text-xs italic">Substitution Order</p>
                    )}
                    <p className="font-bold text-grey-400 text-xs pt-2">
                      PLATFORM REFERENCE NO.: {productionOrder?.platformReferenceNo || '-'}
                    </p>
                    <div className="flex gap-2">
                      <div className="flex flex-col">
                        <p className="font-bold text-grey-400 text-xs">DATE OF PO SENT</p>
                        <p className="text-grey-400 text-xs">
                          {productionOrderSentDate
                            ? convertUnixToSingaporeTime(productionOrderSentDate)
                            : '-'}
                        </p>
                      </div>

                      <div className="w-px bg-grey-400" />
                      <div className="flex flex-col">
                        <p className="font-bold text-grey-400 text-xs">DATE OF REPLY</p>
                        <p className="text-grey-400 text-xs">
                          {productionOrder?.closedAt
                            ? convertUnixToSingaporeTime(productionOrder.closedAt)
                            : '-'}
                        </p>
                      </div>
                      <div className="w-px bg-grey-400" />
                      <div className="flex flex-col">
                        <p className="font-bold text-grey-400 text-xs">RETURN TAT</p>
                        <p className="text-grey-400 text-xs">
                          {productionOrder?.closedAt ? returnTat : '-'}
                        </p>
                      </div>
                    </div>
                    {!productionOrder?.status && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation(); // This stops the event from propagating to the parent div
                        }}
                      >
                        <CommonButton
                          title="Edit Platform Reference No. and Date of Reply"
                          variant="underline-blue-500-text-xs"
                          onClick={() => {
                            setSelectedProductionOrder(productionOrder);
                            setIsEditModalOpen(true);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <CommentsModal
        isOpen={commentsModalOpen}
        handleClose={() => handleCloseCommentsModal()}
        comments={commentsData}
        scamEnablerId={scamEnablerId}
        refetchComments={refetchComments}
        currentUser={currentUser}
        currentRole={currentRole}
      />
      <UpdateProductionOrderModal
        productionOrderId={selectedProductinoOrder?.productionOrderId}
        platformReferenceNo={selectedProductinoOrder?.platformReferenceNo}
        closedAt={selectedProductinoOrder?.closedAt}
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
        }}
        callback={callback}
      />
    </div>
  );
};

export default AssessmentFindingsComponent;
