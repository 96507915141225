import { Outlet } from "react-router-dom";
import EventTracker from "@components/analytics/EventTracker";
import PageView from "@components/analytics/PageView";

const AnalyticsLayout = () => {
  return (
    <> 
      <PageView />
      <EventTracker />
      <Outlet />
    </>
  );
};

export default AnalyticsLayout;