import Modal from '@mui/material/Modal';
import ModalLayout from '@components/layouts/ModalLayout';
import { ScamEnablerInterface } from '@interface/ScamEnablerInterface';
import { useGetProductionQueueDetailsByIdV2Query } from '@api/productionOrderSlice';
import { BankDetailedOverview } from '@pages/portfolios/banks/BanksDetailedPage/BankDetailedOverview';
import { FormItemInterface } from '@interface/dynamicFormInterface';

const ProductionOrderBankModal = ({
  poQueueId,
  isOpen,
  onClose,
}: {
  poQueueId: string;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { refetch, isLoading, data } = useGetProductionQueueDetailsByIdV2Query(
    {
      poQueueId: poQueueId,
      view: 'bankView',
    },
    {
      skip: poQueueId === '' && !isOpen,
    },
  );

  const handleCallback = () => {
    refetch();
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <ModalLayout
          title={data ? data.accountNumber : ''}
          handleCloseModal={onClose}
          alignment="right"
          size="full"
          link={data?._id ? `/admin/portfolios/bank/${data._id}` : ''}
          subTitle="Overview"
          linkText="Go to Bank Details Page"
          overflowClass="overflow-hidden"
        >
          <div className="max-h-[80vh] overflow-auto">
            <BankDetailedOverview
              data={data}
              refetch={() => {}}
              isDirectionQueueMonikerModal={true}
            />
          </div>
        </ModalLayout>
      </Modal>
    </>
  );
};

export default ProductionOrderBankModal;
