import ReactGA from "react-ga4";
/**
 * Tracks a button click event in Google Analytics
 * 
 * @param category - The event category (e.g., "User Actions")
 * @param action - The action performed (e.g., "Clicked Submit")
 */
export const trackButtonClick = (category: string, action: string, label?: string) => {
  ReactGA.event({
    category,  
    action 
  });

  console.log(`GA Button/Link Click: Category: ${category}, Action: ${action}`);
};