import {
  accountTypes,
  bankPlatforms,
  classifications,
  cryptoPlatforms,
  financialExchangePlatform,
  fintechPlatforms,
  platform,
  policeStations,
  scamType,
  scamTypesBriefFactsMapping,
  transactionTypes,
  useOfScamEnabler,
} from '@interface/constant';
import { FormItemInterface } from '@interface/dynamicFormInterface';
import { getFileMimeTypes, validateIdNotNil } from '@utils/utils';
import validator from 'validator';
import { getCountries } from 'libphonenumber-js';
import { getCountryNameAndFlag } from '@components/common/telephoneInput/countryData';
import cloneDeep from 'lodash.clonedeep';

export const faceBookItems: Array<FormItemInterface> = [
  {
    label: 'User ID',
    type: 'textInput',
    id: 'userId',
    alignment: 'column',
    // extras: { prefix: "FBID#" },
    rules: {
      required: 'Facebook User Id is required',
      pattern: {},
      validate: {
        isURL: (value: string) => {
          if (validator.isURL(value)) {
            return 'Please do not include "www.facebook.com" in the Facebook handle';
          }
        },
        isNotNil: (value: string) => {
          return validateIdNotNil(value);
        },
      },
    },
  },
  {
    label: 'URL',
    type: 'textInput',
    id: 'url',
    alignment: 'column',
    extras: {
      keyup: (value: string) => {
        if (value.toLowerCase().startsWith('facebook.com/')) {
          return `https://www.${value}`;
        } else if (value.toLowerCase().startsWith('www.facebook.com/')) {
          return `https://${value}`;
        } else {
          return value;
        }
      },
    },
    rules: {
      required: 'Facebook URL is required',
      validate: {
        startsWithFacebook: (value: string) => {
          if (!value.toLowerCase().startsWith('https://www.facebook.com/'))
            return 'URL must start with https://www.facebook.com/';
        },
      },
    },
  },

  {
    label: 'Tags (Max. 3 each)',
    type: 'dropdownMulti',
    id: 'tags',
    placeholder: 'Type a tag and press Enter…',
    extras: { allowFreeSolo: true, dropdownData: [], isUpperCase: true, maxSelections: 3 },
    optional: true,
  },
  {
    label: 'Screenshot(s)',
    type: 'screenshot',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
      allowPaste: true,
    },
    optional: true,
  },
  {
    label: 'Profile Name',
    type: 'textInput',
    id: 'profileName',
    rules: {},
    optional: true,
  },
  {
    label: 'Brief Facts',
    type: 'textInput',
    id: 'explanation',
    rules: {},
    optional: true,
    extras: {
      rows: 4,
      condition: {
        field: 'scamType',
        setValue: (scamType: keyof typeof scamTypesBriefFactsMapping) => {
          return scamTypesBriefFactsMapping[scamType];
        },
      },
    },
  },
];

export const instagramItems: Array<FormItemInterface> = [
  {
    label: 'User ID',
    type: 'textInput',
    id: 'userId',
    alignment: 'column',
    // extras: { prefix: "FBID#" },
    rules: {
      required: 'User Id is required',
      pattern: {},
      validate: {
        isURL: (value: string) => {
          if (validator.isURL(value)) {
            return 'Please do not include "www.instagram.com" in the Instagram handle';
          }
        },
        isNotNil: (value: string) => {
          return validateIdNotNil(value);
        },
      },
    },
  },
  {
    label: 'URL',
    type: 'textInput',
    id: 'url',
    alignment: 'column',
    extras: {
      keyup: (value: string) => {
        if (value.toLowerCase().startsWith('instagram.com/')) {
          return `https://www.${value}`;
        } else if (value.toLowerCase().startsWith('www.instagram.com/')) {
          return `https://${value}`;
        } else {
          return value;
        }
      },
    },
    rules: {
      required: 'Instagram URL is required',
      validate: {
        startsWithInstagram: (value: string) => {
          if (!value.toLowerCase().startsWith('https://www.instagram.com/'))
            return 'URL must start with https://www.instagram.com/';
        },
      },
    },
  },
  {
    label: 'Tags (Max. 3 each)',
    type: 'dropdownMulti',
    id: 'tags',
    placeholder: 'Type a tag and press Enter…',
    extras: { allowFreeSolo: true, dropdownData: [], isUpperCase: true, maxSelections: 3 },
    optional: true,
  },
  {
    label: 'Screenshot(s)',
    type: 'screenshot',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
      allowPaste: true,
    },
    optional: true,
  },
  {
    label: 'Profile Name',
    type: 'textInput',
    id: 'profileName',
    rules: {},
    optional: true,
  },
  {
    label: 'Brief Facts',
    type: 'textInput',
    id: 'explanation',
    rules: {},
    optional: true,
    extras: {
      rows: 4,
      condition: {
        field: 'scamType',
        setValue: (scamType: keyof typeof scamTypesBriefFactsMapping) => {
          return scamTypesBriefFactsMapping[scamType];
        },
      },
    },
  },
];

export const ShopeeItems: Array<FormItemInterface> = [
  {
    label: 'User ID',
    type: 'textInput',
    id: 'userId',
    alignment: 'column',
    // extras: { prefix: "FBID#" },
    rules: {
      required: 'User Id is required',
    },
  },
  {
    label: 'URL',
    type: 'textInput',
    id: 'url',
    alignment: 'column',
    optional: true,
    extras: {
      keyup: (value: string) => {
        if (value && value.toLowerCase().startsWith('shopee.sg')) {
          return `https://${value}`;
        } else {
          return value;
        }
      },
    },
    rules: {
      pattern: {},
      validate: {
        startsWithShopee: (value: string) => {
          if (value && !value.toLowerCase().startsWith('https://shopee.sg/'))
            return 'URL must start with https://shopee.sg/';
        },
      },
    },
  },
  {
    label: 'Tags (Max. 3 each)',
    type: 'dropdownMulti',
    id: 'tags',
    placeholder: 'Type a tag and press Enter…',
    extras: { allowFreeSolo: true, dropdownData: [], isUpperCase: true, maxSelections: 3 },
    optional: true,
  },
  {
    label: 'Screenshot(s)',
    type: 'screenshot',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
      allowPaste: true,
    },
    alignment: 'column',
    optional: true,
  },
  {
    label: 'Profile Name',
    type: 'textInput',
    id: 'profileName',
    rules: {},
    optional: true,
  },
  {
    label: 'Brief Facts',
    type: 'textInput',
    id: 'explanation',
    rules: {},
    optional: true,
    extras: {
      rows: 4,
      condition: {
        field: 'scamType',
        setValue: (scamType: keyof typeof scamTypesBriefFactsMapping) => {
          return scamTypesBriefFactsMapping[scamType];
        },
      },
    },
  },
];

export const whatsappItems: Array<FormItemInterface> = [
  {
    label: 'User ID',
    type: 'telephoneInput',
    id: 'userId',
    alignment: 'column',
    placeholder: 'e.g. +6591234567',
    helperText: 'You may paste the full number with the country code into the field.',
    extras: {
      hideMessage: true,
      customClass: 'w-60 2xl:w-72',
    },
    rules: {
      required: "Scammer's Contact Number is required",
      pattern: {
        value: /^\+[0-9]+$/,
        message: 'Please enter a valid phone number. e.g. For Spain, +3512345678',
      },
    },
  },
  {
    label: 'URL',
    type: 'textInput',
    id: 'url',
    alignment: 'column',
    optional: true,
    extras: {},
    rules: {
      pattern: {},
    },
  },
  {
    label: 'Tags (Max. 3 each)',
    type: 'dropdownMulti',
    id: 'tags',
    placeholder: 'Type a tag and press Enter…',
    extras: { allowFreeSolo: true, dropdownData: [], isUpperCase: true, maxSelections: 3 },
    optional: true,
  },
  {
    label: 'Screenshot(s)',
    type: 'screenshot',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
      allowPaste: true,
    },
    alignment: 'column',
    optional: true,
  },
  {
    label: 'Profile Name',
    type: 'textInput',
    id: 'profileName',
    rules: {},
    optional: true,
  },
  {
    label: 'Brief Facts',
    type: 'textInput',
    id: 'explanation',
    rules: {},
    optional: true,
    extras: {
      rows: 4,
      condition: {
        field: 'scamType',
        setValue: (scamType: keyof typeof scamTypesBriefFactsMapping) => {
          return scamTypesBriefFactsMapping[scamType];
        },
      },
    },
  },
];

export const iMessageItems: Array<FormItemInterface> = [
  {
    label: 'Contact Method',
    type: 'dropdown',
    id: 'userIdType',
    alignment: 'column',
    extras: {
      dropdownData: ['Phone Number', 'Email'],
    },
    rules: {
      required: 'Contact Method is required',
    },
  },
  {
    label: "Scammer's Contact No.",
    id: 'userId-phone',
    type: 'telephoneInput',
    extras: {
      hideMessage: true,
      customClass: 'w-60 2xl:w-72',
      condition: {
        field: '${tableId}userIdType',
        disabled: (value: any) => value === 'Email',
      },
    },
    helperText: 'You may paste the full number with the country code into the field.',
    rules: {
      pattern: {
        value: /^\+[0-9]+$/,
        message: 'Please enter a valid phone number. e.g. For Spain, +3512345678',
      },
    },
  },
  {
    label: 'Email',
    id: 'userId-email',
    type: 'textInput',
    extras: {
      condition: {
        field: '${tableId}userIdType',
        disabled: (value: any) => value === 'Phone Number',
      },
    },
    rules: {
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Please enter a valid email address',
      },
    },
  },
  {
    label: 'URL',
    type: 'textInput',
    id: 'url',
    alignment: 'column',
    optional: true,
    extras: {},
    rules: {
      pattern: {},
    },
  },
  {
    label: 'Tags (Max. 3 each)',
    type: 'dropdownMulti',
    id: 'tags',
    placeholder: 'Type a tag and press Enter…',
    extras: { allowFreeSolo: true, dropdownData: [], isUpperCase: true, maxSelections: 3 },
    optional: true,
  },
  {
    label: 'Screenshot(s)',
    type: 'screenshot',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
      allowPaste: true,
    },
    alignment: 'column',
    optional: true,
    // rules: {
    //   required: true,
    // },
  },
  {
    label: 'Profile Name',
    type: 'textInput',
    id: 'profileName',
    rules: {},
    optional: true,
  },

  {
    label: 'Brief Facts',
    type: 'textInput',
    id: 'explanation',
    rules: {},
    optional: true,
    extras: {
      rows: 4,
      condition: {
        field: 'scamType',
        setValue: (scamType: keyof typeof scamTypesBriefFactsMapping) => {
          return scamTypesBriefFactsMapping[scamType];
        },
      },
    },
  },
];

export const googlePlaystoreApplicationItems: Array<FormItemInterface> = [
  {
    label: 'User ID',
    type: 'textInput',
    id: 'userId',
    alignment: 'column',
    rules: {
      required: 'User ID is required',
      pattern: {},
      validate: {
        isNotNil: (value: string) => {
          return validateIdNotNil(value);
        },
      },
    },
  },
  {
    label: 'URL',
    type: 'textInput',
    id: 'url',
    alignment: 'column',
    extras: {},
    optional: true,
    rules: {
      required: 'Google Playstore application URL is required',
      validate: {
        isURL: (value: string) => {
          if (!validator.isURL(value)) {
            return 'Please enter valid url';
          }
        },
      },
    },
  },
  {
    label: 'Tags (Max. 3 each)',
    type: 'dropdownMulti',
    id: 'tags',
    placeholder: 'Type a tag and press Enter…',
    extras: { allowFreeSolo: true, dropdownData: [], isUpperCase: true, maxSelections: 3 },
    optional: true,
  },
  {
    label: 'Screenshot(s)',
    type: 'screenshot',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
      allowPaste: true,
    },
    alignment: 'column',
    optional: true,
  },
  {
    label: 'Profile Name',
    type: 'textInput',
    id: 'profileName',
    rules: {},
    optional: true,
  },
  {
    label: 'Brief Facts',
    type: 'textInput',
    id: 'explanation',
    rules: {},
    optional: true,
    extras: {
      rows: 4,
      condition: {
        field: 'scamType',
        setValue: (scamType: keyof typeof scamTypesBriefFactsMapping) => {
          return scamTypesBriefFactsMapping[scamType];
        },
      },
    },
  },
];

export const carousellItems: Array<FormItemInterface> = [
  {
    label: 'User ID',
    type: 'textInput',
    id: 'userId',
    alignment: 'column',
    rules: {
      required: 'Carousell User Id is required',
      pattern: {},
      validate: {
        isURL: (value: string) => {
          if (validator.isURL(value)) {
            return 'Please do not include "https://www.carousell.sg" in the Carousell handle';
          }
        },
        isNotNil: (value: string) => {
          return validateIdNotNil(value);
        },
      },
    },
  },
  {
    label: 'URL',
    type: 'textInput',
    id: 'url',
    alignment: 'column',
    optional: true,
    extras: {},
    rules: {
      pattern: {},
    },
  },
  {
    label: 'Tags (Max. 3 each)',
    type: 'dropdownMulti',
    id: 'tags',
    placeholder: 'Type a tag and press Enter…',
    extras: { allowFreeSolo: true, dropdownData: [], isUpperCase: true, maxSelections: 3 },
    optional: true,
  },
  {
    label: 'Screenshot(s)',
    type: 'screenshot',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
      allowPaste: true,
    },
    alignment: 'column',
    optional: true,
  },
  {
    label: 'Profile Name',
    type: 'textInput',
    id: 'profileName',
    rules: {},
    optional: true,
  },
  {
    label: 'Brief Facts',
    type: 'textInput',
    id: 'explanation',
    rules: {},
    optional: true,
    extras: {
      rows: 4,
      condition: {
        field: 'scamType',
        setValue: (scamType: keyof typeof scamTypesBriefFactsMapping) => {
          return scamTypesBriefFactsMapping[scamType];
        },
      },
    },
  },
];

export const LazadaItems: Array<FormItemInterface> = [
  {
    label: 'User ID',
    type: 'textInput',
    id: 'userId',
    alignment: 'column',
    rules: {
      required: 'Lazada User Id is required',
      pattern: {},
      validate: {
        isURL: (value: string) => {
          if (validator.isURL(value)) {
            return 'Please do not include "www.lazada.com" in the Lazada handle';
          }
        },
        isNotNil: (value: string) => {
          return validateIdNotNil(value);
        },
      },
    },
  },
  {
    label: 'URL',
    type: 'textInput',
    id: 'url',
    alignment: 'column',
    optional: true,
    extras: {},
    rules: {
      pattern: {},
    },
  },
  {
    label: 'Tags (Max. 3 each)',
    type: 'dropdownMulti',
    id: 'tags',
    placeholder: 'Type a tag and press Enter…',
    extras: { allowFreeSolo: true, dropdownData: [], isUpperCase: true, maxSelections: 3 },
    optional: true,
  },
  {
    label: 'Screenshot(s)',
    type: 'screenshot',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
      allowPaste: true,
    },
    alignment: 'column',
    optional: true,
  },
  {
    label: 'Profile Name',
    type: 'textInput',
    id: 'profileName',
    rules: {},
    optional: true,
  },
  {
    label: 'Brief Facts',
    type: 'textInput',
    id: 'explanation',
    rules: {},
    optional: true,
    extras: {
      rows: 4,
      condition: {
        field: 'scamType',
        setValue: (scamType: keyof typeof scamTypesBriefFactsMapping) => {
          return scamTypesBriefFactsMapping[scamType];
        },
      },
    },
  },
];

export const telegramItems: Array<FormItemInterface> = [
  {
    label: 'User ID',
    type: 'textInput',
    id: 'userId',
    alignment: 'column',
    rules: {
      required: 'Telegram handle is required',
      pattern: {},
      validate: {
        isURL: (value: string) => {
          if (validator.isURL(value)) {
            return 'Please do not include "https://t.me/" in the Telegram handle';
          }
        },
        isNotNil: (value: string) => {
          return validateIdNotNil(value);
        },
      },
    },
  },
  {
    label: 'URL',
    type: 'textInput',
    id: 'url',
    alignment: 'column',
    optional: true,
    extras: {},
    rules: {
      pattern: {},
    },
  },
  {
    label: 'Tags (Max. 3 each)',
    type: 'dropdownMulti',
    id: 'tags',
    placeholder: 'Type a tag and press Enter…',
    extras: { allowFreeSolo: true, dropdownData: [], isUpperCase: true, maxSelections: 3 },
    optional: true,
  },
  {
    label: 'Screenshot(s)',
    type: 'screenshot',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
      allowPaste: true,
    },
    alignment: 'column',
    optional: true,
  },
  {
    label: 'Profile Name',
    type: 'textInput',
    id: 'profileName',
    rules: {},
    optional: true,
  },

  {
    label: 'Brief Facts',
    type: 'textInput',
    id: 'explanation',
    rules: {},
    optional: true,
    extras: {
      rows: 4,
      condition: {
        field: 'scamType',
        setValue: (scamType: keyof typeof scamTypesBriefFactsMapping) => {
          return scamTypesBriefFactsMapping[scamType];
        },
      },
    },
  },
];

export const twitterItems: Array<FormItemInterface> = [
  {
    label: 'User ID',
    type: 'textInput',
    id: 'userId',
    alignment: 'column',
    rules: {
      required: 'X Username/Link is required',
      pattern: {},
      validate: {
        isURL: (value: string) => {
          if (validator.isURL(value)) {
            return 'Please do not include "www.x.com" in the X handle';
          }
        },
        isNotNil: (value: string) => {
          return validateIdNotNil(value);
        },
      },
    },
  },
  {
    label: 'URL',
    type: 'textInput',
    id: 'url',
    alignment: 'column',
    optional: true,
    extras: {},
    rules: {
      pattern: {},
    },
  },
  {
    label: 'Tags (Max. 3 each)',
    type: 'dropdownMulti',
    id: 'tags',
    placeholder: 'Type a tag and press Enter…',
    extras: { allowFreeSolo: true, dropdownData: [], isUpperCase: true, maxSelections: 3 },
    optional: true,
  },
  {
    label: 'Screenshot(s)',
    type: 'screenshot',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
      allowPaste: true,
    },
    alignment: 'column',
    optional: true,
  },
  {
    label: 'Profile Name',
    type: 'textInput',
    id: 'profileName',
    rules: {},
    optional: true,
  },
  {
    label: 'Brief Facts',
    type: 'textInput',
    id: 'explanation',
    rules: {},
    optional: true,
    extras: {
      rows: 4,
      condition: {
        field: 'scamType',
        setValue: (scamType: keyof typeof scamTypesBriefFactsMapping) => {
          return scamTypesBriefFactsMapping[scamType];
        },
      },
    },
  },
];

// export const urlItems: Array<FormItemInterface> = [
//   {
//     label: 'URL',
//     type: 'textInput',
//     id: 'userId',
//     alignment: 'column',
//     placeholder: 'e.g. https://website.com',
//     rules: {
//       required: 'Website URL is required',
//       validate: (value: string) => {
//         if (!validator.isURL(value)) {
//           return 'Invalid URL';
//         }
//       },
//     },
//   },
//   {
//     label: 'Screenshot(s)',
//     type: 'screenshot',
//     id: 'attachments',
//     alignment: 'column',
//     optional: true,
//   },
// ];

export const urlItems: Array<FormItemInterface> = [
  {
    label: 'Website URL',
    type: 'tableInput',
    id: 'url',
    alignment: 'column',
    placeholder: 'Please enter',
    rules: {
      required: 'Website URL is required',
      validate: {
        isURL: (value: string) => {
          if (!validator.isURL(value)) {
            return 'Invalid website URL. Please provide URL in the correct format e.g. https://website.com or http://website.com';
          }
        },
        isNotMoniker: (value: string) => {
          if (
            value.toLowerCase().includes('www.facebook.com') ||
            value.toLowerCase().includes('www.instagram.com') ||
            value.toLowerCase().includes('t.me/') ||
            value.toLowerCase().includes('www.carousell.') ||
            value.toLowerCase().includes('www.lazada.') ||
            value.toLowerCase().includes('www.shopee.sg') ||
            value.toLowerCase().includes('www.line.me') ||
            value.toLowerCase().includes('www.wechat.com') ||
            value.toLowerCase().includes('https://wa.me/') ||
            value.toLowerCase().includes('www.tiktok.com')
          ) {
            return 'Moniker URL is not permitted. Please enter under the appropriate scam enablers.';
          }
        },
      },
    },
  },
  {
    label: 'Screenshot(s)',
    type: 'screenshot',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
      allowPaste: true,
    },
    alignment: 'column',
    optional: true,
  },
];

export const urlItemsMainTable: Array<FormItemInterface> = [
  {
    label: 'Website URL',
    type: 'tableInput',
    id: 'userId',
    alignment: 'column',
    extras: {
      tableData: urlItems,
    },
  },
];

export const rcsItems: Array<FormItemInterface> = [
  {
    label: 'User ID',
    type: 'telephoneInput',
    id: 'userId',
    alignment: 'column',
    placeholder: 'e.g. +6591234567',
    helperText: 'You may paste the full number with the country code into the field.',
    extras: {
      hideMessage: true,
      customClass: 'w-60 2xl:w-72',
    },
    rules: {
      required: "Scammer's Contact Number is required",
      pattern: {
        value: /^\+[0-9]+$/,
        message: 'Please enter a valid phone number. e.g. For Spain, +3512345678',
      },
    },
  },
  {
    label: 'URL',
    type: 'textInput',
    id: 'url',
    alignment: 'column',
    optional: true,
    extras: {},
    rules: {
      pattern: {},
    },
  },
  {
    label: 'Tags (Max. 3 each)',
    type: 'dropdownMulti',
    id: 'tags',
    placeholder: 'Type a tag and press Enter…',
    extras: { allowFreeSolo: true, dropdownData: [], isUpperCase: true, maxSelections: 3 },
    optional: true,
  },
  {
    label: 'Screenshot(s)',
    type: 'screenshot',
    id: 'attachments',
    optional: true,
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
      allowPaste: true,
    },
    alignment: 'column',
    // rules: {
    //   required: true,
    // },
  },
  {
    label: 'Profile Name',
    type: 'textInput',
    id: 'profileName',
    rules: {},
    optional: true,
  },
  {
    label: 'Brief Facts',
    type: 'textInput',
    id: 'explanation',
    rules: {},
    optional: true,
    extras: {
      rows: 4,
      condition: {
        field: 'scamType',
        setValue: (scamType: keyof typeof scamTypesBriefFactsMapping) => {
          return scamTypesBriefFactsMapping[scamType];
        },
      },
    },
  },
];

export const defaultItems: Array<FormItemInterface> = [
  {
    label: 'User ID',
    type: 'textInput',
    id: 'userId',
    alignment: 'column',
    rules: {
      pattern: {},
      required: 'Moniker User Id is required',
      validate: {
        isNotNil: (value: string) => {
          return validateIdNotNil(value);
        },
      },
    },
  },
  {
    label: 'URL',
    type: 'textInput',
    id: 'url',
    alignment: 'column',
    optional: true,
    extras: {},
    rules: {
      pattern: {},
    },
  },
  {
    label: 'Tags (Max. 3 each)',
    type: 'dropdownMulti',
    id: 'tags',
    placeholder: 'Type a tag and press Enter…',
    extras: { allowFreeSolo: true, dropdownData: [], isUpperCase: true, maxSelections: 3 },
    optional: true,
  },
  {
    label: 'Screenshot(s)',
    type: 'screenshot',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
      allowPaste: true,
    },
    alignment: 'column',
    optional: true,
  },
  {
    label: 'Profile Name',
    type: 'textInput',
    id: 'profileName',
    rules: {},
    optional: true,
  },
  {
    label: 'Brief Facts',
    type: 'textInput',
    id: 'explanation',
    rules: {},
    optional: true,
    extras: {
      rows: 4,
      condition: {
        field: 'scamType',
        setValue: (scamType: keyof typeof scamTypesBriefFactsMapping) => {
          return scamTypesBriefFactsMapping[scamType];
        },
      },
    },
  },
];

export const emailItems: Array<FormItemInterface> = [
  {
    label: 'Email Address',
    type: 'textInput',
    id: 'userId',
    alignment: 'column',
    rules: {
      required: 'Email address is required',
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Please enter a valid email address',
      },
    },
  },
  {
    label: 'Tags (Max. 3 each)',
    type: 'dropdownMulti',
    id: 'tags',
    placeholder: 'Type a tag and press Enter…',
    extras: { allowFreeSolo: true, dropdownData: [], isUpperCase: true, maxSelections: 3 },
    optional: true,
  },
  {
    label: 'Screenshot(s)',
    type: 'screenshot',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
      allowPaste: true,
    },
    alignment: 'column',
    optional: true,
  },
  {
    label: 'Brief Facts',
    type: 'textInput',
    id: 'explanation',
    rules: {},
    optional: true,
    extras: {
      rows: 4,
      condition: {
        field: 'scamType',
        setValue: (scamType: keyof typeof scamTypesBriefFactsMapping) => {
          return scamTypesBriefFactsMapping[scamType];
        },
      },
    },
  },
];

export const phoneNumberItems: Array<FormItemInterface> = [
  {
    label: 'User ID',
    type: 'telephoneInput',
    id: 'userId',
    alignment: 'column',
    placeholder: 'e.g. +6591234567',
    helperText: 'You may paste the full number with the country code into the field.',
    extras: {
      hideMessage: true,
      customClass: 'w-60 2xl:w-72',
    },
    rules: {
      required: 'Phone Number is required',
      pattern: {
        value: /^\+[0-9]+$/,
        message: 'Please enter a valid phone number. e.g. For Spain, +3512345678',
      },
    },
  },
  {
    label: 'URL',
    type: 'textInput',
    id: 'url',
    alignment: 'column',
    optional: true,
    extras: {},
    rules: {
      pattern: {},
    },
  },
  {
    label: 'Tags (Max. 3 each)',
    type: 'dropdownMulti',
    id: 'tags',
    placeholder: 'Type a tag and press Enter…',
    extras: { allowFreeSolo: true, dropdownData: [], isUpperCase: true, maxSelections: 3 },
    optional: true,
  },
  {
    label: 'Screenshot(s)',
    type: 'screenshot',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
      allowPaste: true,
    },
    alignment: 'column',
    optional: true,

    // rules: {
    //   required: true,
    // },
  },
  {
    label: 'Profile Name',
    type: 'textInput',
    id: 'profileName',
    rules: {},
    optional: true,
  },
  {
    label: 'Brief Facts',
    type: 'textInput',
    id: 'explanation',
    rules: {},
    optional: true,
    extras: {
      rows: 4,
      condition: {
        field: 'scamType',
        setValue: (scamType: keyof typeof scamTypesBriefFactsMapping) => {
          return scamTypesBriefFactsMapping[scamType];
        },
      },
    },
  },
];

const getCountryNames = () => {
  let countries = getCountries();
  let countryList: any = [];
  countries.forEach((country: any) => {
    countryList.push(getCountryNameAndFlag(country).name);
  });
  return countryList;
};

export const createReportPaynowFields = [
  {
    label: 'HP/NRIC/FIN/UEN',
    id: 'paynowNumber',
    type: 'textInput',
    alignment: 'column',
    rules: {
      required: 'HP/NRIC/FIN/UEN is required',
    },
  },
  {
    label: 'Transactions',
    id: 'transactions',
    type: 'dynamicTableModal',
    extras: {
      defaultRow: {
        dateOfTransfer: '',
        amountLoss: '',
        currency: 'SGD',
        transactionType: 'L-L',
      },
      tableFields: [
        {
          label: 'Date of Transfer',
          type: 'calendar',
          id: 'dateOfTransfer',
          extras: {
            customClass: 'w-[25%]',
            removePopupContainer: true,
          },
        },
        {
          label: 'Amount Scammed',
          type: 'textInputSelect',
          id: 'amountLoss',
          extras: {
            customClass: 'w-[25%]',
            idCurrency: 'currency',
          },
        },
        {
          label: 'Transaction Type',
          id: 'transactionType',
          type: 'dropdown',
          alignment: 'column',
          optional: true,
          extras: {
            customClass: 'w-[25%]',
            dropdownData: transactionTypes,
            // defaultValue: 'Local - Local',
          },
        },
      ],
    },
    optional: true,
    alignment: 'column',
  },
];

export const createReportBankFields = [
  {
    label: 'Name of Bank',
    id: 'platform',
    type: 'dropdown',
    alignment: 'column',
    rules: { required: 'Name of Bank is required' },
    extras: {
      dropdownData: bankPlatforms,
    },
  },
  {
    label: 'Account No.',
    id: 'accountNumber',
    type: 'textInput',
    alignment: 'column',
    rules: {
      required: 'Account No is required',
    },
  },
  {
    label: 'Account Type',
    id: 'accountType',
    type: 'dropdown',
    alignment: 'column',
    optional: true,
    extras: {
      dropdownData: accountTypes,
      // defaultValue: 'Personal',
    },
  },

  {
    label: 'Transactions',
    id: 'transactions',
    type: 'dynamicTableModal',
    extras: {
      defaultRow: {
        dateOfTransfer: '',
        amountLoss: '',
        currency: 'SGD',
        transactionType: 'L-L',
      },
      tableFields: [
        {
          label: 'Date of Transfer',
          type: 'calendar',
          id: 'dateOfTransfer',
          extras: {
            customClass: 'w-[25%]',
            removePopupContainer: true,
          },
          rules: { required: 'Date of Transfer is required' },
        },
        {
          label: 'Amount Scammed',
          type: 'textInputSelect',
          id: 'amountLoss',
          extras: {
            customClass: 'w-[25%]',
            idCurrency: 'currency',
          },
          rules: { required: 'Amount Scammed is required' },
        },
        {
          label: 'Transaction Type',
          id: 'transactionType',
          type: 'dropdown',
          alignment: 'column',
          optional: true,
          extras: {
            customClass: 'w-[25%]',
            dropdownData: transactionTypes,
            // defaultValue: 'Local - Local',
          },
        },
      ],
    },
    optional: true,
    alignment: 'column',
  },
  {
    label: 'Country',
    id: 'country',
    type: 'dropdown',
    alignment: 'column',
    extras: {
      dropdownData: getCountryNames(),
      condition: {
        field: '${tableId}platform',
        disabled: (value: any) => {
          return !value?.includes('Others');
        },
      },
    },
    optional: true,
  },
  {
    label: 'Additional Info for Email PO',
    id: 'additionalInformation',
    type: 'textInput',
    alignment: 'column',
    optional: true,
    extras: {
      rows: 3,
    },
  },
];

export const createScamEnablersDisplayFintechFields = [
  {
    label: 'Banks/Fintech/Crypto',
    id: 'platform',
    type: 'dropdown',
    alignment: 'column',
    extras: {
      dropdownData: [...fintechPlatforms, ...cryptoPlatforms],
    },
  },
  {
    label: 'Account No.',
    id: 'accountNumber',
    type: 'textInput',
    alignment: 'column',
    rules: {
      required: 'Account No is required',
    },
  },
  {
    label: 'Transactions',
    id: 'transactions',
    type: 'dynamicTableModal',
    extras: {
      defaultRow: {
        dateOfTransfer: '',
        amountLoss: '',
        currency: 'SGD',
        transactionType: 'L-L',
      },
      tableFields: [
        {
          label: 'Date of Transfer',
          type: 'calendar',
          id: 'dateOfTransfer',
          extras: {
            customClass: 'w-[25%]',
            removePopupContainer: true,
          },
          rules: { required: 'Date of Transfer is required' },
        },
        {
          label: 'Amount Scammed',
          type: 'textInputSelect',
          id: 'amountLoss',
          extras: {
            customClass: 'w-[25%]',
            idCurrency: 'currency',
          },
          rules: { required: 'Amount Scammed is required' },
        },
        {
          label: 'Transaction Type',
          id: 'transactionType',
          type: 'dropdown',
          alignment: 'column',
          optional: true,
          extras: {
            customClass: 'w-[25%]',
            dropdownData: transactionTypes,
            // defaultValue: 'Local - Local',
          },
        },
      ],
    },
    optional: true,
    alignment: 'column',
  },
];

export const createReportFintechFields = [
  {
    label: 'Fintech/Crypto',
    id: 'platform',
    type: 'dropdown',
    alignment: 'column',
    extras: {
      dropdownData: [...fintechPlatforms, ...cryptoPlatforms],
    },
  },
  {
    label: 'Account No.',
    id: 'accountNumber',
    type: 'textInput',
    alignment: 'column',
    rules: {
      required: 'Account No is required',
    },
  },
  {
    label: 'Transactions',
    id: 'transactions',
    type: 'dynamicTableModal',
    extras: {
      defaultRow: {
        dateOfTransfer: '',
        amountLoss: '',
        currency: 'SGD',
        transactionType: 'L-L',
      },
      tableFields: [
        {
          label: 'Date of Transfer',
          type: 'calendar',
          id: 'dateOfTransfer',
          extras: {
            customClass: 'w-[25%]',
            removePopupContainer: true,
          },
          rules: { required: 'Date of Transfer is required' },
        },
        {
          label: 'Amount Scammed',
          type: 'textInputSelect',
          id: 'amountLoss',
          extras: {
            customClass: 'w-[25%]',
            idCurrency: 'currency',
          },
          rules: { required: 'Amount Scammed is required' },
        },
        {
          label: 'Transaction Type',
          id: 'transactionType',
          type: 'dropdown',
          alignment: 'column',
          optional: true,
          extras: {
            customClass: 'w-[25%]',
            dropdownData: transactionTypes,
            // defaultValue: 'Local - Local',
          },
        },
      ],
    },
    optional: true,
    alignment: 'column',
  },
];

export const phoneNumberUserIdItems: Array<FormItemInterface> = [
  {
    label: 'User ID',
    id: 'userId',
    type: 'textInput',
    extras: {
      hideMessage: true,
      customClass: 'w-60 2xl:w-72',
    },
    rules: {},
  },
  {
    label: 'URL',
    type: 'textInput',
    id: 'url',
    alignment: 'column',
    optional: true,
    extras: {},
    rules: {
      pattern: {},
    },
  },
  // {
  //   label: "User ID",
  //   type: 'textInput',
  //   id: 'userId',
  //   alignment: 'column',
  //   rules: {
  //     required: "User ID/ Scammer's Contact Number is required",
  //     validate: {
  //       isNotNil: (value: string) => {
  //         return validateIdNotNil(value);
  //       },
  //     },
  //   },
  // },
  {
    label: 'Tags (Max. 3 each)',
    type: 'dropdownMulti',
    id: 'tags',
    placeholder: 'Type a tag and press Enter…',
    extras: { allowFreeSolo: true, dropdownData: [], isUpperCase: true, maxSelections: 3 },
    optional: true,
  },
  {
    label: 'Screenshot(s)',
    type: 'screenshot',
    id: 'attachments',
    extras: {
      fileType: getFileMimeTypes(['image', 'pdf', 'word']),
      maxFiles: 3,
      allowPaste: true,
    },
    alignment: 'column',
    optional: true,
  },
  {
    label: 'Profile Name',
    type: 'textInput',
    id: 'profileName',
    rules: {},
    optional: true,
  },
  {
    label: 'Brief Facts',
    type: 'textInput',
    id: 'explanation',
    rules: {},
    optional: true,
    extras: {
      rows: 4,
      condition: {
        field: 'scamType',
        setValue: (scamType: keyof typeof scamTypesBriefFactsMapping) => {
          return scamTypesBriefFactsMapping[scamType];
        },
      },
    },
  },
];

export const createFormScamEnablersGeneralItems: Array<FormItemInterface> = [
  {
    label: 'Use of Scam Enabler',
    type: 'dropdown',
    id: 'useOfScamEnabler',
    alignment: 'column',
    extras: {
      dropdownData: useOfScamEnabler,
    },
  },
  {
    label: 'Platform',
    type: 'dropdown',
    id: 'platform',
    alignment: 'column',
    extras: {
      dropdownData: platform,
    },
  },
];

export const createFormGeneralItems: Array<FormItemInterface> = [
  {
    label: 'Police Station/Unit',
    type: 'dropdown',
    id: 'policeStation',
    placeholder: 'Please Select',
    extras: { dropdownData: policeStations },
    alignment: 'column',
    rules: {
      required: 'Please select an option',
    },
  },
  {
    label: 'SPF Email Address',
    type: 'textInput',
    id: 'spfEmail',
    placeholder: 'e.g user@spf.gov.sg',
    alignment: 'column',
    rules: {
      required: 'Please enter a valid SPF email address',
      pattern: {
        value: /^[a-zA-Z0-9._%+-]+@(spf|gt\.tech|tech)\.gov\.sg$/i,
        message: 'A valid SPF email address is required eg. user@spf.gov.sg.',
      },
    },
  },
  {
    label: 'Report Number',
    type: 'textInput',
    id: 'reportNumber',
    placeholder: 'e.g M/20231225/1234',
    alignment: 'column',
    rules: {
      required: 'Please enter a valid report number',
      pattern: {
        value: /^(?=.{13,15}$)([A-Z]{1,3}\/\d{4,8}\/\d+|ASC\/\d{4}\/\d{2}\/\d{2})$/,
        message:
          'Invalid report number. Please provide report number in the correct format e.g. M/20231225/1234 or ASC/2024/10/16.',
      },
    },
    extras: {
      keyup: (value: string) => {
        return value.toUpperCase();
      },
    },
  },
  {
    label: 'Classification',
    type: 'dropdown',
    id: 'classification',
    placeholder: 'Please Select',
    extras: { dropdownData: classifications },
    alignment: 'column',
    rules: {
      required: 'Please select an option',
    },
  },
  {
    label: 'Scam Type',
    type: 'dropdown',
    id: 'scamType',
    placeholder: 'Please Select',
    extras: { dropdownData: scamType },
    alignment: 'column',
    rules: {
      required: 'Please select an option',
    },
  },
  {
    label: 'Scam Sub-Type',
    type: 'textInput',
    id: 'scamSubType',
    optional: true,
    alignment: 'column',
  },
];

export const viewFormGeneralItems: Array<FormItemInterface> = [
  {
    label: 'Police Station/Unit',
    type: 'label',
    id: 'policeStation',
  },
  {
    label: 'SPF Email Address',
    type: 'label',
    id: 'spfEmail',
  },
  {
    label: 'Report Number',
    type: 'label',
    id: 'reportNumber',
  },
  {
    label: 'Scam Type',
    type: 'label',
    id: 'scamType',
  },
  {
    label: 'Sub Scam Type',
    type: 'label',
    id: 'scamSubType',
  },
];

export const createTransactionItems: Array<FormItemInterface> = [
  {
    label: 'Date of Transfer',
    type: 'calendar',
    id: 'dateOfTransfer',
    placeholder: 'dd/mm/yyyy',
    alignment: 'column',
    rules: {
      required: 'Date of transfer is required',
    },
  },
  {
    label: 'Medium of Transfer',
    type: 'dropdown',
    id: 'platform',
    alignment: 'column',
    placeholder: 'Please Select',
    extras: { dropdownData: financialExchangePlatform },
    rules: {
      required: 'Please select an option',
    },
  },
  {
    label: 'Account No/Transaction Reference',
    type: 'textInput',
    id: 'transactionReference',
    alignment: 'column',
    rules: {
      required: 'Please input',
    },
  },
  {
    label: 'Amount of Losses',
    type: 'textInput',
    id: 'amount',
    placeholder: 'e.g 1000',
    alignment: 'column',
    rules: {
      required: 'Please enter a number',
      pattern: {
        value: /^[0-9]+$/,
        message: 'Please enter a number',
      },
    },
  },
];

export const viewFormGeneraMonikerItems: Array<FormItemInterface> = [
  {
    label: 'Use of Scam Enabler',
    type: 'label',
    id: 'useOfScamEnabler',
  },
  {
    label: 'Platform',
    type: 'label',
    id: 'platform',
  },
];

export const getFormItems = (platform: string, selectedCategory: string) => {
  let tempItems: any = [];
  if (selectedCategory === 'Monikers') {
    tempItems = cloneDeep(defaultItems);
    switch (platform) {
      case 'Facebook':
        tempItems = cloneDeep(faceBookItems);
        break;
      case 'Instagram':
        tempItems = cloneDeep(instagramItems);
        break;
      case 'Telegram':
        tempItems = cloneDeep(telegramItems);
        break;
      case 'X':
        tempItems = cloneDeep(twitterItems);
        break;
      case 'Carousell':
        tempItems = cloneDeep(carousellItems);
        break;
      case 'Lazada':
        tempItems = cloneDeep(LazadaItems);
        break;
      case 'Shopee':
        tempItems = cloneDeep(ShopeeItems);
        break;
      case 'Whatsapp':
        tempItems = cloneDeep(whatsappItems);
        break;
      case 'Email':
        tempItems = cloneDeep(emailItems);
        break;
      case 'LINE App':
      case 'WeChat':
        tempItems = cloneDeep(phoneNumberUserIdItems);
        break;
      // case 'On a call':
      case 'SMS':
        tempItems = cloneDeep(phoneNumberItems);
        break;
      case 'RCS':
        tempItems = cloneDeep(rcsItems);
        break;
      case 'iMessage':
        tempItems = cloneDeep(iMessageItems);
        break;
      case 'Google Playstore Application':
        tempItems = cloneDeep(googlePlaystoreApplicationItems);
        break;
      case '':
        tempItems = [];
        break;
      default:
        break;
    }
  } else if (selectedCategory === 'Banks') {
    tempItems = cloneDeep(createReportBankFields);
  } else if (selectedCategory === 'Fintech') {
    tempItems = cloneDeep(createReportFintechFields);
  } else if (selectedCategory === 'Paynow') {
    tempItems = cloneDeep(createReportPaynowFields);
  }

  tempItems.forEach((item: any) => {
    if (item.rules && !item.rules.validate) {
      item.rules.validate = null;
    }
  });
  return tempItems;
};
