import { useDispatch } from 'react-redux';
import { apiSlice } from './apiSlice';
import { showAlert } from '@store/alertSlice';

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    listUsers: builder.query({
      query: (request) => {
        return {
          method: 'GET',
          url: `/v1/users`,
          params: request.params,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    createUser: builder.mutation({
      query: (params) => {
        return {
          method: 'POST',
          url: `/v1/users`,
          body: params,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    changePassword: builder.mutation({
      query: (params) => {
        return {
          method: 'PUT',
          url: `/v1/changepassword/${params.pathParameters.user_id}`,
          body: params.body,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    setupTotp: builder.mutation({
      query: (params) => {
        return {
          method: 'POST',
          url: `/v1/setuptotp`,
          body: params,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    getUser: builder.query({
      query: (params) => {
        return {
          method: 'GET',
          url: `/v1/users/${params.pathParameters.user_id}`,
        };
      },
      transformResponse: (response: any) => {
        return response.user;
      },
    }),
    getAssignUsers: builder.query({
      query: (request) => {
        return {
          method: 'GET',
          url: `/v1/users/assign`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    getUsersByPermissions: builder.query({
      query: (request) => {
        return {
          method: 'GET',
          url: `/v1/users/getbypermissions`,
          params: request.params,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    getRoles: builder.query({
      query: (params) => {
        return {
          method: 'GET',
          url: `/v1/roles`,
        };
      },
      transformResponse: (response: any) => {
        return response.roles;
      },
    }),
    updateUser: builder.mutation({
      query: (params) => {
        return {
          method: 'PUT',
          url: `/v1/users/${params.pathParameters.user_id}`,
          body: params.body,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    adminUpdateUser: builder.mutation({
      query: (params) => {
        return {
          method: 'PUT',
          url: `/v1/admin/users/${params.pathParameters.user_id}`,
          body: params.body,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    adminResetPassword: builder.mutation({
      query: (params) => {
        return {
          method: 'PUT',
          url: `/v1/admin/users/reset-password/${params.pathParameters.user_id}`,
          body: params.body,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    adminResetTotp: builder.mutation({
      query: (params) => {
        return {
          method: 'PUT',
          url: `/v1/admin/users/reset-totp/${params.pathParameters.user_id}`,
          body: params.body,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
  }),
});

export const useAdminResetPassword = () => {
  const [adminResetPassword] = useAdminResetPasswordMutation();
  const dispatch = useDispatch();

  const resetPassword = async (params: any) => {
    let response: any = await adminResetPassword(params);
    console.log('response', response.error);
    if (!response.error) {
      dispatch(
        showAlert({
          type: 'success',
          message: 'Reset password email sent!',
        }),
      );
    }
    return response;
  };

  return resetPassword;
};

export const {
  useListUsersQuery,
  useChangePasswordMutation,
  useCreateUserMutation,
  useSetupTotpMutation,
  useGetUserQuery,
  useGetUsersByPermissionsQuery,
  useGetRolesQuery,
  useUpdateUserMutation,
  useAdminUpdateUserMutation,
  useAdminResetPasswordMutation,
  useAdminResetTotpMutation
} = userApiSlice;
