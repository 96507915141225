import { Link } from 'react-router-dom';
import {
  PaynowStatus,
  BankStatus,
  MonikerOutcomeStatus,
  ProactiveMonikerOutcomeStatus,
} from '@components/common/StatusComponent';
import { convertUnixToSingaporeTime, getFullUrl } from '@utils/utils';
import { ViewProactiveMonikerButton } from './proactiveMonikers/Buttons';
import { Chip, Tooltip } from '@mui/material';
import { ReactComponent as Verified } from '@assets/icons/verified.svg';

export const getBankColumns = (refetch: any, handleExpandRow: any, expandedRows: any) => [
  {
    title: 'Bank Account No.',
    dataIndex: '_id',
    key: '_id',
    sorter: true,
    width: '20%',
    render: (text: string, record: any, index: number) => {
      return (
        <Link
          id="Bank Account No"
          to={`/admin/portfolios/bank/${record._id}`}
          className="underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          {record.accountNumber}
        </Link>
      );
    },
  },
  {
    title: 'Name of Bank',
    dataIndex: 'platform',
    sorter: true,
    key: 'platform',
    width: '10%',
  },
  {
    title: 'No. of Reports',
    dataIndex: 'numReports',
    sorter: false,
    key: 'numReports',
    width: '10%',
    render: (text: string, record: any, index: number) => record.reports.length,
  },
  {
    title: 'Date Created',
    dataIndex: 'createdAt',
    sorter: true,
    width: '10%',
    render: (text: string, record: any, index: number) => {
      return record.createdAt ? convertUnixToSingaporeTime(record.createdAt) : '-';
    },
  },
  {
    title: 'Date of Transfer (Latest)',
    dataIndex: 'dateOfTransfer',
    sorter: false,
    width: '10%',
    render: (text: string, record: any, index: number) => {
      let latestDateUnix = 0;
      if (record?.reports?.length > 0) {
        record.reports.forEach((record: any) => {
          if (record.dateOfTransfer && record.dateOfTransfer > latestDateUnix) {
            latestDateUnix = record.dateOfTransfer;
          }
        });
      }

      return latestDateUnix ? convertUnixToSingaporeTime(latestDateUnix) : '-';
    },
  },
  {
    title: 'Total Amount Scammed (SGD)',
    dataIndex: 'totalScammed',
    sorter: false,
    key: 'totalScammed',
    width: '15%',
    render: (text: string, record: any, index: number) => {
      let total = 0;
      record.reports.forEach((report: any) => {
        total += report.totalAmount;
      });

      return `$${total.toFixed(2)}`;
    },
  },
  {
    title: 'DO Assigned',
    dataIndex: 'dutyOfficer',
    sorter: true,
    key: 'dutyOfficer',
    width: '15%',
    render: (text: string, record: any, index: number) =>
      `${record?.dutyOfficer?.firstName || ''} ${record?.dutyOfficer?.lastName || '-'}`,
  },
  {
    title: 'Account Status',
    dataIndex: 'outcome',
    sorter: true,
    key: 'outcome',
    width: '15%',
    render: (text: string, record: any, index: number) => <BankStatus outcome={record.outcome} />,
  },
  // {
  //   title: '',
  //   key: 'title',
  //   width: '5%',
  //   render: (text: string, record: any, index: number) => (
  //     <AssignSingleButton selected={record} callback={refetch} />
  //   ),
  // },
  // {
  //   title: '',
  //   key: 'title',
  //   width: '5%',
  //   render: (text: string, record: any, index: number) => (
  //     <UpdateOutcomeButton selectedBanks={[record]} callback={refetch} type="icon" />
  //   ),
  // },
];

export const getPaynowColumns = (refetch: any, handleExpandRow: any, expandedRows: any) => [
  {
    title: 'HP/NRIC/FIN/UEN.',
    dataIndex: '_id',
    key: '_id',
    sorter: true,
    width: '20%',
    render: (text: string, record: any, index: number) => {
      return (
        <Link
          to={`/admin/portfolios/paynow/${record._id}`}
          id="HP/NRIC/FIN/UEN"
          className="underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          {record.paynowNumber}
        </Link>
      );
    },
  },
  {
    title: 'No. of Reports',
    dataIndex: 'numReports',
    sorter: false,
    key: 'numReports',
    width: '10%',
    render: (text: string, record: any, index: number) => record.reports?.length,
  },
  {
    title: 'Name of Bank',
    dataIndex: 'bank',
    sorter: true,
    width: '15  %',
    render: (text: string, record: any, index: number) => {
      let bank = null;
      if (record?.history?.length > 0) {
        record.history.forEach((record: any) => {
          if (!record.deactivationDate) {
            bank = record.bank;
          }
        });
      }

      return bank != null ? bank : '-';
    },
  },
  {
    title: 'Bank Account No.',
    dataIndex: 'bankAccountNumber',
    sorter: true,
    width: '15%',
    render: (text: string, record: any, index: number) => {
      let bankAccountNumber = null;
      if (record?.history?.length > 0) {
        record.history.forEach((record: any) => {
          if (!record.deactivationDate) {
            bankAccountNumber = record.bankAccountNumber;
          }
        });
      }

      return bankAccountNumber != null ? bankAccountNumber : '-';
    },
  },
  {
    title: 'Bank Display Name',
    dataIndex: 'displayName',
    sorter: true,
    width: '15%',
  },
  {
    title: 'Date of Request (Latest)',
    dataIndex: 'dateOfRequest',
    sorter: false,
    width: '15%',
    render: (text: string, record: any, index: number) => {
      let latestDateUnix = 0;
      if (record?.history?.length > 0) {
        record.history.forEach((record: any) => {
          if (record.screeningAt && record.screeningAt > latestDateUnix) {
            latestDateUnix = record.screeningAt;
          }
        });
      }

      return latestDateUnix ? convertUnixToSingaporeTime(latestDateUnix) : '-';
    },
  },
  {
    title: 'PayNow Account Status',
    dataIndex: 'outcome',
    sorter: true,
    key: 'outcome',
    width: '15%',
    render: (text: string, record: any, index: number) => <PaynowStatus outcome={record.outcome} />,
  },
];

const renderTags = (tags: string[]) => {
  return tags.map((tag: string, index: number) => (
    <Chip
      key={index}
      label={tag}
      style={{
        backgroundColor: 'var(--blue-200)',
        color: 'var(--grey-500)',
        fontWeight: '700',
        fontSize: '12px',
      }}
    />
  ));
};

export const getMonikerColumns = (refetch: any) => [
  {
    title: 'Moniker ID',
    dataIndex: '_id',
    key: '_id',
    sorter: true,
    width: '14%',
    render: (text: string, record: any, index: number) => (
      <Link
        to={`/admin/portfolios/monikers/${record._id}`}
        id="Moniker ID"
        className="underline"
        target="_blank"
        rel="noopener noreferrer"
      >
        {record.scamEnablerUserId?.length > 20
          ? record.scamEnablerUserId.substring(0, 20) + '...'
          : record.scamEnablerUserId || '-'}
      </Link>
    ),
  },
  {
    title: 'url',
    dataIndex: 'url',
    sorter: true,
    key: 'url',
    width: '10%',
    render: (text: string, record: any, index: number) => (
      <>
        {record.url && (
          <Tooltip
            title={record.url}
            componentsProps={{
              tooltip: {
                sx: {
                  fontSize: '1rem',
                },
              },
            }}
          >
            <a
              href={getFullUrl(record.url)}
              id="URL"
              className="underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              {record.url}
            </a>
          </Tooltip>
        )}
      </>
    ),
  },
  {
    title: 'Platform',
    dataIndex: 'platform',
    sorter: true,
    key: 'platform',
    width: '10%',
  },
  {
    title: 'Scam Type',
    dataIndex: 'scamType',
    sorter: true,
    key: 'scamType',
    width: '10%',
  },
  {
    title: 'Date Received',
    dataIndex: 'createdAt',
    sorter: true,
    key: 'createdAt',
    width: '10%',
    render: (text: string, record: any, index: number) =>
      convertUnixToSingaporeTime(record.createdAt),
  },
  {
    title: 'PO Sent Date',
    dataIndex: 'productionOrderSentDate',
    sorter: true,
    key: 'productionOrderSentDate',
    width: '12%',
    render: (text: string, record: any, index: number) =>
      convertUnixToSingaporeTime(
        record.productionOrderSentDate ?? record.generateProductionOrderAt,
      ),
  },
  {
    title: 'PO Reference Number (Latest)',
    dataIndex: 'productionOrders',
    sorter: false,
    key: 'productionOrders',
    width: '15%',
    render: (text: string, record: any, index: number) => {
      if (!record.productionOrders || record.productionOrders.length === 0) return <p></p>;
      else {
        return (
          <Tooltip
            title={record.productionOrders
              .sort((a: any, b: any) => {
                return b.createdAt - a.createdAt;
              })
              .map((order: any) => order.directionReferenceNo || order.productionOrderId)
              .join(',\n')}
            componentsProps={{
              tooltip: {
                sx: {
                  fontSize: '14px',
                  whiteSpace: 'pre-line',
                  maxWidth: 'none',
                },
              },
            }}
          >
            <div>
              {record.productionOrders
                .sort((a: any, b: any) => {
                  // Assuming createdAt is a Date object
                  return b.createdAt - a.createdAt;
                  // If createdAt is a timestamp (number), the above line works directly.
                })
                .slice(0, 1)
                .map((order: any, index: number) => (
                  <div key={index}>
                    <span>{order.directionReferenceNo || order.productionOrderId}</span>
                  </div>
                ))}
              {record.productionOrders.length > 1 && '...'}
            </div>
          </Tooltip>
        );
      }
    },
  },
  {
    title: 'Platform Reference Number',
    dataIndex: 'productionOrderList',
    sorter: false,
    key: 'productionOrderList',
    width: '10%',
    render: (text: string, record: any, index: number) => {
      if (!record.productionOrderList || record.productionOrderList.length === 0) return <p></p>;
      else {
        return (
          <div>
            {record.productionOrderList
              .sort((a: any, b: any) => {
                // Assuming createdAt is a Date object
                return b.createdAt - a.createdAt;
                // If createdAt is a timestamp (number), the above line works directly.
              })
              .slice(0, 1)
              .map((order: any, index: number) => (
                <div key={index}>
                  <span>{order.platformReferenceNo}</span>
                </div>
              ))}
          </div>
        );
      }
    },
  },
  {
    title: 'DA 1 Assigned',
    dataIndex: 'da1',
    key: 'da1',
    width: '12%',
    render: (text: string, record: any, index: number) => {
      if (!record.assignment || record.assignment.length === 0) return <p></p>;
      else {
        const assignment = record.assignment.find((assign: any) => assign.role === 'da1');
        return <p>{assignment ? assignment.userName : ''}</p>;
      }
    },
  },
  {
    title: 'DA 2 Assigned',
    dataIndex: 'da2',
    width: '12%',
    key: 'da2 Assigned',
    render: (text: string, record: any, index: number) => {
      if (!record.assignment || record.assignment.length === 0) return <p></p>;
      else {
        const assignment = record.assignment.find((assign: any) => assign.role === 'da2');
        return <p>{assignment ? assignment.userName : ''}</p>;
      }
    },
  },
  {
    title: 'Moniker Status',
    dataIndex: 'outcome',
    sorter: true,
    width: '13%',
    key: 'outcome',
    render: (text: string, record: any, index: number) => <MonikerOutcomeStatus outcome={text} />,
  },
  // {
  //   key: "download",
  //   width: "4%",
  //   render: (text: string, record: any, index: number) => (
  //     <IconButton onClick={() => {}} icon="download" isDisabled={false} />
  //   ),
  // },
  // {
  //   key: 'assign',
  //   width: '4%',
  //   render: (text: string, record: any, index: number) => (
  //     <AssignMeSingleButton
  //       scamEnabler={record}
  //       callback={refetch}
  //       showSelectedScamEnablers={true}
  //     />
  //   ),
  // },
  // {
  //   key: 'edit',
  //   width: '4%',
  //   render: (text: string, record: any, index: number) => (
  //     <UpdateSingleButton scamEnabler={record} callback={refetch} showSelectedScamEnablers={true} />
  //   ),
  // },
];

export const getProactiveColumns = (refetch: any) => [
  {
    title: 'Moniker Profile Name',
    dataIndex: 'profilename',
    key: 'profilename',
    sorter: true,
    width: '10%',
    render: (text: string, record: any, index: number) => (
      <div className="overflow-hidden items-center justify-start">
        <ViewProactiveMonikerButton record={record} refetch={refetch} />
      </div>
    ),
  },
  {
    title: 'Moniker ID',
    dataIndex: 'scamEnablerUserId',
    sorter: true,
    key: 'scamEnablerUserId',
    width: '12%',
    render: (text: string, record: any, index: number) => (
      <div className="flex flex-row gap-1 items-center">
        <Tooltip
          title={record.url}
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: '1rem',
              },
            },
          }}
        >
          <Link
            to={`${record.url}`}
            id="Moniker ID"
            className="underline truncate w-40 "
            target="_blank"
            rel="noopener noreferrer"
          >
            {record.scamEnablerUserId}
          </Link>
        </Tooltip>

        {record.verifiedProfile === true && <Verified className="min-w-[20px]" />}

        {record.reported === true && (
          <div className="bg-blue-200 text-grey-500 rounded-md w-[16px] h-[16px] text-xs p-2 flex items-center justify-center">
            R
          </div>
        )}
      </div>
    ),
  },
  {
    title: 'Platform',
    dataIndex: 'platform',
    sorter: true,
    key: 'platform',
    width: '8%',
  },
  {
    title: 'Scam Type',
    dataIndex: 'scamType',
    sorter: true,
    key: 'scamType',
    width: '8%',
  },
  {
    title: 'Reference Number',
    dataIndex: 'referenceNo',
    sorter: true,
    key: 'referenceNo',
    width: '8%',
  },
  {
    title: "Scammer's Contact Number",
    dataIndex: 'scammerContactNumber',
    sorter: true,
    key: 'scammerContactNumber',
    width: '10%',
    render: (text: string, record: any, index: number) => {
      if (Array.isArray(record?.scammerContactNumber)) {
        return record?.scammerContactNumber?.join(',');
      } else {
        return text;
      }
    },
  },
  {
    title: 'Date of Creation',
    dataIndex: 'createdAt',
    sorter: true,
    key: 'createdAt',
    width: '10%',
    render: (text: string, record: any, index: number) =>
      convertUnixToSingaporeTime(record.createdAt),
  },
  {
    title: 'Officer IC',
    sorter: true,
    width: '8%',
    render: (text: string, record: any, index: number) => {
      if (!record.assignment || record.assignment.length === 0) return <p></p>;
      else {
        const assignment = record.assignment[0];
        return <p>{assignment ? assignment.userName : ''}</p>;
      }
    },
  },
  {
    title: 'Tags',
    dataIndex: 'tags',
    key: 'tags',
    width: '12%',
    render: (text: string, record: any, index: number) => {
      if (record?.tags?.length > 0) {
        return <div className="flex flex-row flex-wrap gap-1">{renderTags(record.tags)}</div>;
      } else {
        return <p></p>;
      }
    },
  },
  {
    title: 'Moniker Status',
    dataIndex: 'outcome',
    sorter: true,
    width: '10%',
    key: 'outcome',
    render: (text: string, record: any, index: number) => (
      <ProactiveMonikerOutcomeStatus outcome={text} />
    ),
  },
  // {
  //   key: "download",
  //   width: "4%",
  //   render: (text: string, record: any, index: number) => (
  //     <IconButton onClick={() => {}} icon="download" isDisabled={false} />
  //   ),
  // },
  // {
  //   key: 'action',
  //   width: '4%',
  //   render: (text: string, record: any, index: number) => (
  //     <>
  //       {/* <AssignProactiveSingleButton
  //         scamEnabler={record}
  //         callback={refetch}
  //         showSelectedScamEnablers={true}
  //       /> */}
  //       <ActivityLogsButton proactiveScamEnablerId={record._id} />
  //     </>
  //   ),
  // },
];
