import { IconButton } from '@components/common/CommonButton';
import DynamicForm from '@components/common/DynamicForm';
import {
  LazadaItems,
  ShopeeItems,
  carousellItems,
  createReportBankFields,
  createReportFintechFields,
  createReportPaynowFields,
  defaultItems,
  emailItems,
  faceBookItems,
  googlePlaystoreApplicationItems,
  iMessageItems,
  instagramItems,
  phoneNumberItems,
  phoneNumberUserIdItems,
  rcsItems,
  telegramItems,
  twitterItems,
  viewFormGeneralItems,
  whatsappItems,
} from './AdminCreateReportFormConfig';
import cloneDeep from 'lodash.clonedeep';
import DynamicTable from '@components/common/DynamicTable';
import { filterEmptyRows } from '@utils/utils';
import { useEffect } from 'react';

const AdminCreateReportConfirmation = ({
  formMethods,
  formValues,
  setActiveStep,
}: {
  formMethods: any;
  formValues: any;
  setActiveStep: (index: number) => void;
}) => {
  const getFormItems = (platform: string, index: number, selectedCategory: string) => {
    let tempItems: any = [];
    if (selectedCategory === 'Monikers') {
      tempItems = cloneDeep(defaultItems);
      switch (platform) {
        case 'Facebook':
          tempItems = cloneDeep(faceBookItems);
          break;
        case 'Instagram':
          tempItems = cloneDeep(instagramItems);
          break;
        case 'Telegram':
          tempItems = cloneDeep(telegramItems);
          break;
        case 'X':
          tempItems = cloneDeep(twitterItems);
          break;
        case 'Carousell':
          tempItems = cloneDeep(carousellItems);
          break;
        case 'Lazada':
          tempItems = cloneDeep(LazadaItems);
          break;
        case 'Shopee':
          tempItems = cloneDeep(ShopeeItems);
          break;
        case 'Whatsapp':
          tempItems = cloneDeep(whatsappItems);
          break;
        case 'Email':
          tempItems = cloneDeep(emailItems);
          break;
        case 'LINE App':
        case 'WeChat':
          tempItems = cloneDeep(phoneNumberUserIdItems);
          break;
        // case 'On a call':
        case 'SMS':
          tempItems = cloneDeep(phoneNumberItems);
          break;
        case 'RCS':
          tempItems = cloneDeep(rcsItems);
          break;
        case 'iMessage':
          tempItems = cloneDeep(iMessageItems);
          break;
        case 'Google Playstore Application':
          tempItems = cloneDeep(googlePlaystoreApplicationItems);
          break;
        case '':
          tempItems = [];
          break;
        default:
          break;
      }
    } else if (selectedCategory === 'Banks') {
      tempItems = cloneDeep(createReportBankFields);
    } else if (selectedCategory === 'Fintech') {
      tempItems = cloneDeep(createReportFintechFields);
    } else if (selectedCategory === 'Paynow') {
      tempItems = cloneDeep(createReportPaynowFields);
    }

    tempItems.forEach((item: any) => {
      // formMethods.setValue(`scamEnablers[${index}].${item.id}`, '');
      // item.id = `scamEnablers[${index}].${item.id}`;
      if (item.helperText) delete item.helperText;
      if (item.rules && !item.rules.validate) {
        item.rules.validate = null;
      }
      if (item.type === `screenshot`) {
        item.type = 'controlledFileLabel';
        item.alignment = `row`;
      } else if (item.type === `dynamicTableModal`) {
        item.alignment = `column`;
        if (item?.extras?.tableFields?.length > 0) {
          item.extras.tableFields.map((field: any) => {
            field.type = `controlledLabel`;
          });
        }
      } else {
        item.type = `controlledLabel`;
      }
      if (item.extras) {
        item.extras.customClass = 'w-10';
      } else {
        item.extras = {
          customClass: 'w-10',
        };
      }
    });
    return tempItems;
  };

  const getPlatformPath = (index: number, selectedCategory: string) => {
    return `scamEnablers[${selectedCategory}][${index}].platform`;
  };
  useEffect(() => {console.log("formValues",formValues);}, [formValues]); 

  const getScamEnablersComponent = () => {
    let bankRecords = filterEmptyRows(formValues?.scamEnablers?.Banks);
    let paynowRecords = filterEmptyRows(formValues?.scamEnablers?.Paynow);
    let fintechRecords = filterEmptyRows(formValues?.scamEnablers?.Fintech);
    let monikerRecords = filterEmptyRows(formValues?.scamEnablers?.Monikers);
console.log("formMethodsx",formMethods);
    console.log("formValuesx",formValues.scamEnabler);
    return (
      <div className="flex flex-col gap-2">
        {bankRecords?.length > 0 &&
          bankRecords.map((bank: any, index: number) => {
            return (
              <div className="bg-white p-4 rounded-lg">
                <p className="text-blue-400 font-bold px-2 py-1">Banks</p>

                <DynamicTable
                  tableId={`scamEnablers[Banks][${index}].rows`}
                  formFields={getFormItems(getPlatformForIndex(index, 'Banks'), index, 'Banks')}
                  isEditable={false}
                  formMethods={formMethods}
                  data={bank.rows}
                  defaultRow={{}}
                  showCounter={false}
                  isSimpleView={true}
                />
              </div>
            );
          })}
        {paynowRecords.length > 0 &&
          paynowRecords.map((paynow: any, index: number) => {
            return (
              <div className="bg-white p-4 rounded-lg">
                <p className="text-blue-400 font-bold px-2 py-1">Paynow</p>

                <DynamicTable
                  tableId={`scamEnablers[Paynow][${index}].rows`}
                  formFields={getFormItems(getPlatformForIndex(index, 'Paynow'), index, 'Paynow')}
                  isEditable={false}
                  formMethods={formMethods}
                  data={paynow.rows}
                  defaultRow={{}}
                  showCounter={false}
                  isSimpleView={true}
                />
              </div>
            );
          })}
        {fintechRecords.length > 0 &&
          fintechRecords.map((fintech: any, index: number) => {
            return (
              <div className="bg-white p-4 rounded-lg">
                <p className="text-blue-400 font-bold px-2 py-1">Fintech/Crypto</p>

                <DynamicTable
                  tableId={`scamEnablers[Fintech][${index}].rows`}
                  formFields={getFormItems(getPlatformForIndex(index, 'Fintech'), index, 'Fintech')}
                  isEditable={false}
                  formMethods={formMethods}
                  data={fintech.rows}
                  defaultRow={{}}
                  showCounter={false}
                  isSimpleView={true}
                />
              </div>
            );
          })}
        {monikerRecords.length > 0 &&
          monikerRecords.map((scamEnabler: any, index: number) => {
            console.log("scamEnabler rows",scamEnabler.rows);
            console.log("monikerRecords",monikerRecords);
            return (
              <div className="bg-white p-4 rounded-lg">
                <p className="text-blue-400 font-bold px-2 py-1">Monikers</p>

                <div className="px-2 py-1 flex flex-row gap-2 items-center">
                  <p className="font-bold text-grey-400">Platform : </p>
                  <p>{scamEnabler.platform}</p>
                </div>
                <DynamicTable
                  tableId={`scamEnablers[Monikers][${index}].rows`}
                  formFields={getFormItems(
                    getPlatformForIndex(index, 'Monikers'),
                    index,
                    'Monikers',
                  )}
                  isEditable={false}
                  formMethods={formMethods}
                  data={scamEnabler.rows}
                  defaultRow={{}}
                  showCounter={false}
                  isSimpleView={true}
                />
              </div>
            );
          })}
      </div>
    );
  };

  const getPlatformForIndex = (index: number, selectedCategory: string) => {
    return formMethods.getValues(getPlatformPath(index, selectedCategory));
  };

  return (
    <div className="flex flex-col gap-1 h-[45vh] 2xl:h-[52vh] overflow-y-auto overflow-x-hidden">
      <p className="font-bold  text-lg">Confirmation</p>
      <div className="flex flex-row gap-2 items-center">
        <p className="py-2 text-[16px] font-bold">General Information</p>
        <IconButton
          isDisabled={false}
          icon="edit"
          onClick={() => setActiveStep(0)}
          isLoading={false}
          type="button"
        />
      </div>
      <div className="bg-white p-4 rounded-lg">
        <DynamicForm
          formFields={viewFormGeneralItems.map((field) => ({
            ...field,
            className: 'py-1',
          }))}
          data={formValues}
        />
      </div>
      <div className="flex flex-row gap-2 items-center">
        <p className="py-2 text-[16px] font-bold">Scam Enablers</p>
        <IconButton
          isDisabled={false}
          icon="edit"
          onClick={() => setActiveStep(1)}
          isLoading={false}
          size="16"
          type="button"
        />
      </div>
      {getScamEnablersComponent()}
    </div>
  );
};

export default AdminCreateReportConfirmation;
