import { apiSlice } from './apiSlice';

interface Config {
  [key: string]: any;
}

export const configApiSlice = apiSlice.enhanceEndpoints({
  addTagTypes: ['Config']
}).injectEndpoints({
  endpoints: (builder) => ({
    getConfig: builder.query<Config, string>({
      query: (id) => ({
        url: `v2/config/${id}`,
        method: 'GET',
      }),
      // Keep unused data in cache for 300 seconds
      keepUnusedDataFor: 300,
      // Add tags for cache invalidation
      providesTags: (result, error, id) => [{ type: 'Config' as const, id }],
      // Transform response to ensure consistent data structure
      transformResponse: (response: Config) => {
        return response;
      },
      // Merge incoming data with existing cache
      merge: (currentCache, newItems) => {
        return { ...currentCache, ...newItems };
      }
    }),
  }),
});

export const { useGetConfigQuery } = configApiSlice; 