import { Modal } from '@mui/material';
import { CommonButton, IconButton } from '@components/common/CommonButton';
import ModalLayout from '@components/layouts/ModalLayout';
import { useState } from 'react';
import { convertDateToUnixTimestamp, convertMsToDDMMYYYYHHmm } from '@utils/utils';

const OmsDuplicateCheckModal = ({
  duplicateCheckStatus,
  isOpen,
  isGenerateProductionOrder,
  isSendQueue,
  handleClick,
  onClose,
}: {
  duplicateCheckStatus: any;
  isOpen: boolean;
  isGenerateProductionOrder?: boolean;
  isSendQueue?: boolean;
  handleClick: () => void;
  onClose: () => void;
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <ModalLayout
          title={
            isGenerateProductionOrder
              ? 'OMS Duplicate Check - Duplicates Found'
              : 'OMS Duplicate Check Details'
          }
          handleCloseModal={onClose}
          alignment="center"
          size="small"
        >
          {isGenerateProductionOrder && (
            <div className="-mt-8">
              <div>There are duplicates found in the OMS system for this moniker.</div>
              <div className="mt-2">Click ‘Next’ to proceed with generating PO.</div>
            </div>
          )}
          {isSendQueue && (
            <div className="-mt-8">
              <div>There are duplicates found in the OMS system for this moniker.</div>
              <div className="mt-2">
                You may proceed to send this moniker to the queue for bulk PO generation. However,
                please note that generating a PO for this moniker will overwrite the existing one.
              </div>
            </div>
          )}
          <div
            className={`${isGenerateProductionOrder || isSendQueue ? 'mt-3' : '-mt-8'} bg-grey-200 p-4 rounded-lg shadow overflow-auto`}
          >
            <div className="flex justify-end h-3">
              {!isCopied && (
                <IconButton
                  className="align-right"
                  icon="copy"
                  type="button"
                  isDisabled={false}
                  onClick={() => {
                    const copyToClipboard = () => {
                      const headers = [
                        'Sub Request Status',
                        'Sub Request Reference No.',
                        'Type of Check',
                        'Direction Status',
                        'Direction Reference No.',
                        'Issued Date Time',
                        'Mode of Issuance',
                        'Department',
                        'Report No.',
                        'Other Source of Info',
                      ];

                      const rows = duplicateCheckStatus.map((status: any) => [
                        status.subRequestStatus || '-',
                        status.subRequestReferenceNo || '-',
                        status.typeOfCheck || '-',
                        status.directionStatus || '-',
                        status.directionReferenceNo || '-',
                        status.issuedDateTime || '-',
                        status.modeOfIssuance || '-',
                        status.department || '-',
                        status.reportNumber || '-',
                        status.sourceOfInfo || '-',
                      ]);

                      const csvContent = [headers, ...rows].map((e) => e.join('\t')).join('\n');

                      navigator.clipboard.writeText(csvContent).then(
                        () => {
                          console.log('Copied to clipboard successfully!');
                        },
                        (err) => {
                          console.error('Could not copy text: ', err);
                        },
                      );
                    };

                    copyToClipboard();
                    setIsCopied(true);
                    setTimeout(() => setIsCopied(false), 3000);
                  }}
                />
              )}
              {isCopied && <span className="text-blue-500 font-bold">Copied!</span>}
            </div>
            {duplicateCheckStatus &&
              typeof duplicateCheckStatus === 'object' &&
              duplicateCheckStatus.map((status: any, index: number) => (
                <div key={index}>
                  <div className="grid grid-cols-2 text-grey-400">
                    <div>Sub Request Status:</div>{' '}
                    <div className="font-semibold">{status.subRequestStatus || '-'}</div>
                    <div>Sub Request Reference No.:</div>{' '}
                    <div className="font-semibold">{status.subRequestReferenceNo || '-'}</div>
                    <div>Type of Check:</div>{' '}
                    <div className="font-semibold">{status.typeOfCheck || '-'}</div>
                    <div>Direction Status:</div>{' '}
                    <div className="font-semibold">{status.directionStatus || '-'}</div>
                    <div>Direction Reference No.:</div>{' '}
                    <div className="font-semibold">{status.directionReferenceNo || '-'}</div>
                    <div>Issued Date Time:</div>{' '}
                    <div className="font-semibold">
                      {convertMsToDDMMYYYYHHmm(
                        convertDateToUnixTimestamp('20250311152805', 'YYYYMMDDHHmmSSS'),
                      ) || '-'}
                    </div>
                    <div>Mode of Issuance:</div>{' '}
                    <div className="font-semibold">{status.modeOfIssuance || '-'}</div>
                    <div>Department:</div>{' '}
                    <div className="font-semibold">{status.department || '-'}</div>
                    <div>Report No.:</div>{' '}
                    <div className="font-semibold">{status.reportNumber || '-'}</div>
                    <div>Other Source of Info:</div>{' '}
                    <div className="font-semibold">{status.sourceOfInfo || '-'}</div>
                  </div>
                  {index < duplicateCheckStatus.length - 1 && (
                    <hr className="my-4 border-grey-400" />
                  )}
                </div>
              ))}
          </div>
          {isGenerateProductionOrder ||
            (isSendQueue && (
              <div className="flex justify-end mt-4">
                <button className="button px-4 rounded-lg font-bold" onClick={onClose}>
                  Cancel
                </button>
                <CommonButton
                  onClick={async () => {
                    setIsloading(true);
                    await handleClick();
                    onClose();
                    setIsloading(false);
                  }}
                  title={isGenerateProductionOrder ? 'Next' : 'Send to Queue'}
                  variant="primary"
                  isLoading={isLoading}
                  isDisabled={isLoading}
                />
              </div>
            ))}
        </ModalLayout>
      </Modal>
    </>
  );
};

export default OmsDuplicateCheckModal;
