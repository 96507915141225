// internet
import FormLoginPage from '@pages/login/FormLoginPage/FormLoginPage';
import { loader as formLoginLoader } from '@pages/login/FormLoginPage/FormLoginComponent';
import FormLoginOtpPage from '@pages/login/FormLoginOtpPage/FormLoginOtpPage';
import FormCreateReportPage from '@pages/reports/CreateReportPage/FormCreateReportPage';

//intranet
import LoginPage from '@pages/login/LoginPage/LoginPage';
import LoginChangePassword from '@pages/login/ChangePasswordPage/ChangePasswordPage';
import LoginSetupTotpPage from '@pages/login/SetupTotpPage/SetupTotpPage';
import LoginTotpPage from '@pages/login/LoginTotpPage/LoginTotpPage';
import { ReportsMainPage } from '@pages/reports/ReportsMainPage/ReportsMainPage';
import { ReportsDetailedPage } from '@pages/reports/ReportsDetailedPage/ReportsDetailedPage';
import LoginMainLayout from '@components/layouts/LoginMainLayout';
import { loader as loginLoader } from '@pages/login/LoginPage/LoginComponent';
import ListUsersPage from '@pages/users/ListUserPage/ListUsersPage';
import PortfoliosMainPage from '@pages/portfolios/PortfoliosMainPage';
import PortfolioMonikerDetailsPage from '@pages/portfolios/monikers/MonikersDetailedPage/PortfolioMonikerDetailsPage';
import DirectionsQueuePage from '@pages/directionsqueue/DirectionsQueuePage';
import ErrorPage from '@pages/error/ErrorPage/ErrorPage';
import BankDetailedPage from '@pages/portfolios/banks/BanksDetailedPage/BankDetailedPage';
import PaynowDetailedPage from '@pages/portfolios/paynow/PaynowsDetailedPage/PaynowDetailsPage';

import { createTheme, ThemeProvider } from '@mui/material';
import AdminCreateReportPage from '@pages/reports/CreateReportPage/AdminCreateReportPage';
import UploadPortfolioMonikerPage from '@pages/portfolios/monikers/MonikersDetailedPage/UploadPortfolioMonikerPage';

import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { useEffect } from 'react';

// Google Analytics
import ReactGA from 'react-ga4';
import AnalyticsLayout from "@components/layouts/AnalyticsLayout";
import { APP_ENVIRONMENT_CONFIG } from '@environment/AppEnvironment';

let internetRouter = [
  {
    path: '/',
    element: <AnalyticsLayout />,
    children: [
      {
        index: true,
        element: <FormLoginPage />,
        loader: formLoginLoader,
      },
      {
        path: 'form-login',
        element: <FormLoginPage />,
        loader: formLoginLoader,
      },
      {
        path: 'form-login-otp',
        element: <FormLoginOtpPage />,
        loader: formLoginLoader,
      },
      {
        path: 'create',
        element: <FormCreateReportPage />,
        loader: formLoginLoader,
      },
    ],
  },
];

let intranetRouter = [
  {
    path: '/',
    element: <AnalyticsLayout />,
    children: [
      {
        index: true,
        element: <LoginPage />,
        loader: loginLoader,
      },
      {
        path: 'error',
        element: <ErrorPage />,
      },
      {
        path: 'login',
        element: <LoginPage />,
        loader: loginLoader,
      },
      {
        path: 'login-change-password',
        element: <LoginChangePassword />,
        loader: loginLoader,
      },
      {
        path: 'login-setup-totp',
        element: <LoginSetupTotpPage />,
        loader: loginLoader,
      },
      {
        path: 'login-totp',
        element: <LoginTotpPage />,
        loader: loginLoader,
      },
      {
        path: 'form-login',
        element: <FormLoginPage />,
        loader: formLoginLoader,
      },
      {
        path: 'form-login-otp',
        element: <FormLoginOtpPage />,
        loader: formLoginLoader,
      },
      {
        path: 'create',
        element: <FormCreateReportPage />,
        loader: formLoginLoader,
      },
      {
        path: 'admin',
        loader: loginLoader,
        children: [
          {
            path: 'reports',
            element: <LoginMainLayout title="Reports" />,
            children: [
              { index: true, element: <ReportsMainPage /> },
              { path: ':id', element: <ReportsDetailedPage /> },
              { path: 'create', element: <AdminCreateReportPage /> },
            ],
          },
          {
            path: 'portfolios',
            element: <LoginMainLayout title="Portfolios" />,
            children: [
              { index: true, element: <Navigate to="/admin/portfolios/monikers" /> },
              { path: ':type', element: <PortfoliosMainPage /> },
              { path: 'monikers/:id', element: <PortfolioMonikerDetailsPage /> },
              { path: 'bank/:bankId', element: <BankDetailedPage /> },
              { path: 'paynow/:paynowId', element: <PaynowDetailedPage /> },
              { path: 'monikers/upload', element: <UploadPortfolioMonikerPage /> },
            ],
          },
          {
            path: 'directions-queue',
            element: <LoginMainLayout title="Directions Queue" />,
            children: [
              { index: true, element: <Navigate to="/admin/directions-queue/monikers" /> },
              { path: ':type', element: <DirectionsQueuePage /> },
            ],
          },
          {
            path: 'users',
            element: <LoginMainLayout title="User Management" />,
            children: [{ index: true, element: <ListUsersPage /> }],
          },
        ],
      },
    ],
  },
];

const router = createBrowserRouter(
  process.env.REACT_APP_DEPLOYMENT_ENV === 'intranet' ? intranetRouter : internetRouter,
);

const theme = createTheme({
  typography: {
    fontFamily: 'Inter-Regular, Arial, sans-serif',
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          '::before': {
            display: 'none',
          },
          backgroundColor: 'white',
          borderRadius: '10px',
          marginBottom: '20px',
          padding: '5px',
          border: 'none',
          borderTop: 0,
          boxShadow: 'none',
        },
      },
    },
  },
});

function App() {
  useEffect(() => {
    ReactGA.initialize(APP_ENVIRONMENT_CONFIG.GOOGLE_ANALYTICS_TAG);
  }, []);  
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
