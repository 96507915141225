import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AccessControl from '@components/common/AccessControl';
import { CommonButton } from '@components/common/CommonButton';
import FileUpload from '@components/common/FileUpload';
import { Badge, Tab, Tabs } from '@mui/material';
import { ReactComponent as ErrorOutline } from '@assets/icons/error_outline.svg';
import { set, useForm, useWatch } from 'react-hook-form';
import csv from 'csvtojson';
import cloneDeep from 'lodash.clonedeep';
import { platform, scamType, scamTypesBriefFactsMapping } from '@interface/constant';
import {
  cleanUpUserId,
  cleanUpWhatsappNumber,
  delay,
  generateReportNumber,
  getMimeType,
} from '@utils/utils';
import { Table } from 'antd';
import { useDispatch } from 'react-redux';
import { showAlert } from '@store/alertSlice';
import { useCreateReportCsvV2Mutation } from '@api/reportsSlice';
import moment from 'moment';
import { ReactComponent as Warning } from '@assets/icons/warning_yellow.svg';
import JSZip, { file } from 'jszip';
import { MonikerOutcomeStatus } from '@components/common/StatusComponent';
import UpdateAssessmentFindingsMonikerPresubmitChecklist1Modal from '../modals/UpdateAssessmentFindingsMonikerPresubmitChecklist1Modal';
import Dropdown from '@components/common/dropdown/Dropdown';

const validColumns = [
  {
    title: 'No.',
    key: 'index',
    render: (text: string, record: any, index: number) => index + 1,
    width: '5%',
  },
  {
    title: 'Report No.',
    dataIndex: 'reportNumber',
    key: 'reportNumber',
    width: '10%',
  },
  {
    title: 'User ID',
    dataIndex: 'userId',
    key: 'userId',
    width: '12%',
  },
  {
    title: 'URL',
    dataIndex: 'url',
    key: 'url',
    width: '18%',
  },
  {
    title: 'ProfileName',
    dataIndex: 'profileName',
    key: 'profileName',
    width: '12%',
  },
  {
    title: 'Platform',
    dataIndex: 'platform',
    key: 'platform',
    width: '10%',
  },
  {
    title: 'Scam Type',
    dataIndex: 'scamType',
    key: 'scamType',
    width: '10%',
  },
  {
    title: 'Brief Facts',
    dataIndex: 'briefFacts',
    key: 'briefFacts',
    width: '13%',
  },
  {
    title: 'Screenshot Match',
    width: '10%',
    render: (text: string, record: any, index: number) => {
      return record.attachments && record.attachments.length > 0 ? 'Yes' : '';
    },
  },
];

const uploadingColumns = [
  {
    title: 'No.',
    key: 'index',
    render: (text: string, record: any, index: number) => index + 1,
    width: '5%',
    textWrap: 'word-break',
  },
  {
    title: 'Moniker Id',
    dataIndex: 'userId',
    key: 'userId',
    width: '15%',
  },
  {
    title: 'URL',
    dataIndex: 'url',
    key: 'url',
    width: '15%',
  },
  {
    title: 'Platform',
    dataIndex: 'platform',
    key: 'platform',
    width: '12%',
  },
  {
    title: 'Profile Name',
    dataIndex: 'profileName',
    key: 'profileName',
    width: '15%',
  },
  {
    title: 'Brief Facts',
    dataIndex: 'briefFacts',
    key: 'briefFacts',
    width: '15%',
  },
  {
    title: 'DA1 Assigned',
    dataIndex: 'assignment',
    key: 'assignment',
    width: '15%',
    render: (assignment: any, record: any, index: number) =>
      assignment?.filter((e: any) => e.role === 'da1')[0].userName || '-',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '22%',
    render: (status: any, record: any, index: number) => (
      <>
        {status === 'Verifying...' && <p className="text-grey-400 italic">{status}</p>}
        {status.error && <p className="text-red">{status.error}</p>}
        {status.outcome && (
          <div className="flex flex-row">
            <div className="w-[15em] mr-8">
              <MonikerOutcomeStatus outcome={status.outcome} />
            </div>
            {status.invalidAssign && <Warning />}
          </div>
        )}
      </>
    ),
  },
];
const invalidColumns = [
  {
    title: 'No.',
    key: 'index',
    render: (text: string, record: any, index: number) => index + 1,
    width: '5%',
  },
  {
    title: 'Report No.',
    dataIndex: 'reportNumber',
    key: 'reportNumber',
    width: '10%',
  },
  {
    title: 'User ID',
    dataIndex: 'userId',
    key: 'userId',
    width: '12%',
  },
  {
    title: 'URL',
    dataIndex: 'url',
    key: 'url',
    width: '18%',
  },
  {
    title: 'ProfileName',
    dataIndex: 'profileName',
    key: 'profileName',
    width: '12%',
  },
  {
    title: 'Platform',
    dataIndex: 'platform',
    key: 'platform',
    width: '10%',
  },
  {
    title: 'Scam Type',
    dataIndex: 'scamType',
    key: 'scamType',
    width: '10%',
  },
  {
    title: 'Brief Facts',
    dataIndex: 'briefFacts',
    key: 'briefFacts',
    width: '12%',
  },
  {
    title: 'Reason',
    dataIndex: 'errorMessage',
    key: 'errorMessage',
    width: '12%',
    render: (text: string, record: any, index: number) => (
      <p className="text-red whitespace-pre-line">{text}</p>
    ),
  },
];

const actionDropdownData: any[] = [
  {
    id: 'uploadAssignAndBulkAssess',
    label: 'Bulk Assess Monikers',
    subLabel: 'Proceed for bulk assess the uploaded monikers for OCHA or CGC',
    type: 'col',
  },
  {
    id: 'uploadAndAssign',
    label: 'Assign Monikers to Me Only',
    subLabel: 'Take ownership of the uploaded monikers without bulk assess',
    type: 'col',
  },
  {
    id: 'uploadOnly',
    label: 'Upload Only',
    subLabel: 'Upload files without assigning or assessing the monikers',
    type: 'col',
  },
];

const UploadPortfolioMonikerComponent = ({ id }: { id: string }) => {
  const formMethods = useForm({});
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [createReportCsvV2] = useCreateReportCsvV2Mutation();
  const [uploadStage, setuploadStage] = useState('uploadAttachments');
  const [validCsvData, setValidCsvData] = useState<any[]>([]); // Specify the type of csvData as an array of objects
  const [invalidCsvData, setInvalidCsvData] = useState<any[]>([]); // Specify the type of csvData as an array of objects
  const [tabValue, setTabValue] = useState('valid');
  const dispatch = useDispatch();
  const [isFileVerifying, setIsFileVerifying] = useState(false);
  const [csvDataUploadList, setCsvDataUploadList] = useState<any[]>([]);

  const [action, setAction] = useState('');
  const [emptyActionErrorMessage, setEmptyActionErrorMessageAction] = useState(false);
  const [updateAssessmentFindingsOpen, setUpdateAssessmentFindingsOpen] = useState(false);
  const [selectedScamEnablers, setSelectedScamEnablers] = useState<any[]>([]);

  const [scamEnablersNotAssignedToYou, setScamEnablersNotAssignedToYou] = useState<any[]>([]);

  const [uploadedScreenshotFileName, setUploadedScreenshotFileName] = useState<string>('');
  const [uploadedMonikerListFileName, setUploadedMonikerListFileName] = useState<string>('');

  const watchedValues = useWatch({ control: formMethods.control });

  const onActionDropdownChange = (event: any) => {
    setAction(event?.id);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTabValue(newValue);
  };
  useEffect(() => {
    if (csvDataUploadList.length > 0) {
      let allSuccess = csvDataUploadList.every((item) => item.status.outcome);
      if (allSuccess) {
        setIsLoading(false);
        if (action === 'uploadAssignAndBulkAssess') {
          let addedScamEnablers = JSON.parse(
            JSON.stringify(csvDataUploadList.map((e) => e.response?.data?.scamEnablers[0])),
          );

          for (let scamEnabler of addedScamEnablers) {
            if (!(scamEnabler.outcome === 'Under Review' && scamEnabler.assignedToSelf === true)) {
              scamEnabler.invalid = true;
            }
          }

          let duplicateScamEnablers: string[] = [];
          let uniqueAddedScamEnablers = [];
          for (let scamEnabler of addedScamEnablers) {
            let id = scamEnabler.platform + scamEnabler.scamEnablerUserId;
            if (!duplicateScamEnablers.includes(id)) {
              uniqueAddedScamEnablers.push(scamEnabler);
              duplicateScamEnablers.push(id);
            }
          }
          setSelectedScamEnablers(uniqueAddedScamEnablers);
          let validScamEnablers = addedScamEnablers.filter((scamEnabler: any) => {
            return !scamEnabler.invalid;
          });
          if (validScamEnablers.length > 0) {
            setUpdateAssessmentFindingsOpen(true);
          } else {
            if (callback)
              callback({
                type: 'success',
                message: (
                  <div>
                    <p>{`${csvDataUploadList.length} new moniker(s) added successfully.`}</p>
                    <p className="italic pt-1">
                      As all monikers added are <b>not</b> Pending Review, Under Review, Not
                      Terminated
                    </p>
                    <p className="italic">
                      or No Action Required. Bulk update of assessment findings will not be
                      eligible.
                    </p>
                  </div>
                ),
              });
          }
        }
      }
    }
  }, [csvDataUploadList]);

  const validate = (element: any) => {
    let pattern = /^(?=.{13,15}$)([A-Z]{1,3}\/\d{4,8}\/\d+|ASC\/\d{4}\/\d{2}\/\d{2})$/;

    if (element.reportNumber && !pattern.test(element.reportNumber)) {
      element.errorMessage = 'Invalid Report Number';
      return element;
    }

    if (!element.userId || element.userId.trim() === '') {
      element.errorMessage = 'User ID is required';
      return element;
    }

    let allowedPlatform = platform;
    if (element.platform) {
      const matchingPlatform = allowedPlatform.find(
        (p) => p.toLowerCase() === element.platform.toLowerCase(),
      );

      if (matchingPlatform) {
        element.platform = matchingPlatform;
      } else {
        element.errorMessage = 'Invalid Platform';
        return element;
      }
    }

    let allowedScamType = scamType;
    if (element.scamType) {
      const matchingScamType = allowedScamType.find(
        (p) => p.toLowerCase() === element.scamType.toLowerCase(),
      );

      if (matchingScamType) {
        element.scamType = matchingScamType;
      } else {
        element.errorMessage = 'Invalid Scam Type';
        return element;
      }
    }

    if (!element.briefFacts) {
      let scamType: keyof typeof scamTypesBriefFactsMapping = element.scamType;
      element.briefFacts = scamTypesBriefFactsMapping[scamType];
    }

    const userId =
      element.platform === 'Whatsapp' ? cleanUpWhatsappNumber(element.userId) : element.userId;
    if (element.platform === 'Whatsapp' && !userId) {
      element.errorMessage = 'Invalid Whatsapp number';
      return element;
    }

    element.userId = cleanUpUserId(userId, element.platform || '');

    return element;
  };

  const setJSONData = async (jsonData: any[]) => {
    setIsFileVerifying(true);

    const { validData, invalidData } = jsonData.reduce(
      (acc, element, index) => {
        element = validate(element);

        if (element.errorMessage) {
          acc.invalidData.push(element);
        } else {
          if (!element.reportNumber) {
            element.reportNumber = generateReportNumber('CSV', index);
          }
          acc.validData.push(element);
        }

        return acc;
      },
      { validData: [], invalidData: [] },
    );

    let uploadedAttachments = cloneDeep(formMethods.getValues(`${id}-screenshots`));
    setUploadedScreenshotFileName(uploadedAttachments?.map((e: any) => e.fileName).join(', '));
    if (uploadedAttachments && uploadedAttachments[0]?.fileContent) {
      const zip = new JSZip();
      let fileContent = (uploadedAttachments[0].fileContent =
        uploadedAttachments[0].fileContent.split(',')[1]);
      const attachments = await zip.loadAsync(fileContent, { base64: true });
      const files: any = [];

      attachments.forEach((relativePath, file) => {
        files.push(
          file.async('base64').then((base64Content) => ({
            contentType: getMimeType(file.name),
            fileContent: base64Content,
            fileName: relativePath.split('/').pop(),
          })),
        );
      });

      const unzippedFiles = await Promise.all(files);

      // Match filenames with userId in validData
      validData.forEach((item: any) => {
        item.attachments = unzippedFiles.filter((file) => {
          const filenameWithoutExtension = file.fileName.split('/').pop()?.split('.')[0];
          return filenameWithoutExtension.toLowerCase() === item.userId.toLowerCase();
        });
      });
    }

    setValidCsvData(validData);
    setInvalidCsvData(invalidData);
    setIsFileVerifying(false);
  };

  const parseDateString = (dateString: string, timeString: string) => {
    const formattedTime =
      timeString.length === 3
        ? timeString.slice(0, 1) + ':' + timeString.slice(2)
        : timeString.slice(0, 2) + ':' + timeString.slice(2);
    const dateTimeString = `${dateString} ${formattedTime}`;

    // Define the supported formats
    const formats = [
      'D MMM YYYY HH:mm',
      'DD MMM YYYY HH:mm',
      'DD/MM/YYYY HH:mm',
      'D/MM/YYYY HH:mm',
    ];

    // Parse the date string using moment with the supported formats
    const parsedDate = moment(dateTimeString, formats, true);
    // Check if the date is valid
    if (!parsedDate.isValid()) {
      return '';
    }

    return parsedDate.valueOf();
  };

  const showCsvContent = async () => {
    let uploadedFile = cloneDeep(formMethods.getValues(`${id}-monikerList`));
    setUploadedMonikerListFileName(uploadedFile?.map((e: any) => e.fileName).join(', '));
    if (!uploadedFile[0]?.fileContent) {
      setValidCsvData([]);
      setInvalidCsvData([]);
      return;
    }

    uploadedFile[0].fileContent = uploadedFile[0].fileContent.split(',')[1];
    let csvData = Buffer.from(uploadedFile[0].fileContent, 'base64').toString('utf8');
    let jsonData = await csv().fromString(csvData);

    await setJSONData(jsonData);
  };

  const handleNext = async () => {
    if (uploadStage === 'uploadAttachments') {
      setIsLoading(true);
      await showCsvContent();
      setIsLoading(false);
      setuploadStage('evaluate');
      return;
    } else if (uploadStage === 'evaluate') {
      setuploadStage('uploadAPI');
      onUpload();
    } else if (uploadStage === 'uploadAPI') {
    }
  };
  const handlePrevious = async () => {
    if (uploadStage === 'evaluate') {
      // Reset form values when going back to upload stage
      formMethods.reset();
      setValidCsvData([]);
      setInvalidCsvData([]);
      setuploadStage('uploadAttachments');
    }
  };

  const checkDisabled = () => {
    if (uploadStage === 'uploadAttachments') {
      let uploadedFile = cloneDeep(watchedValues);
      return !uploadedFile ||
        Object.keys(uploadedFile).length === 0 ||
        !(uploadedFile[`${id}-monikerList`] && uploadedFile[`${id}-monikerList`].length > 0)
        ? true
        : false;
    } else if (uploadStage === 'evaluate') {
      let isDisabled = (validCsvData.length <= 0 ? true : false) || !action;
      return isDisabled;
    } else if (uploadStage === 'uploadAPI') {
    }
    return true;
  };

  const onUpload = async () => {
    if (validCsvData.length <= 0) return;
    let createRequest: any = [];
    validCsvData.forEach((element) => {
      let tempElement = cloneDeep(element);
      createRequest.push(tempElement);
    });
    let status: any[] = [];
    status = createRequest.map((element: any) => {
      return {
        ...element,
        status: 'Verifying...',
      };
    });
    setCsvDataUploadList(status);
    setIsLoading(true);

    let tempScamEnablersNotAssignedToYou: any[] = [];
    for (let i = 0; i < createRequest.length; i++) {
      const request = createRequest[i];
      request.action = action;

      let response: any = await createReportCsvV2([request]).then((response: any) => {
        if (!response.error && response.data.failed.length <= 0) {
          status[i].status = {
            outcome: response?.data?.scamEnablers[0].outcome,
            invalidAssign:
              action !== 'uploadOnly' && !response?.data?.scamEnablers[0].assignedToSelf,
          };
          status[i].assignment = response?.data?.scamEnablers[0].assignment;
          status[i].response = response;

          if (!response?.data?.scamEnablers[0].assignedToSelf) {
            tempScamEnablersNotAssignedToYou.push(status[i]);
            setScamEnablersNotAssignedToYou(tempScamEnablersNotAssignedToYou);
          }
        } else {
          status[i].status = {
            error: `Failed. ${response.error_description || response.data?.failed[0]?.error || ''}`,
          };
          status[i].response = response;
        }
        setCsvDataUploadList([...status]);
      });

      await delay(100); // Wait for 100ms before submitting the next request
    }

    setIsLoading(false);
  };

  const handleDownloadTemplate = () => {
    const link = document.createElement('a');
    link.href = '/assets/template/csv_upload_template.csv';
    link.download = 'SATIS+ CSV Upload Template.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadInvalidMonikers = () => {
    let data = 'Report No.,UserID,URL,DA1 Assigned,Outcome\n';
    for (let scamEnabler of scamEnablersNotAssignedToYou) {
      data += `${scamEnabler.reportNumber},${scamEnabler.userId},${scamEnabler.url},${scamEnabler.assignment?.filter((e: any) => e.role === 'da1')[0].userName},${scamEnabler.status.outcome}\n`;
    }

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.download = 'Invalid assignment monikers.csv';
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const callback = (alertMessage?: any) => {
    alertMessage = alertMessage || {
      type: 'success',
      message: `Monikers bulk assessed and assigned successfully.`,
    };
    navigate('/admin/portfolios/monikers');
    dispatch(showAlert(alertMessage));
  };

  const backToMain = () => {
    navigate('/admin/portfolios/monikers');
  };

  const validateCsvRowCount = async (file: File): Promise<string | undefined> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const text = e.target?.result as string;
        const rows = text.split('\n').filter((row) => row.trim()); // Remove empty rows
        const dataRowCount = rows.length - 1; // -1 for header row
        if (dataRowCount > 50) {
          resolve(
            'CSV file cannot contain more than 50 monikers. Please reduce the number of monikers and try again.',
          );
        }
        resolve(undefined);
      };
      reader.readAsText(file);
    });
  };

  return (
    <>
      <AccessControl type="page" allowedPermissions={['portfolios.scamenablers.create']}>
        <div className="flex flex-col h-[80vh] justify-between">
          {uploadStage === 'uploadAttachments' && (
            <>
              <div className="flex flex-row items-center p-4 bg-yellow-200 border border-yellow rounded-lg mb-5 -mt-2">
                <Warning />
                <p className="pl-4 text-xs">
                  Please ensure that the Screenshots file attached is for the monikers in the
                  Moniker List. The system will match and attach the Screenshots file to each of the
                  monikers in the Moniker List accordingly
                </p>
              </div>
            </>
          )}
          {uploadStage === 'uploadAttachments' && (
            <form className="flex flex-row justify-between pb-10">
              <div className="w-[48%] h-[40vh]">
                <h3 className="font-bold pb-2">Screenshots</h3>
                <FileUpload
                  id={`${id}-screenshots`}
                  control={formMethods.control}
                  setValue={formMethods.setValue}
                  getValues={formMethods.getValues}
                  iconType="image"
                  fileType={['application/zip', 'application/x-zip-compressed']}
                  maxFiles={1}
                  maxfileSizeMB={10}
                  // callback={showCsvContent}
                  isLoading={isFileVerifying}
                  allowPaste={false}
                />
              </div>
              <div className="w-[48%] h-[40vh]">
                <div className="flex flex-row justify-between">
                  <h3 className="font-bold pb-2">Moniker List</h3>
                  <div>
                    <CommonButton
                      className="pb-3"
                      title="Download CSV Template"
                      variant="text-blue"
                      onClick={handleDownloadTemplate}
                      type="button"
                    />
                  </div>
                </div>
                <FileUpload
                  id={`${id}-monikerList`}
                  control={formMethods.control}
                  setValue={formMethods.setValue}
                  getValues={formMethods.getValues}
                  fileType={['text/csv']}
                  maxFiles={1}
                  // callback={showCsvContent}
                  isLoading={isFileVerifying}
                  allowPaste={false}
                  validation={validateCsvRowCount}
                />
              </div>
            </form>
          )}
          {uploadStage === 'evaluate' &&
            ((validCsvData && validCsvData.length > 0) ||
              (invalidCsvData && invalidCsvData.length > 0)) && (
              <>
                <div>
                  <div className="flex">
                    <h5 className="font-bold">Screenshots: </h5>
                    <h5 className="ml-2">{uploadedScreenshotFileName || '-'}</h5>
                  </div>
                  <div className="flex">
                    <h5 className="font-bold">Moniker List: </h5>
                    <h5 className="ml-2">{uploadedMonikerListFileName || '-'}</h5>
                  </div>
                </div>
                <div className="flex justify-between items-center -mt-2">
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    TabIndicatorProps={{ style: { display: 'none' } }}
                  >
                    <Tab
                      label={
                        <div className="flex flex-row justify-center items-center">
                          <p
                            className={`${tabValue === 'valid' ? 'text-blue-500 font-bold bg-blue-200' : 'text-blue-400 font-bold'} px-6 py-3 rounded-md`}
                            style={{ textTransform: 'none' }}
                          >
                            Successful
                            <Badge
                              color="success"
                              badgeContent={validCsvData.length || 0}
                              max={999}
                              className="ml-5"
                            />
                          </p>
                        </div>
                      }
                      value="valid"
                    />
                    <Tab
                      label={
                        <div className="flex flex-row justify-center items-center">
                          <p
                            className={`${tabValue === 'invalid' ? 'text-blue-500 font-bold bg-blue-200' : 'text-blue-400 font-bold'} px-6 py-3 rounded-md`}
                            style={{ textTransform: 'none' }}
                          >
                            Error
                            <Badge
                              color="error"
                              badgeContent={invalidCsvData.length || 0}
                              max={999}
                              className="ml-5"
                            />
                          </p>
                        </div>
                      }
                      value="invalid"
                    />
                  </Tabs>
                  <div className="flex flex-col w-[33em]">
                    <Dropdown
                      id={'action'}
                      control={formMethods.control}
                      formState={formMethods.formState}
                      dropdownData={actionDropdownData}
                      setValue={formMethods.setValue}
                      watch={formMethods.watch}
                      placeholder="How would you like to proceed?"
                      onChange={onActionDropdownChange}
                    ></Dropdown>
                  </div>
                </div>
                {tabValue === 'valid' && (
                  <div className="h-[100vh] overflow-auto">
                    <Table
                      rowKey="userId"
                      dataSource={cloneDeep(validCsvData)}
                      columns={validColumns}
                      pagination={false}
                      scroll={{ x: 'max-content' }} // Enable horizontal scrolling
                      sticky
                    />
                  </div>
                )}
                {tabValue === 'invalid' && (
                  <div className="h-[100vh] overflow-auto">
                    <Table
                      rowKey="userId"
                      dataSource={cloneDeep(invalidCsvData)}
                      columns={invalidColumns}
                      pagination={false}
                      scroll={{ x: 'max-content' }} // Enable horizontal scrolling
                      sticky
                    />
                  </div>
                )}
              </>
            )}
          {uploadStage === 'uploadAPI' && (
            <div>
              <h2 className="font-bold mb-4">Status Verification</h2>
              {(action === 'uploadAndAssign' || action === 'uploadAssignAndBulkAssess') &&
                scamEnablersNotAssignedToYou.length > 0 && (
                  <>
                    <div className="flex flex-row items-center p-4 bg-yellow-200 border border-yellow rounded-lg mb-5 -mt-2">
                      <Warning />
                      <p className="pl-4 text-xs">
                        There are monikers cannot be assigned to you because they are already
                        assigned to someone else.
                      </p>
                      &nbsp;
                      <CommonButton
                        className="font-normal text-blue-500"
                        title="Download List"
                        variant="text-blue"
                        onClick={handleDownloadInvalidMonikers}
                        type="button"
                      />
                    </div>
                  </>
                )}
              {(action === 'uploadAndAssign' || action === 'uploadAssignAndBulkAssess') && (
                <h5 className="font-bold mb-4">
                  No. of monikers not assigned to you: {scamEnablersNotAssignedToYou.length}
                </h5>
              )}
              <div className="h-[50vh] overflow-y-auto">
                <Table
                  rowKey="userId"
                  dataSource={cloneDeep(csvDataUploadList)}
                  columns={uploadingColumns}
                  pagination={false}
                  sticky
                />
              </div>
            </div>
          )}
          {uploadStage === 'evaluate' && (
            <div className={`flex flex-row justify-end h-14`}>
              {emptyActionErrorMessage && !action && (
                <div className="flex flex-row mt-10">
                  <ErrorOutline className="mr-2" />
                  <div className="flex flex-col">
                    <p className="text-grey-400 text-sm italic ">
                      Please select how would you like to proceed with the uploaded monikers.
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="flex justify-end gap-4 pt-4">
            {uploadStage === 'evaluate' && (
              <CommonButton
                isDisabled={false}
                variant="secondary"
                title={`Previous`}
                onClick={handlePrevious}
              />
            )}
            {uploadStage === 'uploadAPI' && (
              <CommonButton
                isDisabled={false}
                variant="primary"
                title={`Go Back to Main Page`}
                onClick={backToMain}
              />
            )}
            {uploadStage !== 'uploadAPI' && (
              <>
                <CommonButton
                  isLoading={isLoading}
                  variant="primary"
                  title={uploadStage === 'evaluate' ? 'Upload Successful Rows' : 'Continue'}
                  onClick={handleNext}
                  isDisabled={checkDisabled()}
                  onMouseEnter={() => {
                    setEmptyActionErrorMessageAction(true);
                  }}
                  onMouseLeave={() => {
                    setEmptyActionErrorMessageAction(false);
                  }}
                />
              </>
            )}
          </div>
        </div>
        {updateAssessmentFindingsOpen && (
          <UpdateAssessmentFindingsMonikerPresubmitChecklist1Modal
            selectedScamEnablers={selectedScamEnablers}
            isOpen={updateAssessmentFindingsOpen}
            handleClose={() => setUpdateAssessmentFindingsOpen(false)}
            callback={callback}
          />
        )}
      </AccessControl>
    </>
  );
};

export default UploadPortfolioMonikerComponent;
