import { Modal } from '@mui/material';
import ModalLayout from '@components/layouts/ModalLayout';
import DynamicForm from '@components/common/DynamicForm';
import {
  submissionDetailsGeneralInfo,
  submissionDetailsScamEnablerBank,
  submissionDetailsScamEnablerPaynow,
} from '../ReportFormConfig';
import { getFormItems } from '../CreateReportPage/AdminCreateReportFormConfig';
import { useEffect, useState, useMemo } from 'react';
import { IconButton } from '@components/common/CommonButton';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import cloneDeep from 'lodash.clonedeep';
import { convertMsToYYYYMMDD } from '@utils/utils';
import AccessControl from '@components/common/AccessControl';
import ScamEnablersDisplay from '../components/ScamEnablersDisplay';

function SubmissionDetailFinancialInstitute({
  submission,
  index,
}: {
  submission: any;
  index: number;
}) {
  const formMethods = useForm({ defaultValues: submission, mode: 'onChange' });
  useEffect(() => {
    formMethods.reset(submission);
  }, [submission]);

  return (
    <>
      <div className="flex">
        <h4 className="font-bold text-sm text-grey-600 pr-1">Scam Enabler {index}</h4>
        {submission.deleted === true && (
          <p className="font-bold text-sm text-red italic">(deleted)</p>
        )}
      </div>
      <DynamicForm
        data={submission}
        formFields={
          submission.platform === 'Paynow'
            ? submissionDetailsScamEnablerPaynow
            : submissionDetailsScamEnablerBank
        }
        isEditable={false}
        formMethods={formMethods}
      />
    </>
  );
}

function SubmissionDetailSubmission({
  submissionData,
  isActive,
}: {
  submissionData: any;
  isActive: boolean;
}) {
  const formMethods = useForm({ defaultValues: submissionData, mode: 'onChange' });

  const transformData = (data: any) => {
    // For Monikers, we need to group by platform
    const platformGroups: Record<string, any[]> = {};
    console.log("data", data);
    // Group monikers by platform
    if (data.scamEnablerSubmissions && data.scamEnablerSubmissions.length > 0) {
      data.scamEnablerSubmissions.forEach((moniker: any) => {
        const platform = moniker.platform || 'Unknown';
        if (!platformGroups[platform]) {
          platformGroups[platform] = [];
        }
        platformGroups[platform].push(moniker);
      });
    }
    
    // Convert the grouped monikers to the expected format - one entry per platform with an array of rows
    const monikers = Object.entries(platformGroups).map(([platform, items]) => ({
      platform,
      rows: items
    }));
    /*
    // Filter financial institute submissions into banks and fintech based on submission type
    const banks = [];
    const fintech = [];
    
    if (data.financialInstituteSubmissions && data.financialInstituteSubmissions.length > 0) {
      // Filter Payment type for banks
      const bankSubmissions = data.financialInstituteSubmissions.filter(
        (submission: any) => submission.type === "Payment"
      );
      
      if (bankSubmissions.length > 0) {
        banks.push({ rows: bankSubmissions });
      }
      
      // Filter FintechCrypto type for fintech
      const fintechSubmissions = data.financialInstituteSubmissions.filter(
        (submission: any) => submission.type === "FintechCrypto"
      );
      
      if (fintechSubmissions.length > 0) {
        fintech.push({ rows: fintechSubmissions });
      }
    }*/
      const fintech = data.financialInstituteSubmissions && data.financialInstituteSubmissions.length > 0 
      ? [{ rows: data.financialInstituteSubmissions }] 
      : [];

    const paynow = data.paynowSubmissions && data.paynowSubmissions.length > 0 
      ? [{ rows: data.paynowSubmissions }] 
      : [];
    
    return {
      scamEnablers: {
        Monikers: monikers,
        //Banks: banks,
        Paynow: paynow,
        Fintech: fintech
      }
    };
  };
  
  // Effect to register the data with the form
  useEffect(() => {
    // Transform the data inside the effect
    const transformedData = transformData(submissionData);
    
    // Register data with the form
    if (transformedData?.scamEnablers) {
      if (transformedData.scamEnablers.Monikers) {
        transformedData.scamEnablers.Monikers.forEach((moniker: any, index: number) => {
          if (moniker.rows && Array.isArray(moniker.rows)) {
            formMethods.setValue(`scamEnablers[Monikers][${index}].rows`, moniker.rows);
          }
        });
      }
        
      // Register Banks data
      /*
      if (transformedData.scamEnablers.Banks) {
        transformedData.scamEnablers.Banks.forEach((bank: any, index: number) => {
          if (bank.rows && Array.isArray(bank.rows)) {
            formMethods.setValue(`scamEnablers[Banks][${index}].rows`, bank.rows);
          }
        });
      }*/
      
      // Register Paynow data
      if (transformedData.scamEnablers.Paynow) {
        transformedData.scamEnablers.Paynow.forEach((paynow: any, index: number) => {
          if (paynow.rows && Array.isArray(paynow.rows)) {
            formMethods.setValue(`scamEnablers[Paynow][${index}].rows`, paynow.rows);
          }
        });
      }
      
      // Register Fintech data
      if (transformedData.scamEnablers.Fintech) {
        transformedData.scamEnablers.Fintech.forEach((fintech: any, index: number) => {
          if (fintech.rows && Array.isArray(fintech.rows)) {
            formMethods.setValue(`scamEnablers[Fintech][${index}].rows`, fintech.rows);
          }
        });
      }
    }
  }, [formMethods, submissionData]);

  // Transform the data again for passing to component
  const transformedData = transformData(submissionData);

  return (
    <div
      className={clsx('h-full overflow-auto', {
        hidden: !isActive,
      })}
    >
      <h3 className="text-black-400 font-bold mb-4">General Information</h3>
      <div className="bg-white p-4 rounded-lg mb-4">
        <DynamicForm
          formFields={submissionDetailsGeneralInfo.map((field) => ({
            ...field,
            className: 'py-1',
          }))}
          data={submissionData}
        />
      </div>
      <h3 className="text-black-400 font-bold mb-4">Scam Enablers</h3>
      <AccessControl type="component" allowedPermissions={['reports.scamenablers.view']}>
        <ScamEnablersDisplay 
          formMethods={formMethods}
          formValues={transformedData}
          isSubmissionView={true}
        />
      </AccessControl>
    </div>
  );
}

const SubmissionsDetailsModal = ({
  assignOpen,
  onAssignClose,
  callback,
  data,
  reportNumber,
}: {
  assignOpen: boolean;
  onAssignClose: () => void;
  callback: () => void;
  data: any;
  reportNumber: string;
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [processedData, setProcessedData] = useState<any>([]);
  useEffect(() => {
    let newData: any = [];
    data.forEach((submission: any) => {
      newData.push(processSubmission(submission));
    });
    setProcessedData(newData);
  }, [data]);

  const processSubmission = (submission: any) => {
    let processedSubmission = cloneDeep(submission);
    if (processedSubmission?.financialInstituteSubmissions?.length > 0) {
      processedSubmission.financialInstituteSubmissions.forEach((submission: any) => {
        let transactions: any = [];
        if (submission.transactions && submission.transactions.length > 0) {
          submission.transactions.forEach((t: any) => {
            transactions.push({
              dateOfTransfer: t.dateOfTransfer ? convertMsToYYYYMMDD(t.dateOfTransfer) : '',
              amountLoss: `$${t?.amountLossInSGD?.toFixed(2) || t?.amountLoss.toFixed(2) || 0} ${t?.currency === 'SGD' ? '' : `(${t?.amountLoss.toFixed(2) + ' ' + t?.currency})`}`,
              transactionType: t.transactionType,
            });
          });
        }

        submission.transactions = transactions;
      });
    }
    return processedSubmission;
  };

  return (
    <Modal open={assignOpen} onClose={onAssignClose}>
      <ModalLayout
        title={reportNumber}
        handleCloseModal={onAssignClose}
        alignment="right-half-gray"
        size="full-gray"
      >
        <div className="overflow-auto h-[84vh] flex flex-col -mt-6">
          <div className="flex justify-between align-middle mb-5">
            <h4 className="text-md font-semibold text-blue-500">Submissions</h4>

            <div className="flex flex-row align-middle justify-center items-center gap-2">
              <IconButton
                isDisabled={activeTab === 0}
                icon="left-arrow"
                size="18px"
                className="bg-blue-200 p-1 rounded-md"
                onClick={() => setActiveTab(activeTab - 1)}
              />
              <p className="text-sm text-grey-600">
                <span className="font-semibold">{activeTab + 1}</span> of {data?.length || 0}
              </p>
              <IconButton
                isDisabled={activeTab + 1 === data.length}
                icon="right-arrow"
                size="18px"
                className="bg-blue-200 p-1 rounded-md"
                onClick={() => setActiveTab(activeTab + 1)}
              />
            </div>
          </div>
          {processedData.map((submission: any, index: number) => (
            <SubmissionDetailSubmission
              key={index}
              isActive={activeTab === index}
              submissionData={{
                ...submission,
                reportNumber: reportNumber,
              }}
            />
          ))}
        </div>
      </ModalLayout>
    </Modal>
  );
};

export default SubmissionsDetailsModal;
