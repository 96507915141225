import { Outlet } from 'react-router-dom';
import SideNav from '../common/Sidenav';
import { useSelector } from 'react-redux';
import { apiSlice } from '../../api/apiSlice';
import { useEffect } from 'react';
import { redirect } from 'react-router-dom';



export function LoginMainLayout({ title }: any) {
  const { error } = useSelector((state: any) => state[apiSlice.reducerPath]);

  useEffect(() => {
    if (error) {
      if (error.status === 401) {
      } else {
        alert(`An error occurred: ${error.message}`);
      }
    }
  }, [error]);

  useEffect(() => {
    if (error && error.status === 401) {
      redirect(`/login`);
    }
  }, [error]);

  return (
    <>
      <div className="flex flex-grow overflow-hidden h-screen">
        <div className=" flex-none pt-0">{<SideNav />}</div>
        <div className="flex-grow overflow-hidden pb-6 mb-4 ">
          <div className="w-full h-full">{<Outlet />}</div>
        </div>
      </div>
    </>
  );
}

export default LoginMainLayout;
