import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useGetFinancialInstituteByIdQuery } from '@api/financialInstituteSlice';
import { getSessionUserFromAccessToken } from '@utils/utils';
import { transformData } from './BankDetailedOverview';

// REFER TO useMonikersDetailedPage TO POPULATE
export const useBankDetailedPage = () => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const reportId = searchParams.get('reportId');
  const [myUser, setMyUser] = useState({
    userId: '',
    firstName: '',
    lastName: '',
  });

  const { refetch: originalRefetch, data: originalData, isSuccess, isError, isLoading, isFetching } =
    useGetFinancialInstituteByIdQuery({
      id: params.bankId,
    });

  //Apply transformData to the financialInstitute object
  const data = originalData ? {
    ...originalData,
    financialInstitute: originalData.financialInstitute ? 
      transformData(originalData.financialInstitute) : 
      originalData.financialInstitute
  } : originalData;

  const refetch = async () => {
    const result = await originalRefetch();
    return result;
  };

  useEffect(() => {
    getSessionUserFromAccessToken().then((userSession: any) => {
      setMyUser(userSession);
    });
  }, []);

  return {
    params,
    reportId,
    refetch,
    data,
    isSuccess,
    isError,
    isLoading,
    isFetching,
    myUser,
  };
};
