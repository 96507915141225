// Import the RTK Query methods from the React-specific entry point
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { APP_ENVIRONMENT_CONFIG } from '@environment/AppEnvironment';
import { showAlert } from '@store/alertSlice';
import Cookies from 'js-cookie';

// Define our single API slice object
export const apiSlice = createApi({
  // The cache reducer expects to be added at `state.api` (already default - this is optional)
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: APP_ENVIRONMENT_CONFIG.BACKEND_URL,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
      // Set Access-Control-Allow-Origin header
      // headers.set('Content-Encoding', 'gzip');
      return headers;
    },
    responseHandler: async (response) => {
      // console.log('response', response.headers.get('Content-Disposition'));

      if (!response.ok) {
        throw { status: response.status, message: response.statusText };
      }
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        return response.json();
      } else {
        return { data: response.blob(), headers: response.headers };
      }
    },
  }),

  endpoints: (builder) => ({}),
});

export const errorMiddleware = (storeAPI: any) => (next: any) => (action: any) => {
  if (action.type.endsWith('/rejected')) {
    if (
      action?.payload?.data &&
      action?.payload?.originalStatus !== 201 &&
      action?.payload?.originalStatus !== 200
    ) {
      try {
        let error = JSON.parse(action.payload.data);

        if (
          action?.payload?.originalStatus === 440 &&
          !action.meta.arg.endpointName.startsWith('login') &&
          !action.meta.arg.endpointName.startsWith('formLogin')
        ) {
          storeAPI.dispatch(showAlert({ type: 'error', message: `Session Expired logging out` }));

          if (window.location.pathname === '/create') {
            if (Cookies.get('SatisPlusFormAccessToken')) {
              Cookies.remove('SatisPlusFormAccessToken');
            }
            window.location.href = '/form-login';
          } else {
            if (Cookies.get('SatisPlusAccessToken')) {
              Cookies.remove('SatisPlusAccessToken');
            }
            window.location.href = '/login';
          }
        } else if (action?.payload?.originalStatus === 413) {
          storeAPI.dispatch(
            showAlert({
              type: 'error',
              message: `Payload too large, total form size exceed 6MB`,
            }),
          );
        } else {
          storeAPI.dispatch(
            showAlert({
              type: 'error',
              message: `An error occurred: ${error.error_description} - ${error.trace_id}`,
            }),
          );
        }
      } catch (e) {
        console.log('error', e);
      }
    }
  }
  return next(action);
};
