import { Badge, Box, Modal, Tab, Tabs, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ModalLayout from '@components/layouts/ModalLayout';
import { CommonButton } from '@components/common/CommonButton';
import {
  useGetScamEnablersExistV2Mutation,
  useUpdateScamEnablerV2Mutation,
} from '@api/scamEnablerSlice';
import DynamicForm from '@components/common/DynamicForm';
import { platform, scamEnablerOutcomes, scamEnablerOutcomesUpdate } from '@interface/constant';
import { convertMsToDDMMYYYYHHmm, extractUserIdFromUrl } from '@utils/utils';
import CustomizedStepperForm from '@components/common/Stepper';
import { ErrorOutline } from '@mui/icons-material';
import cloneDeep from 'lodash.clonedeep';
import { Table } from 'antd';
import { MonikerOutcomeStatus } from '@components/common/StatusComponent';
import { ReactComponent as ExpandMoreIcon } from '@assets/icons/down_arrow.svg';
import { ReactComponent as ExpandLessIcon } from '@assets/icons/up_arrow.svg';
import { ReactComponent as WarningYellowIcon } from '@assets/icons/warning_yellow.svg';
import { useUpdateProductionOrdersV2Mutation } from '@api/productionOrderSlice';
import { useDispatch } from 'react-redux';
import { showAlert } from '@store/alertSlice';

export const validColumns = [
  {
    title: 'Row',
    key: 'index',
    render: (text: string, record: any, index: number) => index + 1,
  },
  {
    title: 'Moniker ID',
    dataIndex: 'scamEnablerUserId',
    key: 'scamEnablerUserId',
    width: '90%',
  },
];

export const invalidColumns = [
  {
    title: 'Row',
    key: 'index',
    render: (text: string, record: any, index: number) => index + 1,
  },
  {
    title: 'Moniker ID',
    dataIndex: 'scamEnablerUserId',
    key: 'scamEnablerUserId',
    width: '45%',
  },
  {
    title: 'Reason',
    dataIndex: 'errorMessage',
    key: 'errorMessage',
    width: '45%',
  },
];

const updateSelectScamEnablerConfig = [
  {
    label: 'Platform',
    type: 'dropdown',
    id: 'platform',
    extras: { dropdownData: platform },
    placeholder: 'Please select',
    alignment: 'column',
  },
  {
    label: 'Input the moniker IDs below',
    helperText: 'Fill in one user ID/phone no./full URL(FB and IG only) per line',
    id: 'scamEnablerIds',
    type: 'textInput',
    extras: {
      rows: 15,
    },
    alignment: 'column',
  },
];

const updateConfig = [
  {
    label: 'Moniker Status',
    type: 'dropdown',
    id: 'outcome',
    extras: { dropdownData: scamEnablerOutcomesUpdate },
    placeholder: 'Please select',
    alignment: 'column',
  },
  {
    label: 'Date of Reply',
    type: 'dateTime',
    id: 'closedAt',
    extras: { removePopupContainer: true },
    alignment: 'column',
  },
  {
    label: 'Platform Reference No.',
    type: 'textInput',
    id: 'platformReferenceNo',
    extras: { removePopupContainer: true },
    alignment: 'column',
  },
];

const stepUpdateConfig = [
  {
    label: 'Moniker Status',
    type: 'dropdown',
    id: 'outcome',
    extras: { dropdownData: scamEnablerOutcomesUpdate },
    placeholder: 'Please select',
    alignment: 'column',
  },
];

const UpdateMonikerModal = ({
  isOpen,
  handleClose,
  selectedScamEnablers,
  showSelectedScamEnablers,
  callback,
}: {
  isOpen: boolean;
  handleClose: () => void;
  selectedScamEnablers?: any[];
  showSelectedScamEnablers?: boolean;
  callback: () => void;
}) => {
  const step1FormMethods = useForm({
    mode: 'onChange',
  });
  const formSubmitMethods = useForm({
    mode: 'onChange',
  });
  const steps = ['Input Moniker IDs', 'System Verification', 'Update Fields'];
  const [checkUserId] = useGetScamEnablersExistV2Mutation();
  const [updateScamEnablerV2] = useUpdateScamEnablerV2Mutation();
  const [updateProductionOrdersV2] = useUpdateProductionOrdersV2Mutation();

  const [stepCompletionStatus, setStepCompletionStatus] = useState(
    new Array(steps.length).fill(false),
  );
  const [activeStep, setActiveStep] = useState(0);
  const [tabValue, setTabValue] = useState('valid');

  const isButtonDisabled = false;
  const [validMonikerIds, setValidMonikerIds] = useState<any[]>([]);
  const [invalidMonikerIds, setInvalidMonikerIds] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const [showWarning, setShowWarning] = useState(false);

  const toggleExpansion = () => setIsExpanded(!isExpanded);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTabValue(newValue);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedScamEnablers) {
      selectedScamEnablers.map((scamEnabler) => {
        const mergedProductionOrders = scamEnabler.productionOrders.map((order: any) => {
          const matchingOrder = scamEnabler.productionOrderList?.find(
            (listOrder: any) => listOrder.productionOrderId === order.productionOrderId,
          );
          return { ...order, ...matchingOrder };
        });
        mergedProductionOrders.sort((a: any, b: any) => b.createdAt - a.createdAt);

        let filteredProductionOrders = mergedProductionOrders.filter(
          (order: any) => !order.closedAt,
        );
        scamEnabler.productionOrder = mergedProductionOrders[0];

        if (filteredProductionOrders.length == 1) {
          scamEnabler.productionOrder = filteredProductionOrders[0];
        }

        if (
          scamEnabler.outcome !== 'PO Issued' ||
          mergedProductionOrders.every((order: any) => order.closedAt) ||
          mergedProductionOrders.length == 0 ||
          filteredProductionOrders.length > 1
        ) {
          setShowWarning(true);
          scamEnabler.warning = true;
        }
      });
      if (
        selectedScamEnablers.length !== 0 &&
        selectedScamEnablers.every((scamEnabler) => scamEnabler.warning)
      ) {
        dispatch(
          showAlert({
            type: 'error',
            message: 'All selected monikers are not eligible for update.',
          }),
        );
        handleClose();
      }
    }
  }, selectedScamEnablers);

  const handleNext = async () => {
    if (activeStep === 0) {
      setIsLoading(true);
      const formValues = step1FormMethods.getValues();
      if (formValues.scamEnablerIds) {
        let scamEnablerUserIds = formValues.scamEnablerIds.split('\n');
        scamEnablerUserIds = scamEnablerUserIds.map((item: string) => {
          return extractUserIdFromUrl(item, formValues.platform).userId || item;
        });
        const platform = formValues.platform;
        let response = await checkUserId({
          scamEnablerUserIds,
          platform,
        }).unwrap();
        let tempValidMonikerIds: any[] = [];
        let tempInvalidMonikerIds: any[] = [];
        response.result.forEach((item: any) => {
          if (item.exists === true) {
            tempValidMonikerIds.push(item);
          } else {
            let newItem = { ...item, errorMessage: 'Moniker Id does not exist in the system.' };
            tempInvalidMonikerIds.push(newItem);
          }
        });
        setValidMonikerIds(tempValidMonikerIds);
        setInvalidMonikerIds(tempInvalidMonikerIds);

        setIsLoading(false);
        setStepCompletionStatus((prev) => {
          const newStatus = [...prev];
          newStatus[activeStep] = true;
          return newStatus;
        });
        setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
      }
    } else if (activeStep === 1) {
      if (validMonikerIds && validMonikerIds.length > 0) {
        setStepCompletionStatus((prev) => {
          const newStatus = [...prev];
          newStatus[activeStep] = true;
          return newStatus;
        });
        setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
      }
    } else {
      handleSubmit();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    // const allPreviousCompleted = stepCompletionStatus.slice(0, step).every((status) => status);
    // if (allPreviousCompleted) {
    //   setActiveStep(step);
    // }
  };

  const formatValues = (id: string) => {
    const submitData: any = Object.assign({}, formSubmitMethods.getValues());
    let dirtyFields = formSubmitMethods.formState.dirtyFields;
    let tempParams: any = {};
    for (let key in dirtyFields) {
      if (submitData[key] === '' || submitData[key] === undefined) continue;
      if (typeof submitData[key] === 'string') {
        tempParams[key] = submitData[key].trim();
      } else {
        tempParams[key] = submitData[key];
      }
      if (key === 'productionOrderSentDate' || key === 'closedAt') {
        tempParams[key] = new Date(submitData[key]).getTime();
      }
    }
    tempParams['scamEnablerId'] = id;
    return tempParams;
  };

  const formatPoValues = (id: string) => {
    const submitData: any = Object.assign({}, formSubmitMethods.getValues());
    let dirtyFields = formSubmitMethods.formState.dirtyFields;
    let tempParams: any = {};
    for (let key in dirtyFields) {
      if (submitData[key] === '' || submitData[key] === undefined) continue;
      if (typeof submitData[key] === 'string') {
        tempParams[key] = submitData[key].trim();
      } else {
        tempParams[key] = submitData[key];
      }
      if (key === 'productionOrderSentDate' || key === 'closedAt') {
        tempParams[key] = new Date(submitData[key]).getTime();
      }
    }
    tempParams['productionOrderId'] = id;
    return tempParams;
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    let updateScamEnablerRequest: any = [];
    let updateProductionOrderRequest: any = [];

    if (validMonikerIds?.length > 0) {
      validMonikerIds.forEach((item) => {
        updateScamEnablerRequest.push(formatValues(item._id));
      });
    } else if (selectedScamEnablers && selectedScamEnablers?.length > 0) {
      for (let item of selectedScamEnablers) {
        if (item.warning) {
          continue;
        }
        let tempScamEnablerRequest = formatValues(item._id);
        let tempPoRequest = formatPoValues(item.productionOrder.productionOrderId);
        if (tempScamEnablerRequest.outcome) {
          delete tempScamEnablerRequest.closedAt;
          delete tempScamEnablerRequest.platformReferenceNo;
          updateScamEnablerRequest.push(tempScamEnablerRequest);
        }
        if (tempPoRequest.platformReferenceNo || tempPoRequest.closedAt) {
          delete tempPoRequest.outcome;
          updateProductionOrderRequest.push(tempPoRequest);
        }
      }
    }

    let poResponse: any;
    if (updateProductionOrderRequest.length > 0) {
      poResponse = await updateProductionOrdersV2(updateProductionOrderRequest);
    }

    let scamEnablerResponse: any;
    if (updateScamEnablerRequest.length > 0) {
      scamEnablerResponse = await updateScamEnablerV2(updateScamEnablerRequest);
    }

    if (scamEnablerResponse?.error || poResponse?.error) {
      dispatch(
        showAlert({
          type: 'error',
          message: 'Unable to update moniker details due to network error. Please try again later.',
        }),
      );
    } else {
      dispatch(
        showAlert({
          type: 'success',
          message: 'Moniker details updated successfully.',
        }),
      );
      formSubmitMethods.reset();
      handleClose();
      if (callback) callback();
    }

    setIsLoading(false);
  };

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <div className="flex flex-col gap-2">
            <h2 className="bold">General Information</h2>
            <DynamicForm
              formFields={updateSelectScamEnablerConfig}
              formMethods={step1FormMethods}
            ></DynamicForm>
          </div>
        );
      case 1:
        return (
          <>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              TabIndicatorProps={{ style: { backgroundColor: '#255662' } }}
            >
              <Tab
                label={
                  <div className="flex items-center gap-4">
                    <p
                      className={`font-bold ${tabValue === 'valid' ? 'text-blue-500' : 'text-blue-400'}`}
                      style={{ textTransform: 'none' }}
                    >
                      Successful
                    </p>
                    <Badge color="success" badgeContent={validMonikerIds.length || 0} max={999} />
                  </div>
                }
                value="valid"
              />
              <Tab
                label={
                  <div className="flex items-center gap-4">
                    <p
                      className={`font-bold ${tabValue === 'invalid' ? 'text-blue-500' : 'text-blue-400'}`}
                      style={{ textTransform: 'none' }}
                    >
                      Error
                    </p>
                    <Badge color="error" badgeContent={invalidMonikerIds.length || 0} max={999} />
                  </div>
                }
                value="invalid"
              />
            </Tabs>
            {tabValue === 'valid' && (
              <div className="h-[40vh] overflow-y-auto">
                <Table
                  rowKey="scamEnablerUserId"
                  dataSource={cloneDeep(validMonikerIds)}
                  columns={validColumns}
                  pagination={false}
                  sticky
                />
              </div>
            )}
            {tabValue === 'invalid' && (
              <div className="h-[40vh] overflow-y-auto">
                <Table
                  rowKey="scamEnablerUserId"
                  dataSource={cloneDeep(invalidMonikerIds)}
                  columns={invalidColumns}
                  pagination={false}
                  sticky
                />
              </div>
            )}
          </>
        );

      case 2:
        return (
          <form onSubmit={handleSubmit}>
            <DynamicForm formFields={stepUpdateConfig} formMethods={formSubmitMethods} />
          </form>
        );
      default:
        return 'Unknown stepIndex';
    }
  };
  const tooltipContent = (
    <div className="flex text-black bg-white">
      <ErrorOutline className="mr-2" />
      <span>All required fields need to be filled before proceeding to the next section.</span>
    </div>
  );

  const checkIsButtonEnabled = () => {
    if (activeStep === 0) {
      return step1FormMethods.formState.isValid;
    } else if (activeStep === 1) {
      return validMonikerIds?.length > 0;
    } else if (activeStep === 2) {
      return formSubmitMethods.formState.isDirty;
    }
    return false;
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <ModalLayout
        title={'Update Details'}
        handleCloseModal={handleClose}
        alignment="center"
        size="medium-form"
        overflowClass=""
      >
        <>
          {(!selectedScamEnablers || selectedScamEnablers.length === 0) && (
            <CustomizedStepperForm
              steps={steps}
              handleStepClick={handleStep}
              activeStep={activeStep}
              stepCompletionStatus={stepCompletionStatus}
            >
              <Box sx={{ mb: 2 }}>
                <div className="flex-1 overflow-auto pb-10">{getStepContent(activeStep)}</div>
                <div className="pt-4 flex justify-end sticky gap-5 bottom-0 pb-4 bg-white">
                  <CommonButton variant="text" title="Cancel" onClick={handleClose} type="button" />
                  {activeStep !== 0 && (
                    <CommonButton
                      onClick={(e: any) => {
                        e.preventDefault();
                        handleBack();
                      }}
                      type="button"
                      variant="secondary"
                      title="Previous"
                    />
                  )}

                  <Tooltip
                    title={tooltipContent}
                    disableHoverListener={!isButtonDisabled}
                    placement="bottom-end"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: 'common.white',
                          fontSize: '12px',
                        },
                      },
                    }}
                  >
                    <span>
                      <CommonButton
                        variant="primary"
                        title={activeStep !== steps.length - 1 ? 'Next' : 'Submit'}
                        type="submit"
                        isDisabled={!checkIsButtonEnabled()}
                        onClick={handleNext}
                        isLoading={isLoading}
                      />
                    </span>
                  </Tooltip>
                </div>
              </Box>
            </CustomizedStepperForm>
          )}
          {showWarning && (
            <div className="-mt-10">
              <p className="text-grey-400 text-sm italic mt-2">
                Date of Reply and Platform Reference Number will be updated to the latest PO.
              </p>
              <div className="bg-yellow-200 border-2 border-yellow p-4 mt-2 rounded-md">
                <div className="flex items-start">
                  <WarningYellowIcon />
                  <div className="ml-3 text-xs">
                    <p className="text-xs">
                      You are receiving this alert because one or more of the following conditions
                      apply:
                    </p>
                    <ul className="mt-1 list-disc list-outside pl-5">
                      <li>
                        Some monikers not in the <span className="font-semibold">“PO Issued”</span>{' '}
                        status
                        <span className="text-gray-500 italic">
                          &nbsp;- Bulk updates to an end status are only allowed for monikers in "PO
                          Issued" status
                        </span>
                      </li>
                      <li>
                        Some monikers already have the{' '}
                        <span className="font-semibold">Date of Reply</span> filled for the
                        Production Order
                      </li>
                      <li>
                        Some monikers do not have a{' '}
                        <span className="font-semibold">Production Order</span> created
                      </li>
                      <li>
                        Some monikers have{' '}
                        <span className="font-semibold">multiple Production Orders</span> with an
                        empty <span className="font-semibold">Date of Reply</span>
                        <span className="text-gray-500 italic">
                          &nbsp;- Update these monikers individually in their respective details
                          page
                        </span>
                      </li>
                    </ul>
                    <div className="flex">
                      <p className="mt-5 text-xs">
                        Changes will <span className="font-bold">NOT</span> be applied to the
                        monikers that meet any of the above conditions.
                      </p>
                      <a
                        href="#selectedScamEnablers"
                        className="mt-5 text-blue-500 underline ml-auto"
                        onClick={toggleExpansion}
                      >
                        View list of monikers
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedScamEnablers && selectedScamEnablers.length > 0 && (
            <div className="flex flex-col mt-4">
              <form>
                <DynamicForm formFields={updateConfig} formMethods={formSubmitMethods} />
              </form>
              {showSelectedScamEnablers && (
                <>
                  <div id="selectedScamEnablers" className="flex flex-row gap-4 items-center">
                    <h5 className="font-h4-bold-14">{`Selected monikers: ${selectedScamEnablers.length}`}</h5>
                    <button
                      className="py-2 px-4 text-white font-bold rounded-lg my-4 flex items-center justify-center"
                      onClick={toggleExpansion}
                    >
                      {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </button>
                  </div>
                  {isExpanded && (
                    <div className="overflow-auto bg-grey-200">
                      <div
                        className="grid grid-cols-6 py-4 gap-6 px-2 rounded-lg"
                        style={{ gridTemplateColumns: '18% 18% 15% 5% 20% 5%' }}
                      >
                        <p className="text-xs font-bold">Moniker ID</p>
                        <p className="text-xs font-bold">Moniker Status</p>
                        <p className="text-xs font-bold">Date of PO Sent</p>
                        <p className="text-xs font-bold">Channel</p>
                        <p className="text-xs font-bold">PO Reference No.</p>
                        <p className="text-xs font-bold"></p>
                      </div>
                      {selectedScamEnablers.map((scamEnabler, index) => {
                        let productionOrder = scamEnabler.productionOrder;
                        let channel = productionOrder?.directionReferenceNo
                          ? 'OCHA'
                          : productionOrder?.productionOrderId
                            ? 'CGC'
                            : '-';

                        return (
                          <div
                            key={index}
                            className="grid grid-cols-6 p-2 zebra-stripe gap-6 items-center"
                            style={{ gridTemplateColumns: '18% 18% 15% 5% 20% 5%' }}
                          >
                            <p className="text-xs">{scamEnabler.scamEnablerUserId}</p>
                            <MonikerOutcomeStatus outcome={scamEnabler.outcome} />
                            <p className="text-xs">
                              {productionOrder?.createdAt
                                ? convertMsToDDMMYYYYHHmm(productionOrder?.createdAt)
                                : '-'}
                            </p>
                            <p
                              className={`text-xs  ${channel === 'OCHA' ? 'font-bold text-pink' : channel === 'CGC' ? 'font-bold text-green' : ''}`}
                            >
                              {channel}
                            </p>
                            <p className="text-xs">
                              {productionOrder?.directionReferenceNo ||
                                'PO#' + productionOrder?.productionOrderId}
                            </p>
                            <p className="text-xs">
                              {scamEnabler.warning && <WarningYellowIcon />}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </>
              )}
              <div className="pt-4 flex justify-end  gap-5 bottom-0 pb-4 bg-white">
                <CommonButton variant="text" title="Cancel" onClick={handleClose} type="button" />
                <CommonButton
                  isLoading={isLoading}
                  variant="primary"
                  title="Submit"
                  isDisabled={!formSubmitMethods.formState.isDirty}
                  onClick={handleSubmit}
                  type="submit"
                />
              </div>
            </div>
          )}
        </>
      </ModalLayout>
    </Modal>
  );
};

export default UpdateMonikerModal;
