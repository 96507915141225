export const DEFAULT_LOGGED_IN_PAGE: any = {
  default: '/admin/portfolios/monikers',
  'portfolios.proactivescamenablers.view': '/admin/portfolios/proactive',
  'portfolios.banks.view': '/admin/portfolios/banks',
  'portfolios.paynows.view': '/admin/portfolios/banks',
  pid: '/admin/portfolios/proactive',
};

export const TEAMS: any = [
  { id: 'superAdmin', label: 'Super Admin' },
  { id: 'asc', label: 'ASC' },
  { id: 'pid', label: 'PID' },
  { id: 'dao', label: 'DA Office' },
];

export const CHECKLIST_VERSION = '1.1';
