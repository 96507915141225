import { apiSlice } from './apiSlice';

export const paynowSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllPaynowEntries: builder.query({
      query: (request) => {
        return {
          url: '/v2/paynows',
          params: {
            page: request.params.page,
            size: request.params.size,
            search: request.params.search,
            sort_by: request.params.sort_by,
            order_by: request.params.order_by,
            outcome: request.params.filters?.outcome,
            created_date_from: request.params.filters?.created_date_from,
            created_date_to: request.params.filters?.created_date_to,
            // scam_type: request.params.filters?.scam_type,
            // da1: request.params.filters?.da1,
            // da2: request.params.filters?.da2,
          },
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
    getPaynowById: builder.query({
      query: (params) => {
        return {
          url: `/v2/paynow/${params.id}`,
        };
      },
      transformResponse: (response: any) => {
        console.log('response', response);
        return response;
      },
    }),

    updatePaynow: builder.mutation({
      query: (params) => {
        return {
          method: 'PUT',
          body: [params],
          url: `v2/paynows`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),

    updatePaynows: builder.mutation({
      query: (params) => {
        return {
          method: 'PUT',
          body: params,
          url: `v2/paynows`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),

    updatePaynowTransactions: builder.mutation({
      query: (params) => {
        return {
          method: 'PUT',
          body: params,
          url: `v2/paynow/transactions`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),

    deletePaynow: builder.mutation({
      query: (params) => {
        return {
          method: 'DELETE',
          body: params,
          url: `v2/paynow`,
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),

    assignUserToPaynow: builder.mutation({
      query: (params) => {
        return {
          method: 'PUT',
          body: params,
          url: 'v2/paynow/assign',
        };
      },
      transformResponse: (response: any) => {
        return response;
      },
    }),
  }),
});

export const {
  useGetAllPaynowEntriesQuery,
  useGetPaynowByIdQuery,
  useUpdatePaynowMutation,
  useUpdatePaynowTransactionsMutation,
  useUpdatePaynowsMutation,
  useDeletePaynowMutation,
  useAssignUserToPaynowMutation,
} = paynowSlice;
