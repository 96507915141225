import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import AssessmentFindingsMonikerPresubmitChecklist1 from './AssessmentFindingsMonikerPresubmitChecklist1';
import AssessmentFindingsMonikerPresubmitChecklist2 from './AssessmentFindingsMonikerPresubmitChecklist2';
import cloneDeep from 'lodash.clonedeep';
import CommunityGuidelineConfirmationModal from '../../modals/CommunityGuidelineConfirmationModal';
import { CommonButton, IconButton } from '@components/common/CommonButton';
import { GenerateProductionOrderButton } from '@pages/portfolios/Buttons';
import { useMonikersDetailedPage } from '../useMonikersDetailedPage';
import { convertValues } from '@utils/utils';
import ReassignmentConfirmationModal from '../../modals/ReassignmentConfirmationModal';
import { CHECKLIST_VERSION } from '@constants/index';
import {
  useGetScamEnablerOmsDuplicateCheckV2Mutation,
  useUpdateAssessmentFindingsV2Mutation,
} from '@api/scamEnablerSlice';
import { showAlert } from '@store/alertSlice';
import { useDispatch } from 'react-redux';
import CustomBadge from '@components/common/CustomBadge';
import { DownloadProductionOrderSingleButton } from '@pages/reports/Buttons';
import CancellationConfirmationModal from '../../modals/CancellationConfirmationModal';
import { useUpdateProductionOrdersV2Mutation } from '@api/productionOrderSlice';
import { ReactComponent as Warning } from '@assets/icons/warning.svg';
import AssignModal from '../../modals/AssignModal';
import { getConfig } from './AssessmentFindingsConfig';
import OmsDuplicateCheckModal from '../../modals/OmsDuplicateCheckStatusModal';

const AssessmentFindingsDetailsComponent = ({
  data,
  callback,
  currentRole,
  currentUser,
  isLoading,
  productionOrder,
  assessmentFindingsIndex,
  onReturn,
}: {
  data?: any;
  callback?: any;
  currentRole: string;
  currentUser: any;
  isLoading: boolean;
  scamEnablerId: string | undefined;
  productionOrder?: any;
  assessmentFindingsIndex?: number;
  onReturn: () => void;
}) => {
  const [tabValue, setTabValue] = useState(0);

  const [showOcha, setShowOcha] = useState(true);
  const [updateAssessmentFindingsV2] = useUpdateAssessmentFindingsV2Mutation();
  const [updateProductionOrdersV2] = useUpdateProductionOrdersV2Mutation();
  const [omsDuplicateCheckV2] = useGetScamEnablerOmsDuplicateCheckV2Mutation();

  const [isAssessmentFindingsAPILoading, setIsAssessmentFindingsAPILoading] = useState(false);
  const [showCommunityGuidelineConfirmationModal, setShowCommunityGuidelineConfirmationModal] =
    useState(false);
  const [isDA2Assigned, setIsDA2Assigned] = useState(false);
  const [isDA1FormEditing, setIsDA1FormEditing] = useState(false);
  const [isDA2FormEditing, setIsDA2FormEditing] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [isReassign, setIsReassign] = useState(false);
  const [isReferenceNoValidState, setIsReferenceNoValidState] = useState<boolean | null>(null);

  const formMethodsDa1 = useForm({
    mode: 'onChange',
  });
  const formMethodsDa2 = useForm({
    mode: 'onChange',
  });
  const [version, setVersion] = useState(
    (productionOrder?.scamEnablers &&
      productionOrder?.scamEnablers[0]?.findings?.monikerPreSubmitChecklist1?.version) ||
      data?.scamEnabler?.findings?.monikerPreSubmitChecklist1?.version ||
      CHECKLIST_VERSION,
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancellaionModalOpen, setIsCancellaionModalOpen] = useState(false);

  const [isSendPOQueueLoading, setIsSendPOQueueLoading] = useState(false);

  const [isDisabledPO, setIsDisabledPO] = useState(true);
  const [isDisabledQueue, setIsDisabledQueue] = useState(true);
  const [assignOpen, setAssignOpen] = useState(false);

  const [duplicateCheckStatus, setDuplicateCheckStatus] = useState<any>([]);
  const [isDuplicateCheckModelOpen, setIsDuplicateCheckModelOpen] = useState(false);

  const legalRequirementsQ1 = formMethodsDa2.watch('checklist2_legalRequirements_Q1');
  const operationalRequirementsQ1 = formMethodsDa2.watch('checklist2_operationalRequirements_Q1');
  const operationalRequirementsQ2 = formMethodsDa2.watch('checklist2_operationalRequirements_Q2');
  const communityQ1 = formMethodsDa2.watch('checklist2_community_Q1');
  const channel = formMethodsDa1.watch('channel');

  let { refetch, sendProductionOrderQueue } = useMonikersDetailedPage();

  const dispatch = useDispatch();

  const [showRequiredFieldsMessage, setShowRequiredFieldsMessage] = useState(false);

  const handleMouseEnter = () => {
    setShowRequiredFieldsMessage(true);
  };

  const handleMouseLeave = () => {
    setShowRequiredFieldsMessage(false);
  };

  useEffect(() => {
    if (data?.scamEnabler?.outcome === 'Pending Decision') {
      setTabValue(1);
    }
    if (
      (data?.scamEnabler?.outcome === 'Under Review' &&
        data?.scamEnabler?.findings?.monikerPreSubmitChecklist2) ||
      data?.scamEnabler?.findings?.reassigned
    ) {
      setIsReassign(true);
    } else {
      setIsReassign(false);
    }

    let checklist1: any;
    let checklist2: any;
    let channel;

    setShowEdit(true);
    if (productionOrder?.scamEnablers) {
      setShowEdit(false);
      if (productionOrder?.scamEnablers[0]?.findings?.monikerPreSubmitChecklist1?.checklist) {
        checklist1 = productionOrder.scamEnablers[0].findings.monikerPreSubmitChecklist1.checklist;
        channel =
          productionOrder?.scamEnablers[0].findings.monikerPreSubmitChecklist1.checklist.channel;
        if (channel !== 'OCHA') {
          setShowOcha(false);
        } else {
          setShowOcha(true);
        }
      }
      if (productionOrder?.scamEnablers[0]?.findings?.monikerPreSubmitChecklist2?.checklist) {
        checklist2 = productionOrder.scamEnablers[0].findings.monikerPreSubmitChecklist2.checklist;
      }
    }

    if (!checklist1 && data?.scamEnabler?.findings?.monikerPreSubmitChecklist1?.checklist) {
      checklist1 = data.scamEnabler.findings.monikerPreSubmitChecklist1.checklist;
      channel = data.scamEnabler.findings.monikerPreSubmitChecklist1.checklist.channel;
      if (channel !== 'OCHA') {
        setShowOcha(false);
      } else {
        setShowOcha(true);
      }
    }

    if (!checklist2 && data?.scamEnabler?.findings?.monikerPreSubmitChecklist2?.checklist) {
      checklist2 = data.scamEnabler.findings.monikerPreSubmitChecklist2.checklist;
    }

    if (data?.scamEnabler?.assignment) {
      let isAssignedDA2 = data.scamEnabler.assignment.find(
        (assignment: any) => assignment.role === 'da2',
      );
      if (isAssignedDA2) {
        setIsDA2Assigned(true);
      } else {
        setIsDA2Assigned(false);
      }
    }

    if (checklist1) {
      for (let key in checklist1) {
        let value = checklist1[key];
        formMethodsDa1.setValue(key, value, { shouldValidate: true });
      }
    } else {
      formMethodsDa1.setValue('channel', 'OCHA');
      if (currentRole === 'da1' && !productionOrder?.productionOrderId) {
        setIsDA1FormEditing(true);
      }
      setShowOcha(true);
    }

    if (checklist2) {
      for (let key in checklist2) {
        let value = checklist2[key];
        formMethodsDa2.setValue(key, value, { shouldValidate: true });
      }
    }
    if (
      currentRole === 'da2' &&
      data?.scamEnabler?.outcome === 'Pending Decision' &&
      !productionOrder?.productionOrderId
    ) {
      setIsDA2FormEditing(true);
    }

    isDisabledPOButton();
    isDisabledQueueButton();
  }, [data, formMethodsDa1, formMethodsDa2, currentRole, productionOrder?.productionOrderId]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const onReject = async (event: any) => {
    event.preventDefault();
    setIsModalOpen(true);
  };

  const onSubmitPOQueue = async (event: any) => {
    event.preventDefault();
    setIsSendPOQueueLoading(true);
    setIsAssessmentFindingsAPILoading(true);
    await saveAssessmentFindings();
    setIsAssessmentFindingsAPILoading(false);

    // if (
    //   data.scamEnabler?.duplicateCheckStatus &&
    //   data.scamEnabler?.duplicateCheckStatus?.length > 0
    // ) {
    //   setDuplicateCheckStatus(data.scamEnabler?.duplicateCheckStatus);
    //   setIsDuplicateCheckModelOpen(true);
    //   setIsSendPOQueueLoading(false);
    // } else {
    //   let response: any = await omsDuplicateCheckV2({
    //     userId: data.scamEnabler?.scamEnablerUserId,
    //     platform: data.scamEnabler?.platform,
    //   });

    //   if (response?.error) {
    //     let error = JSON.parse(response.error.data);
    //     dispatch(
    //       showAlert({
    //         type: 'error',
    //         message: error.message || 'Duplicate Check API error.',
    //       }),
    //     );
    //   }

    //   let duplicateCheckStatus: any = response?.data;
    //   if (duplicateCheckStatus?.length > 0) {
    //     setDuplicateCheckStatus(duplicateCheckStatus);
    //     setIsDuplicateCheckModelOpen(true);
    //     setIsSendPOQueueLoading(false);
    //   } else {
    //     await sendProductionOrderQueue([data.scamEnabler._id], 'monikerView');
    //   }
    // }
    await sendProductionOrderQueue([data.scamEnabler._id], 'monikerView');

    if (callback) callback();
    setIsDA1FormEditing(false);
    setIsSendPOQueueLoading(false);
  };

  const saveAssessmentFindings = async () => {
    let formResultsDa2 = convertValues(formMethodsDa2.getValues());
    let formResultsDa1 = convertValues(formMethodsDa1.getValues());

    if (
      currentUser?.permissions.includes('customs.sao') &&
      formResultsDa1.channel == 'OCHA' &&
      !formResultsDa2.checklist2_legalRequirements_Q1 &&
      !formResultsDa2.checklist2_operationalRequirements_Q1 &&
      !formResultsDa2.checklist2_operationalRequirements_Q2
    ) {
      formResultsDa2.checklist2_legalRequirements_Q1 = true;
      formResultsDa2.checklist2_operationalRequirements_Q1 = true;
      formResultsDa2.checklist2_operationalRequirements_Q2 = true;
      formResultsDa2.checklist2_checks_Q1 = true;
    }

    setIsAssessmentFindingsAPILoading(true);
    let params: any = {
      scamEnablerId: data.scamEnabler._id,
      monikerPreSubmitChecklist2: {
        version: version,
        checklist: formResultsDa2,
      },
    };

    if (currentRole == 'da1') {
      params.monikerPreSubmitChecklist1 = {
        version: version,
        checklist: formResultsDa1,
      };
    }

    let response: any = await updateAssessmentFindingsV2(params);
    if (response && !response.error) {
      dispatch(
        showAlert({
          type: 'success',
          message: `Update Successful`,
        }),
      );
    }
    setIsDA1FormEditing(false);
    if (callback) callback();
    setIsAssessmentFindingsAPILoading(false);
    return formResultsDa1.channel;
  };

  const handleReassignment = async () => {
    let formResults = convertValues(formMethodsDa2.getValues());

    setIsAssessmentFindingsAPILoading(true);
    let params = {
      scamEnablerId: data.scamEnabler._id,
      monikerPreSubmitChecklist2: {
        version: version,
        checklist: formResults,
      },

      reassign: true,
    };
    let response: any = await updateAssessmentFindingsV2(params);
    if (response && !response.error) {
      dispatch(
        showAlert({
          type: 'success',
          message: `Update Successful`,
        }),
      );
    }
    setIsDA1FormEditing(false);
    setIsModalOpen(false);
    if (callback) callback();
    setIsAssessmentFindingsAPILoading(false);
  };

  useEffect(() => {
    isDisabledPOButton();
    isDisabledQueueButton();
  }, [
    formMethodsDa1.formState.isValid,
    legalRequirementsQ1,
    operationalRequirementsQ1,
    operationalRequirementsQ2,
    communityQ1,
    isReferenceNoValidState,
    channel,
  ]);

  const showPOButton = () => {
    let formResultsDa1 = convertValues(formMethodsDa1.getValues());
    let showButtonChecklist1: boolean = false;
    let showButtonChecklist2: boolean = false;

    if (data && currentRole && (currentRole === 'da1' || currentRole === 'da2')) {
      if (
        currentRole === 'da1' &&
        (currentUser?.permissions.includes('customs.sao') ||
          formResultsDa1?.channel === 'Community Guideline')
      ) {
        showButtonChecklist1 = true;
      }
      if (currentRole === 'da2') {
        showButtonChecklist2 = true;
      }
    }

    if (productionOrder?.productionOrderId) {
      showButtonChecklist1 = false;
      showButtonChecklist2 = false;
    }

    return showButtonChecklist1 || showButtonChecklist2;
  };

  //   SAO + DA1 + "Under Review" -> Generate PO
  // DA2 + "Pending Decision" -> Generate PO

  // SAO + DA1 -> Remove save and assign button

  // "PO Required" -> Cannot do anything except generate PO

  const isDisabledQueueButton = () => {
    let formResultsDa2 = convertValues(formMethodsDa2.getValues());
    let formResultsDa1 = convertValues(formMethodsDa1.getValues());
    if (
      (currentRole == 'da2' &&
        currentUser?.permissions.includes('customs.sao') &&
        data?.scamEnabler?.outcome == 'Pending Decision' &&
        formResultsDa2?.checklist2_operationalRequirements_Q1 &&
        formResultsDa2?.checklist2_operationalRequirements_Q2 &&
        formResultsDa2?.checklist2_legalRequirements_Q1 &&
        isReferenceNoValidState !== false) ||
      (currentRole == 'da1' &&
        currentUser?.permissions.includes('customs.sao') &&
        formMethodsDa1.formState.isValid &&
        isReferenceNoValidState !== false &&
        !productionOrder?.productionOrderId) ||
      ((currentRole == 'da1' || currentRole == 'da2') &&
        formResultsDa1?.channel == 'Community Guideline')
    ) {
      setIsDisabledQueue(false);
    } else {
      setIsDisabledQueue(true);
    }

    if (data?.scamEnabler?.outcome === 'PO Required') {
      setIsDisabledQueue(true);
    }
  };

  const isDisabledPOButton = () => {
    let formResultsDa2 = convertValues(formMethodsDa2.getValues());
    let formResultsDa1 = convertValues(formMethodsDa1.getValues());

    if (
      (currentRole == 'da2' &&
        currentUser?.permissions.includes('customs.sao') &&
        data?.scamEnabler?.outcome == 'Pending Decision' &&
        formResultsDa2?.checklist2_operationalRequirements_Q1 &&
        formResultsDa2?.checklist2_operationalRequirements_Q2 &&
        formResultsDa2?.checklist2_legalRequirements_Q1 &&
        isReferenceNoValidState !== false) ||
      (currentRole == 'da1' &&
        currentUser?.permissions.includes('customs.sao') &&
        formMethodsDa1.formState.isValid &&
        isReferenceNoValidState !== false &&
        !productionOrder?.productionOrderId) ||
      ((currentRole == 'da1' || currentRole == 'da2') &&
        formResultsDa1?.channel == 'Community Guideline')
    ) {
      setIsDisabledPO(false);
    } else {
      setIsDisabledPO(true);
    }
  };

  const handlePOCancellation = async (status: string) => {
    setIsAssessmentFindingsAPILoading(true);
    let params = [
      {
        scamEnablerId: data.scamEnabler._id,
        productionOrderId: productionOrder.productionOrderId,
        status,
      },
    ];

    let response: any = await updateProductionOrdersV2(params);
    if (response && !response.error) {
      dispatch(
        showAlert({
          type: 'success',
          message: `PO#${productionOrder?.directionReferenceNo || productionOrder?.productionOrderId} is ${status} and updated to ${status} successfully.`,
        }),
      );
    } else {
      dispatch(
        showAlert({
          type: 'error',
          message: `Unable to cancel PO#${productionOrder?.directionReferenceNo || productionOrder?.productionOrderId} due to network error. Please try again later.`,
        }),
      );
    }
    setIsDA1FormEditing(false);
    setIsCancellaionModalOpen(false);
    setIsAssessmentFindingsAPILoading(false);
    if (callback) callback();
    onReturn();
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();
    let formResults = formMethodsDa1.getValues();
    setIsAssessmentFindingsAPILoading(true);

    if (!showOcha) {
      getConfig(version)?.assessmentFindingsDa1PartItems.forEach((sections) => {
        sections.extras.checkboxData.forEach((element: any) => {
          delete formResults[element.id];
        });
      });
    }
    let params = {
      scamEnablerId: data.scamEnabler._id,
      monikerPreSubmitChecklist1: {
        version: version,
        checklist: formResults,
      },
    };
    let response: any = await updateAssessmentFindingsV2(params);
    if (response && !response.error) {
      callback();
      if (isDA2Assigned) {
        onReturn();
      } else {
        setAssignOpen(true);
      }

      dispatch(
        showAlert({
          type: 'success',
          message: `Update Successful`,
        }),
      );
      setIsDA1FormEditing(false);
    }
    setIsAssessmentFindingsAPILoading(false);
  };

  return (
    <div className="box-border flex-row bg-grey-100 h-full rounded-lg p-6">
      <div onClick={onReturn} className="flex align-middle items-center cursor-pointer pb-4">
        <IconButton icon="left_arrow_with_line" type="button" isDisabled={false} />
        <span className="text-blue-400 font-bold pl-1">Back to Assessment Findings</span>
      </div>
      <div className="flex flex-row align-middle items-center gap-3 pb-4">
        <h1 className="font-bold text-lg text-blue-500 ">Assessment Findings</h1>
        {<CustomBadge text={showOcha ? `OCHA` : `Community Guideline`} />}

        {!productionOrder?.status &&
          (productionOrder?.directionReferenceNo || productionOrder?.productionOrderId) && (
            <div className="ml-auto">
              <CommonButton
                variant="warning"
                title={'Cancel PO'}
                onClick={() => {
                  setIsCancellaionModalOpen(true);
                }}
              />
            </div>
          )}
        {productionOrder?.status && (
          <div className="flex justify align-middle text-pink font-bold text-sm ml-auto">
            {productionOrder?.status}
          </div>
        )}
        {!productionOrder?.productionOrderId && data?.scamEnabler?.outcome === 'PO Required' && (
          <div className="flex justify align-middle text-orange font-bold text-sm ml-auto">
            IN QUEUE
          </div>
        )}
        {!productionOrder?.productionOrderId && data?.scamEnabler?.outcome !== 'PO Required' && (
          <div className="flex justify align-middle items-center text-red font-bold text-sm ml-auto">
            PENDING
          </div>
        )}
      </div>

      {(productionOrder?.directionReferenceNo || productionOrder?.productionOrderId) && (
        <div className="flex flex-row gap-4 align-middle items-center pb-4">
          <h1 className="font-bold text-lg text-blue-500">
            PO#{productionOrder.directionReferenceNo || productionOrder.productionOrderId}
          </h1>
          <DownloadProductionOrderSingleButton
            productionOrderId={productionOrder.productionOrderId}
          />
        </div>
      )}

      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        sx={{
          '.MuiTabs-flexContainer': {
            justifyContent: 'space-around',
            flexWrap: 'wrap',
          },
          '& .MuiTab-root': {
            color: 'var(--blue-400)',
            flex: 1,
            wordWrap: 'break-word',
          },
          '& .MuiTab-root.Mui-selected': {
            color: 'var(--blue-500)',
          },
          '.MuiTabs-indicator': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <Tab
          label="Assessment by DA1"
          sx={{
            textTransform: 'none',
            fontWeight: 700,
            backgroundColor: tabValue === 0 ? 'var(--blue-200)' : 'transparent',
            borderRadius: '5px',
          }}
        />
        <Tab
          label="Decision by DA2"
          sx={{
            textTransform: 'none',
            fontWeight: 700,
            backgroundColor: tabValue === 1 ? 'var(--blue-200)' : 'transparent',
            borderRadius: '5px',
          }}
        />
      </Tabs>
      <div className="mt-4">
        {tabValue === 0 && (
          <AssessmentFindingsMonikerPresubmitChecklist1
            formMethods={formMethodsDa1}
            isReassigned={isReassign}
            showOcha={showOcha}
            setShowOcha={setShowOcha}
            data={cloneDeep(data)}
            currentUser={currentUser}
            currentRole={currentRole}
            callback={callback}
            isDA2Assigned={isDA2Assigned}
            isEditing={isDA1FormEditing}
            setIsEditing={setIsDA1FormEditing}
            isLoading={isLoading}
            showEdit={showEdit}
            productionOrder={productionOrder}
            onReturn={onReturn}
            showRequiredFieldsMessage={showRequiredFieldsMessage}
            setIsReferenceNoValidState={setIsReferenceNoValidState}
          />
        )}
        {tabValue === 1 && (
          <AssessmentFindingsMonikerPresubmitChecklist2
            formMethods={formMethodsDa2}
            data={cloneDeep(data)}
            showOcha={showOcha}
            currentRole={currentRole}
            currentUser={currentUser}
            callback={callback}
            isDA2Assigned={isDA2Assigned}
            isEditing={isDA2FormEditing}
            setIsEditing={setIsDA2FormEditing}
            isLoading={isLoading}
            showEdit={showEdit}
            productionOrder={productionOrder}
            onReturn={onReturn}
            showRequiredFieldsMessage={showRequiredFieldsMessage}
          />
        )}
        <div className="flex flex-row justify-end gap-2 pt-4">
          {isDA1FormEditing &&
            tabValue === 0 &&
            currentRole === 'da1' &&
            !currentUser?.permissions.includes('customs.sao') &&
            showOcha && (
              <CommonButton
                isDisabled={!formMethodsDa1.formState.isValid || isReferenceNoValidState == false}
                variant="primary"
                title={isDA2Assigned ? 'Save' : 'Save & Assign DA2'}
                isLoading={isAssessmentFindingsAPILoading}
                onClick={(e: any) => {
                  onSubmit(e);
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
            )}
          {tabValue === 0 &&
            currentRole === 'da1' &&
            !isDA1FormEditing &&
            data?.scamEnabler?.outcome === 'Under Review' &&
            showEdit && (
              <CommonButton
                variant="secondary"
                title={'Edit'}
                onClick={() => setIsDA1FormEditing(true)}
              />
            )}
          {isDA2FormEditing &&
            tabValue === 1 &&
            currentRole === 'da2' &&
            currentUser?.permissions.includes('customs.sao') &&
            showOcha && (
              <CommonButton
                isDisabled={!formMethodsDa2.formState.isValid}
                variant="primary"
                title="Save"
                isLoading={isAssessmentFindingsAPILoading}
                onClick={(e: any) => {
                  onSubmit(e);
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
            )}
          {tabValue === 1 &&
            currentRole === 'da2' &&
            currentUser?.permissions.includes('customs.sao') &&
            !isDA2FormEditing &&
            data?.scamEnabler?.outcome === 'Pending Decision' &&
            showEdit && (
              <CommonButton
                variant="secondary"
                title={'Edit'}
                onClick={() => setIsDA2FormEditing(true)}
              />
            )}
          {tabValue === 1 &&
            currentRole &&
            currentRole === 'da2' &&
            !productionOrder?.productionOrderId && (
              <CommonButton
                variant="warning"
                title={'Reject'}
                onClick={(e: any) => {
                  onReject(e);
                }}
              />
            )}
          {showPOButton() && (
            <>
              <CommonButton
                variant="secondary"
                title={`Save & Send to Queue`}
                onClick={(e: any) => {
                  onSubmitPOQueue(e);
                }}
                isLoading={isSendPOQueueLoading}
                isDisabled={isDisabledQueue || isSendPOQueueLoading}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />

              <GenerateProductionOrderButton
                scamEnablerData={[data.scamEnabler]}
                callback={() => {
                  onReturn();
                  refetch();
                }}
                type="text"
                isDisabled={isDisabledPO}
                preCallback={saveAssessmentFindings}
                setShowErrorMessage={setShowRequiredFieldsMessage}
              />
            </>
          )}
        </div>
      </div>
      <CommunityGuidelineConfirmationModal
        isOpen={showCommunityGuidelineConfirmationModal}
        onClose={() => {
          setShowCommunityGuidelineConfirmationModal(false);
        }}
      />
      <ReassignmentConfirmationModal
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        handleSubmit={handleReassignment}
        isLoading={isAssessmentFindingsAPILoading}
      />
      <CancellationConfirmationModal
        isOpen={isCancellaionModalOpen}
        onClose={() => {
          setIsCancellaionModalOpen(false);
        }}
        handleSubmit={handlePOCancellation}
        channel={
          productionOrder?.scamEnablers &&
          productionOrder?.scamEnablers[0].findings.monikerPreSubmitChecklist1.checklist.channel
        }
      />

      {data && assignOpen && (
        <AssignModal
          selectedScamEnablers={[data.scamEnabler]}
          showSelectedScamEnablers={false}
          assignOpen={assignOpen}
          onAssignClose={() => {
            setAssignOpen(false);
            onReturn();
          }}
          callback={callback}
          isDA2Assign={true}
        />
      )}
      {duplicateCheckStatus && (
        <OmsDuplicateCheckModal
          isOpen={isDuplicateCheckModelOpen}
          duplicateCheckStatus={duplicateCheckStatus}
          onClose={() => {
            setIsDuplicateCheckModelOpen(false);
          }}
          isSendQueue={true}
          handleClick={async () => {
            await sendProductionOrderQueue([data.scamEnabler._id], 'monikerView');
            if (callback) callback();
            setIsDuplicateCheckModelOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default AssessmentFindingsDetailsComponent;
