import { Modal } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ModalLayout from '@components/layouts/ModalLayout';
import { CommonButton, LoadingButtonComponent } from '@components/common/CommonButton';
import { useUpdateProductionOrdersV2Mutation } from '@api/productionOrderSlice';
import DynamicForm from '@components/common/DynamicForm';
import { useDispatch } from 'react-redux';
import { showAlert } from '@store/alertSlice';
import { convertDateToUnixTimestamp, convertUnixToDynamicFormDateTime } from '@utils/utils';

const UpdateProductionOrderModal = ({
  productionOrderId,
  platformReferenceNo,
  closedAt,
  editPlatformReferenceNo = true,
  isOpen,
  onClose,
  callback,
}: {
  productionOrderId: string;
  platformReferenceNo: string;
  closedAt: number;
  editPlatformReferenceNo?: boolean;
  isOpen: boolean;
  onClose: () => void;
  callback: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [updateProductionOrdersV2] = useUpdateProductionOrdersV2Mutation();

  const formMethods = useForm({
    defaultValues: { platformReferenceNo, closedAt: convertUnixToDynamicFormDateTime(closedAt) },
  });
  const dispatch = useDispatch();

  useEffect(() => {
    formMethods.reset({
      platformReferenceNo,
      closedAt: convertUnixToDynamicFormDateTime(closedAt),
    }); // This will reset the form with new default values
  }, [formMethods, platformReferenceNo, closedAt]);

  const updateProductionConfig = [
    ...(editPlatformReferenceNo ? [
      {
        label: 'Platform Reference No',
        type: 'textInput',
        id: 'platformReferenceNo',
        alignment: 'column',
      }
    ] : []),
    {
      label: 'Date of Reply',
      type: 'dateTime',
      id: 'closedAt',
      alignment: 'column',
      extras: {
        removePopupContainer: true,
      },
    },
  ];

  const handleClick = async () => {
    setIsLoading(true);
    const data = formMethods.getValues();
    const { platformReferenceNo, closedAt } = data;

    let params = [];
    let obj: any = { productionOrderId: productionOrderId };
    if (platformReferenceNo) obj['platformReferenceNo'] = platformReferenceNo;
    if (closedAt) obj['closedAt'] = convertDateToUnixTimestamp(closedAt, 'YYYY-MM-DDTHH:mm');
    params.push(obj);
    let response: any = await updateProductionOrdersV2(params);
    if (response && !response.error) {
      dispatch(
        showAlert({
          type: 'success',
          message: `Platform Reference No./Date of Reply for PO updated successfully.`,
        }),
      );
    } else {
      dispatch(
        showAlert({
          type: 'error',
          message: `Unable to update Production Order due to network error. Please try again later.`,
        }),
      );
    }
    onClose();
    setIsLoading(false);
    if (callback) callback();
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <ModalLayout title={'Edit'} handleCloseModal={onClose} alignment="center" size="small">
          <div>
            <form>
              <DynamicForm formFields={updateProductionConfig} formMethods={formMethods} />
            </form>
            <div className="flex justify-end gap-4 pt-4">
              <CommonButton isDisabled={false} variant="text" title={`Cancel`} onClick={onClose} />
              <CommonButton
                isLoading={isLoading}
                variant="primary"
                title="Save"
                onClick={handleClick}
              />
            </div>
          </div>
        </ModalLayout>
      </Modal>
    </>
  );
};

export default UpdateProductionOrderModal;
