import { useDownloadProductionOrders } from '@api/productionOrderSlice';
import { DownloadProductionOrderButton } from '@pages/reports/Buttons';
import { convertUnixToSingaporeTime } from '@utils/utils';
import { transformData } from '@pages/portfolios/banks/BanksDetailedPage/BankDetailedOverview';
import { useEffect, useState } from 'react';
import UpdateProductionOrderModal from '@pages/portfolios/monikers/modals/UpdateProductionOrderModal';

export function ProductionOrdersIssued({
  data,
  refetchCallback
}: {
  data: Array<{
    productionOrderId: string;
    poReferenceNo: string;
    createdAt: number;
    createdBy: any;
    closedAt: number;
    returnTATAccountStatus: string;
  }>;
    refetchCallback?: () => void;
}) {

  const [selectedOrder, setSelectedOrder] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEditClick = (order: any) => {
    setSelectedOrder(order);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    if (refetchCallback) {
      refetchCallback();
    }
  };
    
  return (
    <div className="w-full flex flex-col mb-4">      
      <div 
        className="w-full max-h-[65vh] pb-4 overflow-auto" 
      >
        {data.map((order: any, index: number) => (
          <div className="w-full min-w-56 mb-6 bg-white p-4 rounded-lg shadow-sm" key={`order-${index}`}>
            <div className="flex mb-3 justify-between items-center">
              <p className="font-bold text-md">{order.poReferenceNo}</p>
              <DownloadProductionOrderButton order={order} requireAdditionalFiles={true} />
            </div>
            
            <div className="mb-3">
              <p className="text-sm text-grey-500">
                Issued by: {order?.createdBy?.firstName || ''} {order?.createdBy?.lastName || ''}
              </p>
            </div>
            
            {/* Date of PO Sent */}
            <div className="mb-2">
              <p className="text-xs font-bold text-grey-400 uppercase">DATE OF PO SENT</p>
              <p className="text-xs text-grey-400">
                {convertUnixToSingaporeTime(order.createdAt) || "-"}
              </p>
            </div>
            
            {/* Date of Reply (Account Status) */}
            <div className="mb-2">
              <p className="text-xs font-bold text-grey-400 uppercase">DATE OF REPLY (ACCOUNT STATUS)</p>
              <p className="text-xs text-grey-400">
                {convertUnixToSingaporeTime(order.closedAt) || "-"}
              </p>
            </div>
            
            {/* Return TAT (Account Status) */}
            <div className="mb-2">
              <p className="text-xs font-bold text-grey-400 uppercase">RETURN TAT (ACCOUNT STATUS)</p>
              <p className="text-xs text-grey-400">
                {order.returnTATAccountStatus || "-"}
              </p>
            </div>
            
            {/* Edit Link */}
            <div className="mt-3">
              <a 
                href="#" 
                className="text-blue-500 text-sm underline"
                onClick={(e) => {
                  e.preventDefault();
                  handleEditClick(order);
                }}
              >
                Edit Date of Reply (Account Status)
              </a>
            </div>
          </div>
        ))}
      </div>
      
      {/* Update Production Order Modal */}
      {selectedOrder && (
        <UpdateProductionOrderModal
          productionOrderId={selectedOrder.productionOrderId}
          platformReferenceNo={selectedOrder.poReferenceNo || ''}
          closedAt={Date.now()}
          editPlatformReferenceNo={false}
          isOpen={isModalOpen}
          onClose={handleModalClose}
          callback={refetchCallback ? refetchCallback : () => {}}
        />
      )}
    </div>
  );
}
