import DynamicForm from '@components/common/DynamicForm';
import { convertMsToHoursMinutes } from '@utils/utils';
import cloneDeep from 'lodash.clonedeep';
import { generalInformationItems, generalUpdateInformationItems } from '../MonikerFormConfig';
import { CommonButton, IconButton } from '@components/common/CommonButton';
import { useEffect, useMemo, useState } from 'react';
import ActivityLog from './ActivityLog/ActivityLog';
import CustomBadge from '@components/common/CustomBadge';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useScamEnablerAssign, useUpdateScamEnablerV2Mutation } from '@api/scamEnablerSlice';
import { useGetConfigQuery } from '@api/configSliceApi';

const PortfoliosMonikerDetailsOverviewComponent = ({
  data,
  refetch,
  isLoading,
  isEditable,
  isDirectionQueueMonikerModal,
}: {
  data: any;
  refetch: any;
  isLoading: boolean;
  isEditable: boolean;
  isDirectionQueueMonikerModal: boolean;
}) => {
  const [isActivityLogModalOpen, setIsActivityLogModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const assignUser = useScamEnablerAssign();
  const [updateScamEnablerV2] = useUpdateScamEnablerV2Mutation();
  const { data: config } = useGetConfigQuery('system', {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  useEffect(() => {
    const omsIntegration = config?.config?.oms?.enable ?? false;
    if (omsIntegration) {
      // Enable duplicate check
      generalInformationItems.push({
        label: 'OMS Duplicate Check',
        type: 'omsDuplicateCheck',
        id: 'duplicateCheckStatus',
      });
    }
  }, [config?.config?.oms?.enable]);

  const defaultValues = useMemo(() => {
    const initialValues: any = {};

    if (data?.scamEnabler) {
      generalUpdateInformationItems.forEach((item) => {
        let da1: any = {};
        let da2: any = {};
        if (data?.scamEnabler?.assignment?.length > 0) {
          da1 = data.scamEnabler.assignment.find((assign: any) => assign.role === 'da1');
          da2 = data.scamEnabler.assignment.find((assign: any) => assign.role === 'da2');
        }
        if (item.type === 'dateTime' && data.scamEnabler[item.id]) {
          initialValues[item.id] = dayjs(data.scamEnabler[item.id]).format('YYYY-MM-DDTHH:mm');
        } else if (item.id === 'da1' && da1) {
          initialValues[item.id] = { id: da1.userId };
        } else if (item.id === 'da2' && da2) {
          initialValues[item.id] = { id: da2.userId };
        } else {
          initialValues[item.id] = data?.scamEnabler[item.id] || ''; // Fallback to empty string if data[item.id] is undefined
        }
      });
    }
    return initialValues;
  }, [data]);

  const formMethods = useForm({
    defaultValues,
    mode: 'onChange',
  });

  useEffect(() => {
    formMethods.reset(defaultValues); // This will reset the form with new default values
  }, [defaultValues, formMethods, data]);

  const processReport = (data: any) => {
    if (!data) return {};
    let tempData = cloneDeep(data);
    let productionOrderSentDate =
      tempData?.productionOrderSentDate || tempData?.generateProductionOrderAt;

    tempData.productionOrderSentDate = productionOrderSentDate;

    if (productionOrderSentDate) {
      let reviewTATObject = convertMsToHoursMinutes(productionOrderSentDate - tempData.createdAt);
      tempData.reviewTAT = reviewTATObject.hours + ' hours ' + reviewTATObject.minutes + ' minutes';

      if (tempData.closedAt) {
        let returnTATObject = convertMsToHoursMinutes(tempData.closedAt - productionOrderSentDate);
        tempData.returnTAT =
          returnTATObject.hours + ' hours ' + returnTATObject.minutes + ' minutes';
      }
    }
    if (tempData?.assignment) {
      tempData.assignment.forEach((assign: any) => {
        if (assign.role === 'da1') {
          tempData.da1 = assign.userName;
        } else if (assign.role === 'da2') {
          tempData.da2 = assign.userName;
        }
      });
    }

    return tempData;
  };

  const handleSubmit = async () => {
    if (isEditing) {
      setIsSubmitting(true);
      let values = formMethods.getValues();
      let dirtyFields = formMethods.formState.dirtyFields;
      let tempParams: any = {};
      let newAssignParams = [];
      let isUpdated = false;
      for (const key in dirtyFields) {
        if (key === 'productionOrderSentDate' || key === 'closedAt') {
          tempParams[key] = new Date(values[key]).getTime();
        } else if (key === 'da1' || key === 'da2') {
          if (key === 'da1') {
            newAssignParams.push({
              scamEnablerId: data.scamEnabler._id,
              userId: values[key].id,
              role: 'da1',
            });
          } else if (key === 'da2') {
            newAssignParams.push({
              scamEnablerId: data.scamEnabler._id,
              userId: values[key].id,
              role: 'da2',
            });
          }
        } else {
          if (typeof values[key] === 'string') {
            tempParams[key] = values[key].trim();
          } else {
            tempParams[key] = values[key];
          }
        }
      }
      if (newAssignParams.length > 0) {
        let assignResponse = await assignUser(newAssignParams);
        if (!assignResponse.error) {
          isUpdated = true;
        }
      }
      if (Object.keys(tempParams).length > 0) {
        tempParams['scamEnablerId'] = data.scamEnabler._id;
        let response: any = await updateScamEnablerV2([tempParams]);
        if (!response.error) {
          isUpdated = true;
        }
      }

      if (isUpdated) {
        await refetch();
        formMethods.reset(defaultValues);
      }
      setIsSubmitting(false);
    }
    setIsEditing(!isEditing);
  };

  return (
    <>
      {!isDirectionQueueMonikerModal && (
        <div>
          <div className="flex flex-row justify-between">
            <div className="flex flex-row items-center align-middle gap-4 pb-4">
              <h1 className="font-bold text-lg  text-blue-500 ">Overview</h1>
              <IconButton
                isDisabled={false}
                icon="activity_log"
                type="button"
                onClick={() => setIsActivityLogModalOpen(true)}
                isLoading={false}
              />
              {data?.scamEnabler?._id && isActivityLogModalOpen && (
                <ActivityLog
                  isOpen={isActivityLogModalOpen}
                  handleClose={() => setIsActivityLogModalOpen(false)}
                  id={data.scamEnabler._id}
                />
              )}
            </div>
            <div className="flex flex-row gap-4">
              {isEditing && (
                <>
                  <CommonButton
                    isDisabled={false}
                    variant="text"
                    title={`Cancel`}
                    onClick={() => setIsEditing(false)}
                  />
                  <CommonButton
                    isDisabled={!formMethods.formState.isDirty}
                    variant="secondary"
                    title={`Save`}
                    isLoading={isSubmitting}
                    onClick={handleSubmit}
                  />
                </>
              )}
              {!isEditing && (
                <CommonButton
                  isDisabled={!isEditable}
                  variant="primary"
                  title={`Edit`}
                  isLoading={isSubmitting}
                  onClick={() => setIsEditing(true)}
                />
              )}
            </div>
          </div>
          <div className="mt-4 mb-4">
            {data?.scamEnabler?.previouslyNotTerminated === true && (
              <CustomBadge text="Previously Not terminated" />
            )}
            {data?.scamEnabler?.previouslyTerminated === true && (
              <CustomBadge text="Previously terminated" />
            )}
          </div>
        </div>
      )}
      <div className="max-h-[80vh] overflow-visible pb-4">
        <h1 className="font-semibold text-sm pb-1 text-blue-400">General Information</h1>

        {data && data.scamEnabler && Object.keys(data.scamEnabler).length > 0 ? (
          <>
            {!isEditing && (
              <DynamicForm
                formFields={generalInformationItems}
                data={processReport(data.scamEnabler)}
                callback={refetch}
                isEditable={false}
              />
            )}
            {isEditing && (
              <form>
                <DynamicForm
                  formFields={generalUpdateInformationItems}
                  formMethods={formMethods}
                  data={processReport(data.scamEnabler)}
                  callback={refetch}
                  isEditable={true}
                />
              </form>
            )}
          </>
        ) : (
          <DynamicForm formFields={generalInformationItems} isLoading={true} />
        )}
      </div>
    </>
  );
};

export default PortfoliosMonikerDetailsOverviewComponent;
