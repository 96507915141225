import { useState } from 'react';
import { LoadingButtonComponent, CommonButton } from '@components/common/CommonButton';
import { Modal } from '@mui/material';
import ModalLayout from '@components/layouts/ModalLayout';

const ProductionOrderDeleteQueueModal = ({
  selectedRows,
  view,
  isOpen,
  handleDeletePO,
  onClose,
}: {
  selectedRows: any[];
  view: string;
  isOpen: boolean;
  handleDeletePO: () => void;
  onClose: () => void;
}) => {
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const handleClick = async () => {
    setIsLoadingDelete(true);
    await handleDeletePO();
    setIsLoadingDelete(false);
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalLayout
        title={'Delete from Queue'}
        handleCloseModal={onClose}
        alignment="center"
        size="medium"
      >
        <div className='-mt-8'>
          <p className="py-4 font-h4-bold-14 ">
            You are deleting the following {view === 'bankView' ? 'accounts' : 'monikers'} from the
            queue:
          </p>
          <div className="overflow-auto bg-grey-200 p-4 mb-4">
            <ul className="pl-2">
              {selectedRows.map((row, index) => (
                <li key={index} className="list-disc text-sm">
                  {row.userId || row.scamEnablerUserId || row.accountNumber}
                </li>
              ))}
            </ul>
          </div>
          <p className="mb-4">
            The status of these accounts will change back to <b>Under Review</b>.
          </p>
          <p className="mb-2">Are you sure you want to proceed?</p>
          <p>You cannot undo this action.</p>
          <div className="flex justify-end gap-4 pt-4">
            <CommonButton isDisabled={false} variant="text" title={`Cancel`} onClick={onClose} />
            <LoadingButtonComponent
              isLoading={isLoadingDelete}
              title="Delete"
              variant="red"
              onClick={handleClick}
            />
          </div>
        </div>
      </ModalLayout>
    </Modal>
  );
};

export default ProductionOrderDeleteQueueModal;
