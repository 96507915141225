import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLogoutMutation } from '@api/sessionSlice';
import { getSessionUserFromAccessToken } from '@utils/utils';
import { IconButton } from './CommonButton';
import UpdateUserModal from '../../pages/users/UpdateUser/UpdateUserModal';
import { ReactComponent as Warning } from '@assets/icons/warning_yellow.svg';
import { ReactComponent as Info } from '@assets/icons/info_blue.svg';
import { useGetConfigQuery } from '@api/configSliceApi';

const HeaderComponent = ({ title }: { title?: string | any[] }) => {
  const navigate = useNavigate();
  const [logout] = useLogoutMutation();
  const [user, setUser] = useState<any>({});
  const [intials, setInitials] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [isSignoutLoading, setIsSignoutLoading] = useState(false);
  const [isRecoveredMessageOpen, setIsRecoveredMessageOpen] = useState(false);
  const { data: config } = useGetConfigQuery('system', {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true
  });

  useEffect(() => {
    const currentAlertStatus = config?.config?.oms?.frontend?.showAlert ?? false;
    const previousAlertStatus = localStorage.getItem('omsAlertStatus') === 'true';
    
    // If we have a previous status and it was true but now it's false, show recovery message
    if (previousAlertStatus && currentAlertStatus === false) {
      setIsRecoveredMessageOpen(true);
    }
    localStorage.setItem('omsAlertStatus', currentAlertStatus.toString());
  }, [config?.config?.oms?.frontend?.showAlert]);

  useEffect(() => {
    const getSessionData = async () => {
      try {
        const response = await getSessionUserFromAccessToken();
        let initial = response.firstName[0].toUpperCase() + response.lastName[0].toUpperCase();
        setInitials(initial);
        setUser(response);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    getSessionData();
  }, []);

  // let session = await getUserFromAccessToken();

  const handleNotification = () => {};

  async function handleSignOut() {
    try {
      setIsSignoutLoading(true);
      let response: any = await logout(null);
      setIsSignoutLoading(false);

      if (!response.error) {
        navigate('/login');
      }
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  function handleClickUserName() {
    setOpenModal(true);
  }

  async function handleUpdateUserModalClose() {
    const response = await getSessionUserFromAccessToken();
    let initial = response.firstName[0].toUpperCase() + response.lastName[0].toUpperCase();
    setInitials(initial);
    setUser(response);
    setOpenModal(false);
  }

  return (
    <>
      <div className="w-full h-16 flex justify-between bg-grey-200 items-center">
        <div>
          <h1 className={`font-bold  text-grey-500 p-6`}>{title}</h1>
        </div>
        {config?.config?.oms?.frontend?.showAlert && (
          <div className="rounded py-2 px-4 m-3 font-semibold bg-yellow-200 border border-yellow flex flex-row text-black items-center gap-1">
            <Warning />
            <p className="pl-2 text-xs">
              {config.config?.oms?.frontend?.alertMessage || 'Connection to OMS is currently unavailable. Please use your manual workflow to complete necessary actions after generating production orders.'}
            </p>
          </div>
        )}
        {(isRecoveredMessageOpen && config?.config?.oms?.frontend?.showAlert === false) && (
          <div className="rounded py-2 px-4 m-3 font-semibold bg-blue-200 border border-blue-400 flex flex-row text-black items-center gap-1">
            <Info />
            <p className="pl-2 text-xs">
              Connection to OMS has been restored. You may now proceed with normal operations.
            </p>
            <button
              className="ml-2 text-black"
              onClick={() => {
                setIsRecoveredMessageOpen(false);
              }}
            >
              &times;
            </button>
          </div>
        )}
        <div className="flex justify-end items-center">
          {/* <IconButton onClick={handleNotification} icon="notification" isDisabled={false} /> */}
          <IconButton
            onClick={handleSignOut}
            icon="logout"
            isDisabled={false}
            isLoading={isSignoutLoading}
          />
          <div
            className="flex flex justify-end items-center gap-4 px-8"
            title="Update User"
            onClick={handleClickUserName}
            style={{ cursor: 'pointer' }}
          >
            <div title="Update User" className="w-8 h-8 rounded-full bg-blue-400 text-white flex justify-center items-center font-bold">
              {intials || 'DO'}
            </div>
            <p title="Update User">{`${user.firstName} ${user.lastName}` || 'Duty Officer'}</p>
          </div>
        </div>
      </div>
      <UpdateUserModal isOpen={openModal} onModalClose={handleUpdateUserModalClose} />
    </>
  );
};

export default HeaderComponent;
