import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getSessionUserFromAccessToken } from '@utils/utils';
import { useGetReportDetailsByIdV2Query } from '@api/reportsSlice';

export const useMonikersDetailedPage = () => {
  const params = useParams();
  const [currentReportHistoryPage, setReportHistoryPage] = useState(0);
  const [poQueueModalOpen, setPOQueueModalOpen] = useState(false);
  const [poModalOpen, setPOModalOpen] = useState(false);
  const [currentRole, setCurrentRole] = useState('');
  const [currentUser, setCurrentUser] = useState({});

  // const { refetch, data, isSuccess, isError, isLoading, isFetching } = useGetScamEnablersByIdQuery(
  //   {
  //     id: params.id,
  //   },
  //   {},
  // );

  const { refetch, data, isSuccess, isError, isLoading, isFetching } =
    useGetReportDetailsByIdV2Query(
      {
        id: params.id,
      },
      {},
    );

  const handlePreviousSubmission = () => {
    if (currentReportHistoryPage !== 0) {
      setReportHistoryPage(currentReportHistoryPage - 1);
    }
  };

  const handleNextSubmission = () => {
    if (currentReportHistoryPage + 1 < data!.report.reportHistory.length) {
      setReportHistoryPage(currentReportHistoryPage + 1);
    }
  };

  const handleOpenQueuePOModal = () => {
    setPOQueueModalOpen(true);
  };

  const handleCloseQueuePOModal = () => {
    setPOQueueModalOpen(false);
  };

  const handleOpenPOModal = () => {
    setPOModalOpen(true);
  };

  const handleClosePOModal = () => {
    setPOModalOpen(false);
  };

  useEffect(() => {
    const getRole = async () => {
      if (data && isSuccess) {
        const sessionUser = await getSessionUserFromAccessToken();
        if (`${sessionUser.firstName} ${sessionUser.lastName}` === data.report.da1) {
          setCurrentRole('da1');
        } else if (`${sessionUser.firstName} ${sessionUser.lastName}` === data.report.da2) {
          setCurrentRole('da2');
        } else {
          setCurrentRole('');
        }

        setCurrentUser(sessionUser);
      }
    };
    getRole();
  }, [data]);

  return {
    params,
    poQueueModalOpen,
    handleOpenQueuePOModal,
    handleCloseQueuePOModal,
    poModalOpen,
    handleOpenPOModal,
    handleClosePOModal,
    currentRole,
    refetch,
    data,
    currentUser,
  };
};
