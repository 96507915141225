import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { ReactComponent as Tick } from '@assets/icons/tick.svg';
import Box from '@mui/material/Box';
import { StepButton } from '@mui/material';

const CustomizedStepperForm = ({
  steps,
  children,
  handleStepClick,
  activeStep,
  stepCompletionStatus,
}: {
  steps: Array<string>;
  children: any;
  handleStepClick: any;
  activeStep: number;
  stepCompletionStatus: Array<boolean>;
}) => {
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}, &.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: 'var(--blue-400)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor: theme.palette.mode === 'dark' ? 'var(--blue-400)' : 'var(--blue-300)',
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
    backgroundColor: 'var(--blue-200)',
    zIndex: 1,
    color: '#fff',
    fontWeight: '700',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    border: `solid 4px var(--blue-300)`,
    ...(ownerState.active && {
      backgroundColor: `var(--blue-400)`,
      border: 'none',
    }),
    ...(ownerState.completed && {
      border: `solid 4px var(--blue-400)`,
    }),
  }));

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, icon } = props;

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }}>
        {completed && active ? (
          <span className="text-sm">{icon}</span> // If completed, show tick icon
        ) : completed ? (
          <Tick />
        ) : (
          <span className="text-sm">{icon}</span> // Step number
        )}
      </ColorlibStepIconRoot>
    );
  }

  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {steps.map((label, index) => (
          <Step key={label} completed={stepCompletionStatus[index]}>
            <StepButton
              color="inherit"
              onClick={handleStepClick(index)}
              disabled={!stepCompletionStatus.slice(0, index).every((status) => status)}
            >
              <StepLabel
                StepIconComponent={ColorlibStepIcon}
                sx={{
                  '& .MuiStepLabel-label': {
                    color: stepCompletionStatus[index]
                      ? 'var(--black)' // Completed step
                      : 'rgba(0, 0, 0, 0.3)', // Default (not yet reached) step with 30% opacity
                    fontWeight: 700,
                    fontSize: '14px',
                    '@media (max-width: 425px)': {
                      fontSize: '8px',
                    },
                    marginTop: 1,
                  },
                  '& .MuiStepLabel-label.Mui-active': {
                    color: 'var(--blue-400)',
                    fontWeight: 700,
                    fontSize: '14px',
                    '@media (max-width: 425px)': {
                      fontSize: '8px',
                    },
                  },
                }}
              >
                {label}
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mb: 2 }}>{children}</Box>
    </Stack>
  );
};

export default CustomizedStepperForm;
