import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { trackButtonClick } from "@utils/trackEvent";
import routeTitlesJson from "../analytics/routeTitles.json";

const routeTitles: Record<string, string> = routeTitlesJson as Record<string, string>;

const getPageTitle = (pathname: string): string => {
  if (routeTitles[pathname]) {
    return routeTitles[pathname];
  }

  if (pathname.startsWith("/admin/reports/")) return "Report Details Page";
  if (pathname.startsWith("/admin/portfolios/monikers/")) return "Moniker Details Page";
  if (pathname.startsWith("/admin/portfolios/bank/")) return "Bank Details Page";
  if (pathname.startsWith("/admin/portfolios/paynow/")) return "PayNow Details Page";
  if (pathname.startsWith("/admin/directions-queue/")) return "Directions Queue Type Page";

  return "Unknown Page"; 
};

const EventTracker = () => {
  const location = useLocation();
  const [prevPathname, setPrevPathname] = useState(location.pathname);
  const lastClickedPathRef = useRef(location.pathname); 

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const element = target.closest("button, [type='button'], a");
      const clickedPathname = lastClickedPathRef.current;
      const pageTitle = getPageTitle(clickedPathname); 
      let elementText: string = "";
      let elementType: string = "";

      if (!element) {
        elementText = target.getAttribute("title")?.trim() || "";
      } else {
        elementType = element.tagName.toLowerCase();
        elementText = element.getAttribute("id")?.trim() || element.textContent?.trim() || "";
      }

      if (!elementText) return;
      
      trackButtonClick(
        elementType === "a" ? "Link Clicks" : "Button Clicks",
        `Clicked ${elementText} on ${pageTitle}`
      );

      lastClickedPathRef.current = location.pathname;
    };

    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, [location.pathname]);

  return null;
};

export default EventTracker;