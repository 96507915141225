import UploadPortfolioMonikerComponent from './UploadPortfolioMonikerComponent';
import HeaderComponent from '@components/common/HeaderComponent';
import AccessControl from '@components/common/AccessControl';
import { Link } from 'react-router-dom';

const UploadPortfolioMonikerPage = () => {
  return (
    <>
      <HeaderComponent
        title={[
          <div>
            <Link to="/admin/portfolios/monikers" className="text-blue-400">
              Monikers (Reported)
            </Link>
            <span> {'/'} Add Report - Upload List</span>
          </div>,
        ]}
      />
      <div className="p-2">
        <AccessControl type="page" allowedPermissions={['portfolios.scamenablers.create']}>
          <section className="box-border bg-white rounded-lg p-4">
            <div className="box-border h-full flex flex-col bg-grey-100 rounded-lg p-6">
              <UploadPortfolioMonikerComponent id={'fileUpload'}></UploadPortfolioMonikerComponent>
            </div>
          </section>
        </AccessControl>
      </div>
    </>
  );
};

export default UploadPortfolioMonikerPage;
