import { Link } from 'react-router-dom';
import { ReportStatus } from '@components/common/StatusComponent';
import { convertUnixToSingaporeTime } from '@utils/utils';
import { OpenDiary } from '../Buttons';
import { Tooltip } from '@mui/material';

const getColumns = (refetch: any) => [
  {
    title: 'Report No',
    dataIndex: 'reportNumber',
    key: 'reportNumber',
    sorter: true,
    width: '19%',
    render: (text: string, record: any, index: number) => (
      <Link
        id="Report No"
        to={`/admin/reports/${record._id}`}
        className="underline"
        target="_blank"
        rel="noopener noreferrer"
      >
        {text}
      </Link>
    ),
  },
  // {
  //   title: "No of Monikers Involved",
  //   dataIndex: "numberOfMonikers",
  //   sorter: true,
  //   key: "numberOfMonikers",
  //   width: "8%",
  // },
  {
    title: 'Classification',
    dataIndex: 'classification',
    sorter: true,
    key: 'classification',
    width: '19%',
    render: (text: string, record: any, index: number) => text || '-',
  },
  {
    title: 'Scam Type',
    dataIndex: 'scamType',
    sorter: true,
    key: 'scamType',
    width: '19%',
  },
  {
    title: 'Date of Creation (Latest)',
    dataIndex: 'createdAt',
    sorter: true,
    key: 'createdAt',
    width: '19%',
    render: (text: string, record: any, index: number) =>
      convertUnixToSingaporeTime(record.createdAt),
  },
  {
    title: 'Report Status',
    dataIndex: 'reportStatus',
    sorter: true,
    width: '19%',
    key: 'reportStatus',
    render: (text: string, record: any, index: number) => <ReportStatus reportStatus={text} />,
  },
  // {
  //   key: "download",
  //   width: "4%",
  //   render: (text: string, record: any, index: number) => (
  //     <IconButton onClick={() => {}} icon="download" isDisabled={false} />
  //   ),
  // },
  // {
  //   key: 'assign',
  //   width: '4%',
  //   render: (text: string, record: any, index: number) => (
  //     <AssignMeSingleButton report={record} callback={refetch} showSelectedReports={true} />
  //   ),
  // },
  // {
  //   key: 'download',
  //   width: '4%',
  //   render: (text: string, record: any, index: number) => (
  //     <DownloadSingleReportButton reportId={record._id} />
  //     // <AssignMeSingleButton report={record} callback={refetch} showSelectedReports={true} />
  //   ),
  // },
  {
    key: 'diary',
    width: '4%',
    render: (text: string, record: any, index: number) => (
      <Tooltip title="View Diary">
        <div>
          <OpenDiary
            callback={() => {
              refetch();
            }}
            report={record}
          />
        </div>
      </Tooltip>
    ),
  },
];

export default getColumns;
