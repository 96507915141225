import { DatePicker } from 'antd';
import { ReactComponent as CalendarIcon } from '@assets/icons/calendar.svg';
import { ConfigProvider } from 'antd';
import { Controller } from 'react-hook-form';
import { getNestedError } from '@utils/utils';
import { createStyledPicker } from './DatePicker';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const DateRangePicker = ({
  id,
  placeholder,
  control,
  formState,
  rules,
  setValue,
  showTime,
  removePopupContainer,
}: {
  id: string;
  placeholder?: string;
  control: any;
  formState?: any;
  rules?: any;
  setValue?: any;
  showTime?: boolean;
  removePopupContainer?: boolean;
}) => {
  const errors = formState?.errors;
  const datePlaceholder = placeholder ? placeholder : 'dd/mm/yyyy';
  const fromId = id?.split(',')[0];
  const toId = id?.split(',')[1];
  const StyledRangePicker = createStyledPicker(RangePicker);

  const isValidDateFormat = (value: string) => {
    const regex = /^\d{4}-\d{2}-\d{2}( \d{2}:\d{2})?$/;
    return regex.test(value);
  };

  const getCompatibleValue = (value: string) => {
    if (isValidDateFormat(value)) {
      return dayjs(value, 'YYYY-MM-DD HH:mm');
    }
    return null;
  };

  const handleDateRangeChange = (dates: string[]) => {
    setValue(fromId, dates[0]);
    setValue(toId, dates[1]);
  };

  const disabledDate = (current: any) => {
    return current && current > dayjs().endOf('day');
  };

  const getFormattedDate = (newValue: any) => {
    if (newValue && newValue[0] && newValue[1]) {
      if (showTime) {
        return [
          dayjs(newValue[0]).format('YYYY-MM-DD HH:mm'),
          dayjs(newValue[1]).format('YYYY-MM-DD HH:mm'),
        ];
      } else {
        return [
          dayjs(newValue[0]).startOf('day').format('YYYY-MM-DD HH:mm'),
          dayjs(newValue[1]).endOf('day').format('YYYY-MM-DD HH:mm'),
        ];
      }
    } else {
      return ['', ''];
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#6FB8C9',
        },
      }}
    >
      <Controller
        name={id}
        control={control}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <StyledRangePicker
            disabledDate={disabledDate}
            format={showTime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY'}
            getPopupContainer={
              removePopupContainer ? () => document.body : (trigger: any) => trigger.parentElement
            }
            onChange={(newValue: any) => {
              const formattedDate = getFormattedDate(newValue);

              onChange(formattedDate);
              handleDateRangeChange(formattedDate);
            }}
            placeholder={[datePlaceholder, datePlaceholder]}
            suffixIcon={<CalendarIcon />}
            showTime={showTime ? { format: 'HH:mm' } : false}
            value={
              value && value[0] && value[1]
                ? [getCompatibleValue(value[0]), getCompatibleValue(value[1])]
                : [null, null]
            }
          />
        )}
      />
      {errors && getNestedError(errors, id) && (
        <span style={{ color: 'var(--red)' }} role="alert">
          {getNestedError(errors, id)?.message}
        </span>
      )}
    </ConfigProvider>
  );
};

export default DateRangePicker;
