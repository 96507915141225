import { showAlert } from '@store/alertSlice';

export const deleteProductionOrder = async (
  poQueueIds: Array<string>,
  view: string,
  dispatch: any,
  deleteProductionOrderQueue: any,
) => {
  try {
    let data: any = await deleteProductionOrderQueue({
      poQueueIds: poQueueIds,
      view: view
    });
    if (data.error) {
      dispatch(showAlert({ type: 'error', message: data.error.data.message }));
    } else {
      dispatch(
        showAlert({
          type: 'success',
          message: `${poQueueIds.length} monikers deleted`,
        }),
      );
    }
  } catch (e) {
    dispatch(showAlert({ type: 'error', message: `Error deleting` }));
  }
};
